import { Avatar, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
// import { getApiHandlerWithToken } from "../../../config/service";
import { Box } from "@mui/system";
import CircularProgressBox from "../../../component/CircularProgressBox";
import SettingsIcon from "@mui/icons-material/Settings";
import PasswordIcon from "@mui/icons-material/Password";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/auth";
import axios from "axios";
import { ApiConfig } from "../../../config/ApiConfig";

const MyAccount = () => {
  const [profile, setProfile] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const navigate = useNavigate();
  const { setUserDetail } = useContext(AuthContext);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setIsDataLoading(true);
    let Id = sessionStorage.getItem("id");
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getAccountDetails,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          userId: Id,
        },
      });
      if (res?.data?.responseCode === 200) {
        const profileData = res?.data?.result;
        setProfile(profileData);
        setUserDetail(profileData);
        setIsDataLoading(false);
      } else {
        console.log("Unexpected response code:", res?.data?.responseCode);
        setIsDataLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 404) {
        setIsDataLoading(false);
        console.log("API not found (404)");
      }
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
      //   bgcolor={"orange"}
      sx={{
        mt: { xs: 6, md: 2, lg: 0 },
      }}
    >
      <Grid container item xs={12}>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          My Account
        </Typography>
      </Grid>

      <>
        <Grid
          container
          item
          xs={12}
          md={8}
          mt={8}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Avatar src={profile?.profileUrl} sx={{ height: 180, width: 180 }} />
          <Box sx={{ mt: { md: 0, xs: 10 } }}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h6">Name: </Typography>
              <Typography variant="body1" width={270} ml={2}>
                {profile?.fullName ? profile?.fullName : ""}
              </Typography>
            </Box>
            <Box
              mt={1}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h6">Email address: </Typography>
              <Typography variant="body1" width={270} ml={2}>
                {profile?.email ? profile?.email : ""}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          mt={12}
          alignItems={"center"}
          justifyContent={"center"}
          gap={6}
        >
          <Button
            variant="contained"
            style={{
              background: "#fff",
              color: "#2A73E0",
              border: "1px solid #2A73E0",
              padding: "8px 30px",
            }}
            onClick={() => {
              navigate("/my-account/edit-profile", {
                state: { data: profile },
              });
            }}
          >
            <SettingsIcon style={{ marginRight: "8px" }} /> Edit Profile
          </Button>
          <Button
            variant="contained"
            style={{
              background: "#fff",
              color: "#2C3335",
              border: "1px solid #2C3335",
              padding: "8px 30px",
            }}
            onClick={() => {
              navigate("/my-account/edit-password", {
                state: { data: profile },
              });
            }}
          >
            <PasswordIcon style={{ marginRight: "8px" }} /> Change Password
          </Button>
        </Grid>
      </>
    </Grid>
  );
};

export default MyAccount;
