import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";
import CallIcon from "@mui/icons-material/Call";
import { Avatar, ListItemAvatar, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/header/Header";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BadgeIcon from "@mui/icons-material/Badge";
import PortraitIcon from "@mui/icons-material/Portrait";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import SourceIcon from "@mui/icons-material/Source";
import LogoutPopUp from "../../component/LogoutPopUp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import { useStore } from "../../store/store";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import axios from "axios";
import { ApiConfig } from "../../config/ApiConfig";
import { useAuth } from "../../context/auth";
import BroadcastOnPersonalIcon from "@mui/icons-material/BroadcastOnPersonal";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";

const drawerWidth = 340;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(4),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flex: "1 1 auto",
    overflow: "hidden",
    "@media(max-width:600px)": { height: "auto", padding: "0px !important" },
    "@media(max-width:1200px)": {
      marginLeft: `-${drawerWidth}px`,
    },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const dashboardItemList = [
  {
    title: "Dashboard",
    pathNamematcher: "/dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "Caregivers Management",
    pathNamematcher: "/caregiver-management",
    path: "/caregiver-management",
    icon: <EscalatorWarningIcon />,
  },
  {
    title: "ASHA Workers Management",
    pathNamematcher: "/asha-worker-management",
    path: "/asha-worker-management",
    icon: <Diversity3Icon />,
  },
  {
    title: "Informative Content Management",
    pathNamematcher: "/informative-content",
    path: "/informative-content",

    icon: <BadgeIcon />,
  },
  {
    title: "Questionnaire Management",
    pathNamematcher: "questionnaire",
    path: "/questionnaire",

    icon: <PsychologyAltIcon />,
  },
  {
    title: "Broadcast Management",
    pathNamematcher: "/broadcast",
    path: "/broadcast",

    icon: <BroadcastOnPersonalIcon />,
  },
  {
    title: "Static Content Management",
    pathNamematcher: "/static-content",
    path: "/static-content",
    icon: <SourceIcon />,
  },
  {
    title: "My Account",
    pathNamematcher: "/my-account",
    path: "/my-account",
    icon: <AccountCircleIcon />,
  },
];

const DashboardLayout = ({ children, roleType }) => {
  const isDrawerMediaMatch = useMediaQuery("(min-width:1200px)");
  const matches = useMediaQuery("(min-width:768px)");
  const [open, setOpen] = useState(false);
  const { userDetail } = useAuth();
  const [openLeft, setOpenLeft] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const permissionArray = useStore((state) => state.allowedHeaderPermission);
  const [isLogOutPopUpOpen, setIsLogOutPopUpOpen] = useState(false);
  const [profile, setProfile] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);
  const userDetails = JSON.parse(sessionStorage.getItem("user"))
    ? JSON.parse(sessionStorage.getItem("user"))
    : {};

  useEffect(() => {
    if (matches) {
      handleDrawerOpen();
    }
  }, [matches]);
  useEffect(() => {
    if (userDetail?.id) {
      setProfile(userDetail);
    }
  }, [userDetail]);
  useEffect(() => {
    if (!matches) {
      handleDrawerClose();
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  function logouthandler() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("authToken");
    navigate("/");
  }
  const getProfile = async () => {
    setIsDataLoading(true);
    let Id = sessionStorage.getItem("id");
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getAccountDetails,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          userId: Id,
        },
      });
      if (res?.data?.responseCode === 200) {
        setProfile(res?.data?.result);
        setIsDataLoading(false);
      } else {
        console.log("Unexpected response code:", res?.data?.responseCode);
        setIsDataLoading(false);
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 404) {
        setIsDataLoading(false);
        console.log("API not found (404)");
      }
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Header setOpenLeft={setOpenLeft} openLeft={openLeft} />
      <Box sx={{ display: "flex" }}>
        <Drawer
          className="removeScrollbar"
          sx={{
            display: isDrawerMediaMatch && !openLeft ? "none" : "block",
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              // height: "90%",
              // bottom: { xs: "16px", xl: "24px" },
              top: "70px",
              "@media(max-width:768px)": {
                height: "88%",
                bottom: "25px",
              },

              overflowX: "hidden !important",
            },
          }}
          variant={"persistent"}
          anchor="left"
          open={openLeft}
        >
          <List
            sx={{
              pt: "0px",
            }}
            component={OverlayScrollbarsComponent}
          >
            <Box
              sx={{
                pt: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                "@media(max-width:768px)": {
                  ml: "0px",
                },
              }}
            >
              <Box
                sx={{
                  bgcolor: "#2A73E0",
                  color: "white",
                  borderRadius: "12px",
                  width: "90%",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      src={profile?.profileUrl ? profile?.profileUrl : "-"}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6" fontSize={16}>
                        {profile?.fullName ? profile?.fullName : "Admin Name"}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" fontSize={12}>
                        Admin
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider sx={{ bgcolor: "white", mx: "20px" }} />
                <ListItemButton sx={{ height: "24px", marginTop: "12px" }}>
                  <ListItemIcon
                    sx={{
                      color: "white",
                      "&.MuiListItemIcon-root": {
                        minWidth: "38px !important",
                      },
                    }}
                  >
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      profile?.email ? profile?.email : "admin@mailinator.com"
                    }
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      color: "white",
                      "&.MuiListItemIcon-root": {
                        minWidth: "38px !important",
                      },
                    }}
                  >
                    <CallIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={profile?.mobileNumber ? profile?.mobileNumber : ""}
                  />
                </ListItemButton>
              </Box>

              <Box
                mt={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  bgcolor: "#ffffff",
                  mr: "12px",
                }}
              >
                {dashboardItemList
                  .filter((e) => {
                    if (permissionArray && permissionArray?.length > 1) {
                      return permissionArray
                        ?.filter((e) => e?.is_active)
                        .map((e) => e?.permission)
                        ?.includes(e?.title);
                    } else {
                      return true;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <>
                        <ListItem
                          key={index}
                          sx={{
                            "&.MuiListItem-root": {
                              paddingBottom: "0px",
                              paddingTop: "0px",
                            },
                          }}
                        >
                          <ListItemButton
                            onClick={() => {
                              navigate(item.path);
                              if (isDrawerMediaMatch) {
                                setOpenLeft(true);
                              } else {
                                setOpenLeft(false);
                              }
                            }}
                            sx={{
                              background: pathname
                                .toLowerCase()
                                .includes(item.pathNamematcher.toLowerCase())
                                ? " linear-gradient(90deg, rgba(42,115,224,1) 0%, rgba(42,115,224,1) 31%, rgba(42,115,224,0.9724264705882353) 100%)"
                                : "#fff",
                              borderRadius: "20px",
                              px: "20px",
                              py: "14px",
                              color: pathname
                                .toLowerCase()
                                .includes(item.pathNamematcher.toLowerCase())
                                ? "#fff"
                                : "#000",
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                color: pathname
                                  .toLowerCase()
                                  .includes(item.pathNamematcher.toLowerCase())
                                  ? "#fff"
                                  : "#111",
                              }}
                            >
                              {item?.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  // fontSize={"12.5px"}
                                  whiteSpace={"nowrap"}
                                >
                                  {item?.title}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      </>
                    );
                  })}
              </Box>
            </Box>
            <ListItem
              sx={{
                position: "relative",
                // bottom: "0px",
                // mr: "100px",

                "&.MuiListItem-root": {
                  paddingBottom: "0px",
                  paddingTop: "0px",
                },
              }}
            >
              <ListItemButton
                onClick={() => {
                  setIsLogOutPopUpOpen(true);
                }}
                sx={{
                  background: "#fff",
                  borderRadius: "20px",
                  px: "10px",
                  py: "14px",
                  color: "#000",
                  // position: "relative",
                  // right: "6px",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "red",
                    position: "relative",
                    left: "-2px",
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body2">Logout</Typography>}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>

        {/* {isDrawerMediaMatch && !openLeft &&
       <Drawer
        className="removeScrollbar"
        sx={{
          display:isDrawerMediaMatch && !openLeft?'block':'none',
          // width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            // width: drawerWidth,
            boxSizing: "border-box",
            height: "90%",
            bottom: { xs: "16px", xl: "24px" },
            top: "initial",
            "@media(max-width:768px)": {
              height: "88%",
              bottom: "33px",
            },

            overflowX: "hidden !important",
          },
        }}
        variant={"persistent"}
        anchor="left"
        open={!openLeft}
      >
        <List
          sx={{
            pt: "0px",
          }}
          component={OverlayScrollbarsComponent}
        >
          <Box
            sx={{
              pt: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              "@media(max-width:768px)": {
                ml: "0px",
              },
            }}
          >
            <Box
              sx={{
                bgcolor: "#2A73E0",
                color: "white",
                borderRadius: "12px",
                // width: "90%",
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    src={profile?.profileUrl ? profile?.profileUrl : "NA"}
                  />
                </ListItemAvatar>
              
              </ListItem>
              <Divider sx={{ bgcolor: "white", mx: "20px" }} />
              <ListItemButton sx={{ height: "24px", marginTop: "12px" }}>
                <ListItemIcon
                  sx={{
                    color: "white",
                    "&.MuiListItemIcon-root": {
                      // minWidth: "38px !important",
                    },
                  }}
                >
                  <MailIcon />
                </ListItemIcon>
                
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon
                  sx={{
                    color: "white",
                    "&.MuiListItemIcon-root": {
                      // minWidth: "38px !important",
                    },
                  }}
                >
                  <CallIcon />
                </ListItemIcon>
                
              </ListItemButton>
            </Box>

            <Box
              mt={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "#ffffff",
                // mr: "12px",
              }}
            >
              {dashboardItemList
                .filter((e) => {
                  if (permissionArray && permissionArray?.length > 1) {
                    return permissionArray
                      ?.filter((e) => e?.is_active)
                      .map((e) => e?.permission)
                      ?.includes(e?.title);
                  } else {
                    return true;
                  }
                })
                .map((item, index) => {
                  return (
                    <>
                      <ListItem
                        key={index}
                        sx={{
                          "&.MuiListItem-root": {
                            paddingBottom: "0px",
                            paddingTop: "0px",
                          },
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            navigate(item.path);
                            if (isDrawerMediaMatch) {
                              setOpenLeft(true);
                            } else {
                              setOpenLeft(false);
                            }
                          }}
                          sx={{
                            background: pathname
                              .toLowerCase()
                              .includes(item.pathNamematcher.toLowerCase())
                              ? " linear-gradient(90deg, rgba(42,115,224,1) 0%, rgba(42,115,224,1) 31%, rgba(42,115,224,0.9724264705882353) 100%)"
                              : "#fff",
                            borderRadius: "20px",
                            // px: "20px",
                            // py: "14px",
                            color: pathname
                              .toLowerCase()
                              .includes(item.pathNamematcher.toLowerCase())
                              ? "#fff"
                              : "#000",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color: pathname
                                .toLowerCase()
                                .includes(item.pathNamematcher.toLowerCase())
                                ? "#fff"
                                : "#111",
                            }}
                          >
                            {item?.icon}
                          </ListItemIcon>
                        
                        </ListItemButton>
                      </ListItem>
                    </>
                  );
                })}
            </Box>
          </Box>


          <ListItem
            sx={{
              position: "relative",
              // bottom: "0px",
              // mr: "100px",

              "&.MuiListItem-root": {
                paddingBottom: "0px",
                paddingTop: "0px",
              },
            }}
          >
            <ListItemButton
              onClick={() => {
                setIsLogOutPopUpOpen(true);
              }}
              sx={{
                background: "#fff",
                borderRadius: "20px",
                // px: "10px",
                // py: "14px",
                color: "#000",
                // position: "relative",
                // right: "6px",
              }}
            >
              <ListItemIcon
                sx={{
                  color: "red",
                  position: "relative",
                  left: "-2px",   
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
            
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>} */}

        <Main open={open}>{children}</Main>
      </Box>

      <LogoutPopUp
        open={isLogOutPopUpOpen}
        setOpen={setIsLogOutPopUpOpen}
        funcToExecute={logouthandler}
      />
    </>
  );
};

export default DashboardLayout;
