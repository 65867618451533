import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CircularProgressBox = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        minHeight: "400px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        sx={{
          color: "#1D60B3",
        }}
        size={"50px"}
      />
    </Box>
  );
};

export default CircularProgressBox;
