import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BlockIcon from "@mui/icons-material/Block";
import { Avatar, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeletePopUp = ({ open, setOpen, funcToExecute }) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ height: "100px", width: "100px" }}>
            <DeleteIcon sx={{ color: "red", height: "80px", width: "80px" }} />
          </Avatar>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "10px",
            mb: "20px",
          }}
        >
          <Grid container item xs={5}>
            <Button
              onClick={() => {
                funcToExecute();
                handleClose();
              }}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "#fe352c" }}
            >
              Yes
            </Button>
          </Grid>
          <Grid container item xs={5}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "gray" }}
            >
              No
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DeletePopUp;
