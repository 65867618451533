import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Card,
  CardContent,
  Collapse,
  Tooltip,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { getAPIHandler } from "../../../../config/service";
import CircularProgressBox from "../../../../component/CircularProgressBox";
import NoDataFound from "../../../../component/NoDataFound";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

const SurveyResponses = () => {
  const { state } = useLocation();
  const [listData, setListData] = useState([]);
  const [isDataLoading, setisDataLoading] = useState(true);
  const [updateList, setUpdateList] = useState(false);
  const [expanded, setExpanded] = useState(null); // For toggling answer visibility
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const navigate = useNavigate();

  async function initialData() {
    setisDataLoading(true);
    const paramsToSend = {
      caregiverId: state?.value?.caregiverId,
      sharedQuestionnaireId: state?.value?.id,
    };
    const response = await getAPIHandler({
      endPoint: "getAllQuestionnaireResponses",
      paramsData: paramsToSend,
    });
    console.log(response, "getAllQuestionnaireResponses");

    if (!response || response.status !== 200) {
      setisDataLoading(false);
      return;
    }

    setUpdateList(false);
    const questionList =
      response?.data?.result?.questionnaireShared?.questionnaireQuestions || [];
    setListData(questionList);
    setisDataLoading(false);
  }

  useEffect(() => {
    initialData();
  }, []);

  const handleExpandClick = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle expansion of answers
  };
  // async function csvDownloder() {
  //   setIsCsvDataLoading(true);
  //   const paramsToSend = {
  //     caregiverId: state?.value?.caregiverId,
  //     sharedQuestionnaireId: state?.value?.id,
  //   };
  //   const res = await getAPIHandler({
  //     endPoint: "getAllQuestionnaireResponses",
  //     paramsData: paramsToSend,
  //   });
  //   if (!res) {
  //     setIsCsvDataLoading(false);
  //     return;
  //   }

  //   let dataToBeDownload = [];
  //   const tableHeadDataToCsv = ["S.No", "Survey Question", "Survey Answer"];

  //   dataToBeDownload.push(tableHeadDataToCsv);
  //   dataToBeDownload = [
  //     ...dataToBeDownload,
  //     ...res?.data?.result?.questionnaireShared?.questionnaireQuestions.map(
  //       (e, i) => [
  //         i + 1, // S.No
  //         e?.question ? e?.question : "NA", // Survey Question
  //         e?.questionResponse?.length > 0 && e?.questionResponse[0]?.ans
  //           ? e?.questionResponse[0]?.ans // Survey Answer
  //           : "NA", // Default to "NA" if no response
  //       ]
  //     ),
  //   ];

  //   setCsvData(dataToBeDownload);

  //   const csvBlob = new Blob(
  //     [dataToBeDownload.map((row) => row.join(",")).join("\n")],
  //     { type: "text/csv" }
  //   );
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = URL.createObjectURL(csvBlob);
  //   downloadLink.download = `${state?.value?.questionnaireShared?.questionnaireName_en}.csv`;

  //   setTimeout(() => {
  //     setIsCsvDataLoading(false);
  //     downloadLink.click();
  //   }, 1000);
  // }

  async function xlsxDownloader() {
    setIsCsvDataLoading(true);
    const paramsToSend = {
      caregiverId: state?.value?.caregiverId,
      sharedQuestionnaireId: state?.value?.id,
    };

    const res = await getAPIHandler({
      endPoint: "getAllQuestionnaireResponses",
      paramsData: paramsToSend,
    });

    if (!res) {
      setIsCsvDataLoading(false);
      return;
    }

    // Prepare data for XLSX
    const dataToBeDownload =
      res?.data?.result?.questionnaireShared?.questionnaireQuestions.map(
        (e, i) => ({
          "S.No": i + 1,
          Question: e?.question || "-",
          Answer:
            e?.questionResponse?.length > 0 && e?.questionResponse[0]?.ans
              ? e?.questionResponse[0]?.ans
              : "-",
        })
      );

    // Add header row
    const header = ["S.No", "Question", "Answer"];
    const finalData = [header, ...dataToBeDownload];

    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(dataToBeDownload, {
      header: ["S.No", "Question", "Answer"],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Survey Responses");

    // Generate file name
    const fileName =
      `${
        state?.value?.questionnaireShared?.questionnaireName_en ||
        "SurveyResponses"
      } ${
        dayjs(state?.value?.submittedAt).format("MMM D YYYY h:mm A") || "N/A"
      }`.replace(/\s+/g, "_") + "_Response.xlsx";

    // Write file and trigger download
    XLSX.writeFile(workbook, fileName);
    setIsCsvDataLoading(false);
  }

  return (
    <Grid container>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        item
        xs={12}
        mb={2}
        mt={2}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
              <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
            </IconButton>
            <Typography
              color={"#262626"}
              fontWeight={"600"}
              variant="h5"
              sx={{ ml: 2 }}
            >
              {state?.value?.questionnaireShared?.questionnaireName_en} - Survey
              Response
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end", // Align the date to the right
              textAlign: "right", // Align text to the right
            }}
          >
            <Tooltip title="Download CSV">
              <Button
                variant="contained"
                style={{ backgroundColor: "#1D60B3" }}
                sx={{ px: 4, py: 1.5, borderRadius: "24px" }}
                onClick={() => {
                  xlsxDownloader();
                }}
                loading={isCsvDataLoading}
              >
                Download Xlsx
              </Button>
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Align the date to the right
            textAlign: "right", // Align text to the right
          }}
        >
          <Typography
            color={"#2A73E0"}
            fontWeight={"500"}
            variant="body1"
            sx={{ mb: 0.5 }}
          >
            Submitted On:
          </Typography>
          <Typography
            color={"#262626"}
            fontWeight={"400"}
            variant="body2"
            sx={{
              backgroundColor: "#f0f8ff", // Light background for the date
              padding: "4px 8px", // Padding for the date
              borderRadius: "4px", // Rounded corners for the date box
              fontStyle: "italic", // Italicize the date for emphasis
            }}
          >
            {dayjs(state?.value?.submittedAt).format("MMM D YYYY h:mm A")}
          </Typography>
        </Box>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sx={{
          p: { lg: 4, sm: 0 },
          boxShadow: { lg: "rgba(0, 0, 0, 0.16) 0px 1px 4px", xs: "none" },
          mb: 3,
        }}
      >
        {isDataLoading ? (
          <CircularProgressBox />
        ) : !isDataLoading && listData.length === 0 ? (
          <NoDataFound message={"No Question & Answer Found"} />
        ) : (
          listData.map((item, index) => {
            const answerResponse =
              item.questionResponse[0]?.ans || "No answer provided";
            return (
              <Grid
                key={item.id}
                item
                container
                sm={12}
                lg={12}
                justifyContent={"flex-start"}
                sx={{
                  mt: { lg: 0, xs: 6 },
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    mb: 2,
                    borderRadius: 2,
                    boxShadow: 2,
                    transition: "transform 0.3s, box-shadow 0.3s",

                    backgroundOrigin: "border-box",
                    backgroundClip: "content-box",
                    "&:hover": {
                      transform: "scale(1.01)", // Slightly enlarge the card on hover
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.6)", // Enhance shadow on hover
                      // background:
                      //   "linear-gradient(white, white) padding-box, linear-gradient(140.68deg, #2A73E0 9.76%, #3c80f7 52.53%) border-box",
                      // border: "1px solid transparent",
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      color={"#262626"}
                      fontWeight={"600"}
                      variant="body1"
                      sx={{ mb: 1 }}
                    >
                      <strong>Q{index + 1}.</strong> {item.question}
                    </Typography>
                    <Typography
                      color={"#2A73E0"}
                      fontWeight={"400"}
                      variant="body2"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleExpandClick(index)} // Toggle answer visibility
                    >
                      <strong>A{index + 1}:</strong>{" "}
                      {expanded === index
                        ? answerResponse
                        : `${answerResponse.slice(0, 100)}...`}{" "}
                      {/* Truncate if not expanded */}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

export default SurveyResponses;
