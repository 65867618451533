import toast from "react-hot-toast";
// import { postApiHandlerWithToken } from "../config/service";
import { postAPIHandler } from "../config/service";

export const fileUploadHandler = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  
  try {
    const res = await postAPIHandler({
      endPoint: "uploadFile",
      dataToSend: formData,
    });

    if (!res || res.data.responseCode !== 200) {
      toast.error("Unable to upload media");
      console.error("Upload failed:", res);
      return null;
    }

    toast.success("Media uploaded successfully");
    return res.data.result;
  } catch (error) {
    toast.error("Error uploading media");
    console.error("Error during file upload:", error);
    return null;
  }
};

export const videoUploadHandler = async (file) => {
  const formData = new FormData();
  formData.append("video", file);
  // const id = toast.loading("Uploading Media");
  // const res = await postApiHandlerWithToken("uploadVideo", formData);
  // if (!res) {
  //   toast.error("Unable to upload video", { id: id });
  //   return;
  // }
  // toast.success("Video uploaded successfully", { id: id });
  // if (res.statusCode == 200) {
  //   return res?.result?.videoUrl;
  // }
  return null;
};
