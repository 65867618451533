import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast from "react-hot-toast";
import { ApiConfig } from "../../../config/ApiConfig";
import { GlobalStyles } from "@mui/material";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
}));

const PrivacyPolicyContainer = styled(Grid)(({ theme }) => ({
  "& .belowBox": {
    border: "1px solid #cccccc",
    padding: "15px",
    borderRadius: "10px",
    height: "120px",
  },
  "& .heading": {
    marginTop: "20px",
  },
}));

const EditContact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [viewData, setViewData] = useState(null);
  console.log("viewData: ", viewData);
  const [isLoading, setIsLoading] = useState(false);

  const type = location?.state?.type;

  const formValidationSchema = yup.object().shape({
    phoneNumber: yup.string().required("Phone number is required"),
  });

  const getStaticData = async () => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getOneStaticContent,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          contentType: "supportContact",
        },
      });
      if (res?.data?.responseCode === 200) {
        setViewData(res?.data?.result[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editStaticData = async (phoneNumber) => {
    let myId = viewData?.id;
    setIsLoading(true);

    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.editStaticContent,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        data: {
          staticContentId: myId,
          description_en: phoneNumber,
        },
      });
      if (res?.data?.responseCode === 200) {
        toast.success(res?.data?.responseMessage);
        navigate("/static-content");
      }
    } catch (error) {
      console.log(error);
      if (error?.response) {
        toast.error(error?.response?.data?.responseMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <Root>
      <GlobalStyles
        styles={{
          ".react-tel-input .country-list": {
            outline: "none",
            zIndex: 1,
            listStyle: "none",
            position: "fixed",
            padding: 0,
            margin: "10px 0 10px -1px",
            boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.35)",
            backgroundColor: "white",
            width: "300px",
            maxHeight: "200px",
            overflowY: "scroll",
            borderRadius: "0 0 3px 3px",
          },
        }}
      />
      <PrivacyPolicyContainer>
        <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
            <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <Typography variant="h5">Edit Support Contact</Typography>
        </Box>

        <Box>
          {!isLoading && viewData ? (
            <Formik
              enableReinitialize={true}
              initialValues={{ phoneNumber: viewData?.description_en || "" }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                editStaticData(values.phoneNumber);
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Box
                    className="belowBox"
                    mb={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      overflow: "hidden",
                      // position: "relative",
                    }}
                  >
                    <Box marginBottom={2} mt={2}>
                      <Typography variant="body1" color="textSecondary">
                        Phone Number
                      </Typography>
                      <Field name="phoneNumber">
                        {({ field }) => (
                          <PhoneInput
                            {...field}
                            countryCodeEditable={false}
                            placeholder="Enter phone number"
                            onChange={(value, country) => {
                              // Ensure + sign at the start of the country code
                              const formattedPhoneNumber = `+${
                                country.dialCode
                              }-${value.slice(country.dialCode.length)}`;
                              setFieldValue(
                                "phoneNumber",
                                formattedPhoneNumber
                              );
                            }}
                            inputStyle={{
                              // width: "100%",
                              height: "45px",
                              marginTop: "0px",
                              fontWeight: "normal",
                              border: "1px solid #e7e7e7",
                            }}
                            dropdownClass="phone-dropdown"
                            sx={{
                              "& .react-tel-input .country-list": {
                                position: "fixed",
                              },
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" type="submit">
                      Update
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          ) : (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
          )}
        </Box>
      </PrivacyPolicyContainer>
    </Root>
  );
};

export default EditContact;
