import * as React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Grid, Avatar, Box } from "@mui/material";
import DropDown from "./common/Dropdown";
import axios from "axios";
import toast from "react-hot-toast";
import { ApiConfig } from "../config/ApiConfig";

const ReassignPopUp = ({
  open,
  setOpen,
  allAshaWorker,
  selectedCareGiverId,
  setUpdateList,
}) => {
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const options = allAshaWorker?.map((worker) => ({
    label: worker?.fullName_en,
    value: worker?.id,
    detail: worker,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const assignAsha = async () => {
    setIsLoading(true);

    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.updateUserDetails,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        data: {
          userId: selectedCareGiverId,
          assignedAshaWorker: selectedOption?.detail?.id,
        },
      });

      if (res?.data?.responseCode === 200) {
        toast.success("ASHA worker assigned successfully.");
        handleClose();
        setUpdateList(true);
        setSelectedOption(null);
      } else {
        toast.error(res?.data?.responseMessage || "Something went wrong.");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.responseMessage ||
          "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderDetails = (title, selectedOption) => (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: 2,
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        alignItems: "center",
        gap: 3,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.01)",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Avatar
        sx={{
          height: 160,
          width: 160,
          transition: "transform 0.3s ease",
          "&:hover": { transform: "scale(1.1)" },
        }}
        src={selectedOption?.detail?.profileUrl}
      />
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: "#2A73E0",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: 1,
            mb: 2,
          }}
        >
          {title}
        </Typography>
        {[
          { label: "Full Name", value: selectedOption?.detail?.fullName_en },
          { label: "Email", value: selectedOption?.detail?.email_en },
          {
            label: "Phone No.",
            value: selectedOption?.detail?.mobileNumber_en,
          },
          { label: "Address", value: selectedOption?.detail?.address_en },
        ].map((detail, index) => (
          <Box key={index} sx={{ display: "flex", mb: 1 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", minWidth: 120, color: "text.primary" }}
            >
              {detail.label}:
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {detail.value || "N/A"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          width: "100%",
          maxWidth: 600,
          maxHeight: "90vh",
          p: 4,
          borderRadius: 2,
          boxShadow: 2,
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" mb={3}>
          Assign ASHA Worker
        </Typography>

        <Grid container spacing={3} alignItems="center" fullWidth>
          {/* Dropdown Section */}
          <Grid item xs={12}>
            <DropDown
              label="Select ASHA Worker"
              options={options}
              value={selectedOption}
              onChange={setSelectedOption}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        {selectedOption && (
          <Box mt={4}>
            {renderDetails("ASHA Worker Details", selectedOption)}
          </Box>
        )}

        <Divider sx={{ my: 4 }} />

        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              onClick={assignAsha}
              variant="contained"
              sx={{
                bgcolor: "#fe352c",
                fontSize: "0.9rem",
                px: 3, // Horizontal padding
                py: 1, // Vertical padding
                whiteSpace: "nowrap", // Prevents text wrapping
                borderRadius: 2, // Rounded button
                minWidth: 150, // Ensures buttons are of consistent size
                textAlign: "center",
              }}
              disabled={isLoading}
            >
              Assign ASHA Worker
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                bgcolor: "gray",
                fontSize: "0.9rem",
                px: 3,
                py: 1,
                whiteSpace: "nowrap",
                borderRadius: 2,
                minWidth: 150,
                textAlign: "center",
              }}
              disabled={isLoading}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ReassignPopUp;
