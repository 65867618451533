import React from "react";
import { Box, Paper, Grid, Divider, IconButton } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GeneralInfo from "./GeneralInfo";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SurveyLisiting from "./SurveyListing";

export default function ViewCareGiver() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
              <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" justifyContent="center">
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="General Details" />
              <Tab label="Survey's Responses" />
            </Tabs>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <TabPanel value={value} index={0}>
        <GeneralInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SurveyLisiting />
      </TabPanel>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return <Box>{value === index && <Box>{children}</Box>}</Box>;
}
