import {
  Grid,
  Button,
  Typography,
  Divider,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Pagination,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useRef, useState } from "react";
import Filter from "../../../../../component/Filter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BlockPopUp from "../../../../../component/BlockPopUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import { Delete, LensTwoTone } from "@mui/icons-material";
import NoDataFound from "../../../../../component/NoDataFound";
import DeletePopUp from "../../../../../component/DeletePopUp";
import QuestionPopup from "../Question/QuestionModal";
import { getAPIHandler, putAPIHandler } from "../../../../../config/service";
import CircularProgressBox from "../../../../../component/CircularProgressBox";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import dayjs from "dayjs";
const tableHeadData = [
  "S.No",
  "Question",
  "Answer Type",
  "Created Date & Time",
  "Updated Date & Time",
  "Action",
];

const AssessmentType = {
  ADD: "ADD",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

const Question = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(AssessmentType.ADD);
  const [listData, setListData] = useState([]);
  const [isDataLoading, setisDataLoading] = useState(true);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const navigate = useNavigate();
  const [currentBusinessOwnerData, setCurrentBusinessOwnerData] = useState({});
  const [csvData, setCsvData] = useState([]);
  const csvRef = useRef();
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [questionPopUp, setQuestionPopup] = useState(false);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const { id } = useParams();

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    name: "",
    fromDate: "",
    toDate: "",
  });

  async function blockhandler() {
    const payLoadToSend = {
      questionId: currentBusinessOwnerData?.id,
      status: currentBusinessOwnerData?.status == "BLOCK" ? "ACTIVE" : "BLOCK",
    };
    const response = await putAPIHandler({
      endPoint: "updateQuestionnaireQuestion",
      dataToSend: payLoadToSend,
    });
    if (!response) {
      return;
    }
    setIsDataUpdate((p) => !p);
  }

  async function initialData(params) {
    const filterToSend = filter;
    setisDataLoading(true);
    const paramsToSend = {
      questionnaireId: id,
      ...Object.fromEntries(
        Object.entries(filterToSend).filter(([_, v]) => v != "")
      ),
    };
    const response = await getAPIHandler({
      endPoint: "listAllQuestionnaireQuestion",
      paramsData: paramsToSend,
    });
    if (!response || response.status !== 200) {
      setisDataLoading(false);
      return;
    }
    const questionsList = response?.data?.result?.questionsList || [];
    const totalPages = response?.data?.result?.totalPages || 1;

    setListData([]);
    setListData(questionsList);
    setTotalPages(totalPages);

    setisDataLoading(false);
  }

  useEffect(() => {
    initialData();
  }, [isDataUpdate, filter]);

  async function csvDownloder() {
    setIsCsvDataLoading(true);
    const paramsToSend = {
      questionnaireId: id,
    };
    const res = await getAPIHandler({
      endPoint: "listAllQuestionnaireQuestion",
      paramsData: paramsToSend,
    });
    if (!res) {
      setIsCsvDataLoading(false);
      return;
    }

    let dataToBeDownload = [];
    const tableHeadDataToCsv = [
      "S.No",
      "Question",
      "Answer Type",
      "Created Date & Time",
      "Updated Date & Time",
    ];

    dataToBeDownload.push(tableHeadDataToCsv);
    dataToBeDownload = [
      ...dataToBeDownload,
      ...res?.data?.result?.questionsList.map((e, i) => [
        i + 1,
        e?.question_en ? e?.question_en : "-",
        e?.answerType ? e?.answerType : "-",
        e?.createdAt ? dayjs(e?.createdAt).format("MMM D YYYY h:mm A") : "-",
        e?.createdAt ? dayjs(e?.createdAt).format("MMM D YYYY h:mm A") : "-",
      ]),
    ];

    setCsvData(dataToBeDownload);

    const csvBlob = new Blob(
      [dataToBeDownload.map((row) => row.join(",")).join("\n")],
      { type: "text/csv" }
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "question_data.csv";

    setTimeout(() => {
      setIsCsvDataLoading(false);
      downloadLink.click();
    }, 1000);
  }

  const deleteHandler = async () => {
    const payLoadToSend = {
      questionId: currentBusinessOwnerData?.id,
      status: "DELETE",
    };
    const response = await putAPIHandler({
      endPoint: "updateQuestionnaire",
      dataToSend: payLoadToSend,
    });
    if (!response) {
      return;
    }
    setIsDataUpdate((p) => !p);
  };

  return (
    <Grid container>
      <Grid
        container
        item
        style={{ backgroundColor: "#ffffff" }}
        sx={{ mt: { xs: 6, md: 2, lg: 0 } }}
      >
        <Grid item mb={4} container justifyContent={"space-between"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
              <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
            </IconButton>
            <Typography color={"#262626"} fontWeight={"600"} variant="h5">
              Questions
            </Typography>
          </Box>

          <Grid
            container
            item
            xs={6}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={4}
          >
            <Tooltip title="Download CSV">
              <Button
                variant="contained"
                style={{ backgroundColor: "#1D60B3" }}
                sx={{ px: 4, py: 1.5, borderRadius: "24px" }}
                onClick={() => {
                  csvDownloder();
                }}
                loading={isCsvDataLoading}
              >
                Download CSV
              </Button>
            </Tooltip>
            <Button
              variant="outlined"
              sx={{
                color: "#2A73E0",
                border: "1px solid #2A73E0",
                px: { xs: 3 },
                py: { xs: 1.3 },
                mb: { xs: 2, md: 0 },
              }}
              onClick={() => {
                setData({});
                setType(AssessmentType.ADD);
                setQuestionPopup(true);
              }}
            >
              <AddIcon />
              Questions
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={11} alignItems={"center"}>
          <Divider sx={{ background: "#DFDFDF" }} />
        </Grid>

        <Filter setFilter={setFilter} />

        <TableContainer
          className="removeScrollbar"
          component={Paper}
          sx={{
            mt: 4,
            overflowX: "scroll",
            width: "100%",
            // minHeight: "520px",
          }}
        >
          <Table sx={{ minWidth: 1200 }}>
            {isDataLoading ? (
              <>
                <CircularProgressBox />
              </>
            ) : !isDataLoading && listData.length == 0 ? (
              <>
                <NoDataFound message={"No Questions Found"} />
              </>
            ) : (
              <>
                <TableHead
                  sx={{
                    bgcolor: "#2A73E0",
                  }}
                >
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "4px",
                        height: "45px",
                      },
                    }}
                  >
                    {tableHeadData.map((e, i) => (
                      <TableCell key={i} align="center" sx={{ color: "white" }}>
                        {e}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.map((data, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: "4px",
                        },
                      }}
                    >
                      <TableCell align="center" scope="row">
                        {currentPage ? (currentPage - 1) * 10 + (i + 1) : i + 1}
                      </TableCell>

                      <Tooltip
                        title={data?.question_en ? data?.question_en : "-"}
                      >
                        <TableCell align="center" scope="row">
                          {data?.question_en
                            ? data?.question_en.length > 20
                              ? data?.question_en.slice(0, 20) + "..."
                              : data?.question_en
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <Tooltip
                        title={data?.answerType ? data?.answerType : "-"}
                      >
                        <TableCell align="center" scope="row">
                          {data?.answerType
                            ? data?.answerType.length > 20
                              ? data?.answerType.charAt(0).toUpperCase() +
                                data?.answerType.slice(1, 20).toLowerCase() +
                                "..."
                              : data?.answerType.charAt(0).toUpperCase() +
                                data?.answerType.slice(1).toLowerCase()
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <TableCell align="center" scope="row">
                        {dayjs(data?.createdAt).format("MMM D YYYY h:mm A")}
                      </TableCell>

                      <TableCell align="center" scope="row">
                        {dayjs(data?.updatedAt).format("MMM D YYYY h:mm A")}
                      </TableCell>

                      <TableCell align="center" scope="row">
                        <Tooltip title={"View"}>
                          <IconButton
                            variant="filled"
                            sx={{ color: "#5D9FED" }}
                            onClick={() => {
                              setData(data);
                              setType(AssessmentType.VIEW);
                              setQuestionPopup(true);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title={data?.status == "BLOCK" ? "Unblock" : "Block"}
                        >
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setCurrentBusinessOwnerData(data);
                              setOpen(true);
                            }}
                            sx={{
                              color: data?.status == "BLOCK" ? "red" : "green",
                            }}
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={"Delete"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setCurrentBusinessOwnerData(data);
                              setDeletePopUp(true);
                            }}
                            sx={{
                              color: "red",
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={"Edit questions"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setData(data);
                              setType(AssessmentType.EDIT);
                              setQuestionPopup(true);
                            }}
                            sx={{ color: "#2B2B52" }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} mt={4}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            defaultPage={filter.page}
            onChange={(e, page) => {
              const updatedFilter = { ...filter, page: page };
              setCurrentPage(page);
              setFilter(updatedFilter);
            }}
            sx={{ color: "#681e65" }}
          />
        )}
      </Grid>

      <BlockPopUp
        open={open}
        setOpen={setOpen}
        funcToExecute={blockhandler}
        isBlocked={currentBusinessOwnerData?.status == "BLOCK" ? false : true}
      />

      <DeletePopUp
        funcToExecute={deleteHandler}
        open={deletePopUp}
        setOpen={setDeletePopUp}
      />

      <QuestionPopup
        open={questionPopUp}
        setOpen={setQuestionPopup}
        type={type}
        data={data}
        onSuccess={() => setIsDataUpdate((prev) => !prev)}
      />
    </Grid>
  );
};

export default Question;
