import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import React from "react";

const LoginLayoutWrapper = styled("div")({
  height: "100dvh",
  width: "100dvw",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  position: "relative",
  paddingLeft: "100px",
  "@media(max-width:1024px)": {
    paddingLeft: "0px",
    // padding: "10px 10px",
  },
});

const CompanyLogoContainer = styled("img")({
  position: "absolute",
  width: "100px",
  height: "85px",
  top: "20px",
  "@media(max-width:1280px)": {},
});

const LoginLayoutImageContainer = styled("div")({
  width: "50%",
  "@media(max-width:1280px)": {
    display: "none",
  },
});

const LoginLayout = ({ children }) => {
  return (
    <LoginLayoutWrapper>
      {/* <CompanyLogoContainer src={"/images/Group 1261154272.svg"} /> */}

      {children}
      <Box
        sx={{
          top: "-60px",
          width: "55%",
          height: "110dvh",
          position: "relative",
          "@media(max-width:1280px)": {
            display: "none",
          },
        }}
      >
        {/* Background Image with Lower Opacity */}
        <Box
          sx={{
            backgroundImage: "url(/images/Group 1261154272.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "brightness(0.2)",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
        <Box
          component={"img"}
          src={"/images/Group 1261154272.svg"}
          sx={{
            position: "absolute",
            right: "15%",
            width: "30vw",
            top: "20%",
          }}
        />
      </Box>
    </LoginLayoutWrapper>
  );
};

export default LoginLayout;
