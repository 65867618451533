import { createContext, useState, useContext } from "react";

export const AuthContext = createContext();

export default function AuthProvider(props) {
  const [isLoginType, setIsLoginType] = useState("");
  const [userDetail, setUserDetail] = useState({});
  console.log("sdd: ", userDetail);

  let data = {
    setIsLoginType,
    isLoginType,
    userDetail,
    setUserDetail,
  };
  console.log("datavalue", data);
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
