import {
  Grid,
  Button,
  Typography,
  Divider,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Pagination,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
// import shortUid from "short-uuid";
import React, { useEffect, useId, useRef, useState } from "react";
import Filter from "../../../../component/Filter";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import BlockPopUp from "../../../../component/BlockPopUp";
// import { useScrollContainer } from "react-indiana-drag-scroll";
import NoDataFound from "../../../../component/NoDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { CSVDownload, CSVLink } from "react-csv";
import BlockIcon from "@mui/icons-material/Block";
import toast from "react-hot-toast";
import { Delete, LensTwoTone } from "@mui/icons-material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import DeletePopUp from "../../../../component/DeletePopUp";
import { getAPIHandler, putAPIHandler } from "../../../../config/service";
import CircularProgressBox from "../../../../component/CircularProgressBox";
const tableHeadData = [
  "S.No",
  "Questionnaire Name",
  "No. Of Questions",
  "Date & Time",
  "Action",
];

const QuestionnaireCategory = () => {
  const [open, setOpen] = useState(false);
  const [advisorPopUp, setadvisorPopUp] = useState(false);
  const [listData, setListData] = useState([]);
  const [isDataLoading, setisDataLoading] = useState(true);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const navigate = useNavigate();
  // const scrollContainer = useScrollContainer();
  const [currentBusinessOwnerData, setCurrentBusinessOwnerData] = useState({});
  const [csvData, setCsvData] = useState([]);
  const csvRef = useRef();
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const { state } = useLocation();
  const questionaireIdProps = state?.propquestionid;
  console.log("questionaireIdProps: ", questionaireIdProps);
  const questionaireproname = state?.proquestionairname;
  const questionairname = state?.value?.categoryName_en;
  const questionaireId = state?.value?.id;
  console.log("questionaireId: ", questionaireId);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    name: "",
    fromDate: "",
    toDate: "",
  });

  async function blockhandler() {
    const payLoadToSend = {
      questionnaireId: currentBusinessOwnerData?.id,
      status: currentBusinessOwnerData?.status == "BLOCK" ? "ACTIVE" : "BLOCK",
    };
    const response = await putAPIHandler({
      endPoint: "updateQuestionnaire",
      dataToSend: payLoadToSend,
    });
    if (!response) {
      return;
    }
    setIsDataUpdate((p) => !p);
  }

  async function initialData(params) {
    const filterToSend = filter;
    setisDataLoading(true);
    const paramsToSend = {
      categoryId: state?.value?.id || questionaireIdProps,
      ...Object.fromEntries(
        Object.entries(filterToSend).filter(([_, v]) => v != "")
      ),
    };
    const response = await getAPIHandler({
      endPoint: "listAllQuestionnaire",
      paramsData: paramsToSend,
    });
    console.log(response, "response");
    if (!response || response.status !== 200) {
      setisDataLoading(false);
      return;
    }
    const questionnaireList = response?.data?.result?.questionnaireList || [];
    const totalPages = response?.data?.result?.totalPages || 1;

    setListData([]);
    setListData(questionnaireList);
    setTotalPages(totalPages);

    setisDataLoading(false);
  }
  useEffect(() => {
    initialData();
  }, [isDataUpdate, filter]);

  async function csvDownloder() {
    setIsCsvDataLoading(true);
    const paramsToSend = {
      categoryId: state?.value?.id,
    };
    const res = await getAPIHandler({
      endPoint: "listAllQuestionnaire",
      paramsData: paramsToSend,
    });
    if (!res) {
      setIsCsvDataLoading(false);
      return;
    }

    let dataToBeDownload = [];
    const tableHeadDataToCsv = [
      "S.No",
      "Questionnaire Name",
      "No. Of Questions",
      "Date & Time",
    ];

    dataToBeDownload.push(tableHeadDataToCsv);
    dataToBeDownload = [
      ...dataToBeDownload,
      ...res?.data?.result?.questionnaireList.map((e, i) => [
        i + 1,
        e?.questionnaireName_en ? e?.questionnaireName_en : "-",
        e?.questionsCount ? e?.questionsCount : "0",
        e?.createdAt ? dayjs(e?.createdAt).format("MMM D YYYY h:mm A") : "-",
      ]),
    ];

    setCsvData(dataToBeDownload);

    const csvBlob = new Blob(
      [dataToBeDownload.map((row) => row.join(",")).join("\n")],
      { type: "text/csv" }
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "questionnaire_data.csv";

    setTimeout(() => {
      setIsCsvDataLoading(false);
      downloadLink.click();
    }, 1000);
  }

  const deleteHandler = async () => {
    const payLoadToSend = {
      questionnaireId: currentBusinessOwnerData?.id,
      status: "DELETE",
    };
    const response = await putAPIHandler({
      endPoint: "updateQuestionnaire",
      dataToSend: payLoadToSend,
    });
    if (!response) {
      return;
    }
    setIsDataUpdate((p) => !p);
  };

  return (
    <Grid container>
      <Grid
        container
        item
        style={{ backgroundColor: "#ffffff" }}
        sx={{ mt: { xs: 6, md: 2, lg: 0 } }}
      >
        <Grid item mb={4} container justifyContent={"space-between"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => navigate("/questionnaire")}
              sx={{ color: "#2A73E0" }}
            >
              <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
            </IconButton>
            <Tooltip
              title={
                state?.value?.categoryName_en || questionaireproname
                  ? state?.value?.categoryName_en || questionaireproname
                  : "-"
              }
            >
              <Typography color={"#262626"} fontWeight={"600"} variant="h5">
                {state?.value?.categoryName_en
                  ? state?.value?.categoryName_en.length > 20
                    ? state?.value?.categoryName_en.slice(0, 20) + "..."
                    : state?.value?.categoryName_en
                  : "0" || questionaireproname
                  ? questionaireproname.length > 30
                    ? questionaireproname.slice(0, 30) + "..."
                    : questionaireproname
                  : "0"}{" "}
                - Questionnaire
              </Typography>
            </Tooltip>
          </Box>

          <Grid
            container
            item
            xs={6}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={4}
          >
            <Tooltip title="Download CSV">
              <Button
                variant="contained"
                style={{ backgroundColor: "#1D60B3" }}
                sx={{ px: 4, py: 1.5, borderRadius: "24px" }}
                onClick={() => {
                  csvDownloder();
                }}
                loading={isCsvDataLoading}
              >
                Download CSV
              </Button>
            </Tooltip>

            <Button
              variant="outlined"
              sx={{
                color: "#2A73E0",
                border: "1px solid #2A73E0",
                px: { xs: 3 },
                py: { xs: 1.3 },
                mb: { xs: 2, md: 0 },
              }}
              onClick={() => {
                navigate("/questionnaire/add-questionnaire", {
                  state: {
                    categoryId: state?.value?.id,
                    questionaire: questionaireId,
                    questionairname: questionairname,
                  },
                });
              }}
            >
              <AddIcon />
              Questionnaire
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={11} alignItems={"center"}>
          <Divider sx={{ background: "#DFDFDF" }} />
        </Grid>

        <Filter setFilter={setFilter} />

        <TableContainer
          className="removeScrollbar"
          // ref={scrollContainer.ref}
          component={Paper}
          sx={{
            mt: 4,
            overflowX: "scroll",
            width: "100%",
            // minHeight: "520px",
          }}
        >
          <Table sx={{ minWidth: 1200 }}>
            {isDataLoading ? (
              <>
                <CircularProgressBox />
              </>
            ) : !isDataLoading && listData.length == 0 ? (
              <>
                <NoDataFound message={"No Questionnaire Found"} />
              </>
            ) : (
              <>
                <TableHead
                  sx={{
                    bgcolor: "#2A73E0",
                  }}
                >
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "4px",
                        height: "45px",
                      },
                    }}
                  >
                    {tableHeadData.map((e, i) => (
                      <TableCell key={i} align="center" sx={{ color: "white" }}>
                        {e}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.map((data, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: "4px",
                        },
                      }}
                    >
                      <TableCell align="center" scope="row">
                        {currentPage ? (currentPage - 1) * 10 + (i + 1) : i + 1}
                      </TableCell>

                      <Tooltip
                        title={
                          data?.questionnaireName_en
                            ? data?.questionnaireName_en
                            : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.questionnaireName_en
                            ? data?.questionnaireName_en.length > 20
                              ? data?.questionnaireName_en.slice(0, 20) + "..."
                              : data?.questionnaireName_en
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <Tooltip
                        title={
                          data?.questionsCount ? data?.questionsCount : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.questionsCount
                            ? data?.questionsCount.length > 20
                              ? data?.questionsCount.slice(0, 20) + "..."
                              : data?.questionsCount
                            : "0"}
                        </TableCell>
                      </Tooltip>

                      <TableCell align="center" scope="row">
                        {dayjs(data?.createdAt).format("MMM D YYYY h:mm A")}
                      </TableCell>

                      <TableCell align="center" scope="row">
                        <Tooltip title={"View"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              navigate(
                                `/questionnaire/view-questionnaire/${data.id}`
                              );
                            }}
                            sx={{ color: "#5D9FED" }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title={data?.status == "BLOCK" ? "Unblock" : "Block"}
                        >
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setCurrentBusinessOwnerData(data);
                              setOpen(true);
                            }}
                            sx={{
                              color: data?.status == "BLOCK" ? "red" : "green",
                            }}
                          >
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              setCurrentBusinessOwnerData(data);
                              setDeletePopUp(true);
                            }}
                            sx={{
                              color: "red",
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Edit owner profile"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              navigate("/questionnair/edit-questionnaire", {
                                state: {
                                  value: data,
                                  questionaire:
                                    questionaireId || questionaireIdProps,
                                  questionairname:
                                    questionairname || questionaireproname,
                                },
                              });
                            }}
                            sx={{ color: "#2B2B52" }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} mt={4}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            defaultPage={filter.page}
            onChange={(e, page) => {
              const updatedFilter = { ...filter, page: page };
              setCurrentPage(page);
              setFilter(updatedFilter);
            }}
            sx={{ color: "#681e65" }}
          />
        )}
      </Grid>

      <BlockPopUp
        open={open}
        setOpen={setOpen}
        funcToExecute={blockhandler}
        isBlocked={currentBusinessOwnerData?.status == "BLOCK" ? false : true}
      />

      <DeletePopUp
        funcToExecute={deleteHandler}
        open={deletePopUp}
        setOpen={setDeletePopUp}
      />
    </Grid>
  );
};

export default QuestionnaireCategory;
