import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  styled,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { ApiConfig } from "../../config/ApiConfig";

const PolicyWrapper = styled("div")({
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: "100px",
  backgroundColor: "#f5f5f5",
  "@media(max-width:640px)": {
    paddingTop: "100px",
  },
});

const ContentBox = styled(Box)({
  maxHeight: "85vh",
  overflowY: "auto",
  padding: "32px",
  width: "100%",
  marginTop: "10px",
  "@media(max-width:640px)": {
    width: "90%",
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const AppBarStyled = styled(AppBar)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: "#ffffff",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
  backdropFilter: "blur(10px)",
});

const Logo = styled("img")({
  height: "100px",
  marginRight: "16px",
  maxWidth: "100%",
});

const TermsAndConditions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewData, setViewData] = useState("");

  const getStaticData = async () => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getStaticContentByType,
        params: {
          contentType: "termsCondition",
        },
      });

     
      if (res) {
      

        
        setViewData(res?.data?.result[0]?.description || "");
      }
    } catch (error) {
      console.error("Error fetching terms and conditions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStaticData();
  }, []);

  

  return (
    <>
      {/* Top AppBar with logo */}
      <AppBarStyled>
        <Toolbar>
          <Logo src="/images/Group 1261154272.svg" alt="Company Logo" />
        </Toolbar>
      </AppBarStyled>

      {/* Main content */}
      <PolicyWrapper>
        <ContentBox>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography
                variant="h4"
                sx={{ fontWeight: 600, paddingBottom: "15px" }}
              >
                Terms and Conditions
              </Typography>
              <Typography
                variant="body1"
                sx={{ lineHeight: 1.8 }}
                dangerouslySetInnerHTML={{ __html: viewData }}
              />
            </>
          )}
        </ContentBox>
      </PolicyWrapper>
    </>
  );
};

export default TermsAndConditions;
