import {
  Grid,
  Divider,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Pagination,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
// import shortUid from "short-uuid";
import React, { useEffect, useState } from "react";
import Filter from "../../../../component/Filter";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import NoDataFound from "../../../../component/NoDataFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAPIHandler } from "../../../../config/service";
import CircularProgressBox from "../../../../component/CircularProgressBox";

const tableHeadData = [
  "S.No",
  "Category",
  "Questionnaire Name",
  "No. Of Questions",
  "Response Submited Date & Time",
  "Action",
];

const SurveyLisiting = () => {
  const [listData, setListData] = useState([]);
  console.log("listData: ", listData);
  const [isDataLoading, setisDataLoading] = useState(true);
  const [isDataUpdate, setIsDataUpdate] = useState(false);
  const navigate = useNavigate();
  // const scrollContainer = useScrollContainer();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateList, setUpdateList] = useState(false);
  const { state } = useLocation();
  const [isCsvDataLoading, setIsCsvDataLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    search: "",
    fromDate: "",
    toDate: "",
  });

  async function initialData(params) {
    const filterToSend = filter;
    setisDataLoading(true);
    const paramsToSend = {
      caregiverId: state?.value?.id,
      ...Object.fromEntries(
        Object.entries(filterToSend).filter(([_, v]) => v != "")
      ),
    };
    const response = await getAPIHandler({
      endPoint: "listAllQuestionnaireSubmittedByCaregiver",
      paramsData: paramsToSend,
    });
    console.log(response, "response");
    if (!response || response.status !== 200) {
      setisDataLoading(false);

      return;
    }
    setUpdateList(false);
    const questionnaireList = response?.data?.result?.questionnaireList || [];
    const totalPages = response?.data?.result?.totalPages || 1;

    setListData([]);
    setListData(questionnaireList);
    setTotalPages(totalPages);
    setisDataLoading(false);
  }
  useEffect(() => {
    initialData();
  }, [isDataUpdate, filter, updateList]);

  async function csvDownloder() {
    // setIsCsvDataLoading(true);

    let dataToBeDownload = [];
    const tableHeadDataToCsv = [
      "S.No",
      "Category",
      "Questionnaire Name",
      "No. Of Questions",
      "Response Submitted Date & Time",
    ];

    dataToBeDownload.push(tableHeadDataToCsv);
    dataToBeDownload = [
      ...dataToBeDownload,
      ...listData?.map((e, i) => [
        i + 1,

        e?.questionnaireShared?.questionnairesCategoryDetails?.categoryName_en
          ? e?.questionnaireShared?.questionnairesCategoryDetails
              ?.categoryName_en
          : "-",
        e?.questionnaireShared?.questionnaireName_en
          ? e?.questionnaireShared?.questionnaireName_en
          : "-",
        e?.questionsCount ? e?.questionsCount : "-",
        e?.createdAt ? dayjs(e?.createdAt).format("MMM D YYYY h:mm A") : "-",
      ]),
    ];

    const csvBlob = new Blob(
      [dataToBeDownload.map((row) => row.join(",")).join("\n")],
      { type: "text/csv" }
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = "caregiver_data.csv";

    setTimeout(() => {
      setIsCsvDataLoading(false);
      downloadLink.click();
    }, 1000);
  }
  return (
    <Grid container>
      <Grid
        container
        item
        style={{ backgroundColor: "#ffffff" }}
        sx={{ mt: { xs: 6, md: 2, lg: 0 } }}
      >
        <Grid item xs={11} alignItems={"center"}>
          <Divider sx={{ background: "#DFDFDF" }} />
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          mr={2}
        >
          <Tooltip title="Download CSV">
            <Button
              variant="contained"
              style={{ backgroundColor: "#1D60B3" }}
              sx={{ px: 4, py: 1.5, borderRadius: "24px" }}
              onClick={() => {
                csvDownloder();
              }}
              loading={isCsvDataLoading}
            >
              Download CSV
            </Button>
          </Tooltip>
        </Grid>

        <Filter setFilter={setFilter} />

        <TableContainer
          className="removeScrollbar"
          // ref={scrollContainer.ref}
          component={Paper}
          sx={{
            mt: 4,
            overflowX: "scroll",
            width: "100%",
            // minHeight: "520px",
          }}
        >
          <Table sx={{ minWidth: 1200 }}>
            {isDataLoading ? (
              <>
                <CircularProgressBox />
              </>
            ) : !isDataLoading && listData.length == 0 ? (
              <>
                <NoDataFound message={"No Survey Responses Found"} />
              </>
            ) : (
              <>
                <TableHead
                  sx={{
                    bgcolor: "#2A73E0",
                  }}
                >
                  <TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        padding: "4px",
                        height: "45px",
                      },
                    }}
                  >
                    {tableHeadData.map((e, i) => (
                      <TableCell key={i} align="center" sx={{ color: "white" }}>
                        {e}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {listData.map((data, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: "4px",
                        },
                      }}
                    >
                      <TableCell align="center" scope="row">
                        {currentPage ? (currentPage - 1) * 10 + (i + 1) : i + 1}
                      </TableCell>

                      <Tooltip
                        title={
                          data?.questionnaireShared
                            ?.questionnairesCategoryDetails?.categoryName_en
                            ? data?.data?.questionnaireShared
                                ?.questionnairesCategoryDetails?.categoryName_en
                            : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.questionnaireShared
                            ?.questionnairesCategoryDetails?.categoryName_en
                            ? data?.questionnaireShared
                                ?.questionnairesCategoryDetails?.categoryName_en
                                ?.length > 20
                              ? data?.questionnaireShared?.questionnairesCategoryDetails?.categoryName_en.slice(
                                  0,
                                  20
                                ) + "..."
                              : data?.questionnaireShared
                                  ?.questionnairesCategoryDetails
                                  ?.categoryName_en
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <Tooltip
                        title={
                          data?.questionnaireShared?.questionnaireName_en
                            ? data?.questionnaireShared?.questionnaireName_en
                            : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.questionnaireShared?.questionnaireName_en
                            ? data?.questionnaireShared?.questionnaireName_en
                                ?.length > 20
                              ? data?.questionnaireShared?.questionnaireName_en.slice(
                                  0,
                                  20
                                ) + "..."
                              : data?.questionnaireShared?.questionnaireName_en
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <Tooltip
                        title={
                          data?.questionsCount ? data?.questionsCount : "-"
                        }
                      >
                        <TableCell align="center" scope="row">
                          {data?.questionsCount
                            ? data?.questionsCount.length > 20
                              ? data?.questionsCount.slice(0, 20) + "..."
                              : data?.questionsCount
                            : "-"}
                        </TableCell>
                      </Tooltip>

                      <TableCell align="center" scope="row">
                        {dayjs(data?.submittedAt).format("MMM D YYYY h:mm A")}
                      </TableCell>

                      <TableCell align="center" scope="row">
                        <Tooltip title={"View"}>
                          <IconButton
                            variant="filled"
                            onClick={() => {
                              navigate(
                                "/caregiver-management/view-caregiver/view-survey",
                                {
                                  state: { value: data },
                                }
                              );
                            }}
                            sx={{ color: "#5D9FED" }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid item container justifyContent={"flex-end"} mt={4}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            defaultPage={filter.page}
            onChange={(e, page) => {
              const updatedFilter = { ...filter, page: page };
              setCurrentPage(page);
              setFilter(updatedFilter);
            }}
            sx={{ color: "#681e65" }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SurveyLisiting;
