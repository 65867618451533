import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import axios from "axios";
// import { ApiConfig } from "../../../config/ApiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ApiConfig } from "../../../config/ApiConfig";
const boxShadow = {
  fill: "#FFF",
  strokeWidth: "1px",
  stroke: "rgba(82, 49, 104, 0.25)",
  padding: "15px",
  width: "100%",
  height: "100%",
  position: "relative",
  // boxShadow: "rgba(0, 0, 0, 0.07) 6px 1px 18px 5px",
};
const btn = {
  background: "linear-gradient(98deg, #2A73E0 -1.68%, #1D60B3 103.45%)",
  color: "#fff",
  padding: "10px 70px",
  borderRadius: "10px",
};
const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "8px",
  minWidth: "35vw",
  // "@media(max-width:1024px)": {
  //   width: "70vw !important",
  // },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    marginTop: "2px",
  },
  marginBottom: "14px",
});
const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .trim()
    .required(" Please enter old password.")
    .min(6, "Minimum 6 digit required."),
  // .max(40, "Maximum 40 character allowed."),
  newPassword: Yup.string()
    .trim()
    .required("Password is required.")
    .min(6, "Minimum 6 digit required."),
  // .max(40, "Maximum 40 character allowed."),
  cPassword: Yup.string()
    .trim()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm passwords must match new password."
    )
    .required("Confirm password is required."),
});

const formInitialSchema = {
  oldPassword: "",
  newPassword: "",
  cPassword: "",
};

const EditPassword = () => {
  // const { state } = useLocation();
  // const { data } = state;
  const [ispasswordHidden, setIspasswordHidden] = useState(true);
  const [isNewpasswordHidden, setIsNewpasswordHidden] = useState(true);
  const [isConfmPasswordHidden, setIsConfmPasswordHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const changePasswordhandler = async (values) => {
    setIsLoading(true);
    const payLoadToSend = {
      currentPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    try {
      console.log(payLoadToSend);
      const mainRes = await axios({
        method: "POST",
        url: ApiConfig.changePassword,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        data: payLoadToSend,
      });
      let res = mainRes.data;
      console.log("data", res);
      if (res) {
        toast.success(res?.responseMessage || "Password changed successfully");
        navigate(-1, { replace: true });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.responseMessage || "Old password did not match"
      );
      setIsLoading(false);
    }
    console.log("values", values);
  };
  return (
    <Box style={boxShadow}>
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          mt: { xs: 6, md: 2, lg: 0 },
        }}
      >
        <Grid container item xs={12}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={2}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                color: "#2A73E0",
              }}
            >
              <ArrowCircleLeftIcon
                sx={{
                  fontSize: "30px",
                }}
              />
            </IconButton>
            <Typography variant="h5" fontWeight={"600"}>
              Change password
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item justifyContent={"center"} alignItems={"center"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          mt={8}
        >
          <Formik
            validationSchema={ChangePasswordSchema}
            initialValues={formInitialSchema}
            onSubmit={(values) => {
              changePasswordhandler(values);
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              values,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: { xs: "100%", md: "100%" },
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <label style={{ fontWeight: "500" }}>Old password</label>
                    <FormTextField
                      variant="outlined"
                      name="oldPassword"
                      placeholder="Enter Old Password"
                      value={values.oldPassword}
                      inputProps={{
                        maxLength: 40,
                        style: {
                          height: "20px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      type={ispasswordHidden ? "password" : "text"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.oldPassword && Boolean(errors.oldPassword)}
                      helperText={touched.oldPassword && errors.oldPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setIspasswordHidden((p) => !p)}
                            >
                              {ispasswordHidden ? (
                                <VisibilityOffIcon />
                              ) : (
                                <RemoveRedEyeIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onPaste={(e) => e.preventDefault()}
                    />
                  </FormControl>

                  <FormControl sx={{ width: "100%" }}>
                    <label style={{ fontWeight: "500" }}>New password</label>
                    <FormTextField
                      variant="outlined"
                      name="newPassword"
                      placeholder="Enter New Password"
                      value={values.newPassword}
                      inputProps={{
                        maxLength: 40,
                        style: {
                          height: "20px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      type={isNewpasswordHidden ? "password" : "text"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setIsNewpasswordHidden((p) => !p)}
                            >
                              {isNewpasswordHidden ? (
                                <VisibilityOffIcon />
                              ) : (
                                <RemoveRedEyeIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onPaste={(e) => e.preventDefault()}
                    />
                  </FormControl>

                  <FormControl sx={{ width: "100%" }}>
                    <label style={{ fontWeight: "500" }}>
                      Confirm password
                    </label>
                    <FormTextField
                      variant="outlined"
                      name="cPassword"
                      placeholder="Confirm Password"
                      value={values.cPassword}
                      inputProps={{
                        maxLength: 40,
                        style: {
                          height: "20px",
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      type={isConfmPasswordHidden ? "password" : "text"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.cPassword && Boolean(errors.cPassword)}
                      helperText={touched.cPassword && errors.cPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setIsConfmPasswordHidden((p) => !p)
                              }
                            >
                              {isConfmPasswordHidden ? (
                                <VisibilityOffIcon />
                              ) : (
                                <RemoveRedEyeIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onPaste={(e) => e.preventDefault()}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "10px",
                  }}
                  mt={8}
                >
                  <LoadingButton
                    type="submit"
                    sx={btn}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Grid>
    </Box>
  );
};

export default EditPassword;
