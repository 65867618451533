// import { getApiHandlerWithToken } from "../../config/service";

export const navBarSlice = (set, get, api) => ({
  allowedHeaderPermission: [],
  restrictedPath: [],
  setHeaderPermissionData: (permissionArray) => {
    set((state) => ({ allowedHeaderPermission: permissionArray }));
  },
  setRestrictedPath: (restrictedPathArray) => {
    set((state) => ({ restrictedPath: restrictedPathArray }));
  },
});
