import React, { useRef, useState } from "react";
import { Formik, Form } from "formik";
import { Typography, Grid, Button, IconButton, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { addQuestionnaireCategorySchema } from "../../../schema/index";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { putAPIHandler } from "../../../config/service";
import { transliterateToHindi } from "../../../config/service";

const EditQuestionnaireCategory = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  console.log(state, "state for the questionnaire");

  const onSubmit = async (values) => {
    console.log(values, "values");
    const payloadToSend = {
      categoryId: state?.value?.id,
      categoryName_en: values.categoryName,
      categoryName_hi: values.categoryNameHindi,
    };
    try {
      const response = await putAPIHandler({
        endPoint: "updateQuestionnaireCategory",
        dataToSend: payloadToSend,
      });
      console.log(response, "response");

      if (response && response.data.responseCode === 200) {
        navigate("/questionnaire");
      } else {
        console.error("error.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          Edit Category
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Formik
          initialValues={{
            categoryName: state?.value?.categoryName_en || "",
            categoryNameHindi: state?.value?.categoryName_hi || "",
          }}
          validationSchema={addQuestionnaireCategorySchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form
            style={{
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              // sm={8}
              md={10}
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                p: "10px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                <Grid container item sm={12} lg={2}>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Category Name (श्रेणी नाम):
                  </label>
                </Grid>
                <Grid container item sm={12} lg={4}>
                  <TextField
                  fullWidth
                    type="text"
                    id="categoryName"
                    name="categoryName"
                    placeholder="Enter category name"
                    inputProps={{
                      maxLength: 80,
                      
                    }}

                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                       
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: "0px",
                      },
                    }}
                    value={values.categoryName}
                    disabled={isLoading}
                    onChange={(e) => {
                      const englishText = e.target.value;
                      handleChange(e);
                      transliterateToHindi(englishText).then((hindiText) => {
                        setFieldValue("categoryNameHindi", hindiText);
                      });
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.categoryName && Boolean(errors.categoryName)
                    }
                    helperText={touched.categoryName && errors.categoryName}
                  />
                </Grid>
                <Grid container item sm={12} lg={4}>
                  <TextField
                  fullWidth
                    type="text"
                    id="categoryNameHindi"
                    name="categoryNameHindi"
                    placeholder="श्रेणी नाम दर्ज करें"
                    value={values.categoryNameHindi}
                    inputProps={{
                      maxLength: 80,
                   
                    }}

                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                       
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: "0px",
                      },
                    }}
                    disabled={isLoading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.categoryNameHindi &&
                      Boolean(errors.categoryNameHindi)
                    }
                    helperText={
                      touched.categoryNameHindi && errors.categoryNameHindi
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                mt={6}
                mb={8}
                sx={{ gap: { xs: 4, md: 10 } }}
              >
                <Grid item container xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "gray" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                    disabled={isLoading}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item container xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "#F83629" }}
                    type="submit"
                    loading={isLoading}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default EditQuestionnaireCategory;
