export const CareGiverData = [
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
  {
    Caregiver_ID: "H2S1234",
    Caregiver_Name: "Harsh Singh",
    Assigned_Asha_Worker_Name: "Jaanvi Singh",
    Date_Time: "13th March 2022, 15:09",
    State: "Haryana",
    District: "Faridabad",
    Tehsil: "Faridabad",
    City: "Faridabad",
    Ward: "A",
  },
];

export const categoryData = {
  categoryName: "Medication",
  categoryNameHindi: "दवा",
  thumbnailImage: "https://example.com/path/to/image.jpg",
};

export const statesData = [
  {
    state: "Rajasthan",
    stateHindi: "राजस्थान",
    cities: [
      {
        city: "Ajmer",
        cityHindi: "अजमेर",
        districts: [
          {
            district: "Ajmer",
            districtHindi: "अजमेर",
            tehsils: [
              { tehsil: "Ajmer", tehsilHindi: "अजमेर" },
              { tehsil: "Nasirabad", tehsilHindi: "नसीराबाद" },
              { tehsil: "Kishangarh", tehsilHindi: "किशनगढ़" },
              { tehsil: "Beawar", tehsilHindi: "ब्यावर" },
              { tehsil: "Masuda", tehsilHindi: "मसूदा" },
              { tehsil: "Sarwar", tehsilHindi: "सरवर" },
            ],
          },
          {
            district: "Bhilwara",
            districtHindi: "भीलवाड़ा",
            tehsils: [
              { tehsil: "Bhilwara", tehsilHindi: "भीलवाड़ा" },
              { tehsil: "Mandal", tehsilHindi: "मंडल" },
              { tehsil: "Shahpura", tehsilHindi: "शाहपुरा" },
              { tehsil: "Suwana", tehsilHindi: "सुवाणा" },
              { tehsil: "Hurda", tehsilHindi: "हुरड़ा" },
              { tehsil: "Asind", tehsilHindi: "आसिंड" },
            ],
          },
          {
            district: "Nagaur",
            districtHindi: "नागौर",
            tehsils: [
              { tehsil: "Nagaur", tehsilHindi: "नागौर" },
              { tehsil: "Parbatsar", tehsilHindi: "पारबतसर" },
              { tehsil: "Deedwana", tehsilHindi: "दीदवाना" },
              { tehsil: "Merta", tehsilHindi: "मेरता" },
              { tehsil: "Kuchaman City", tehsilHindi: "कुचामन सिटी" },
              { tehsil: "Jayal", tehsilHindi: "जयाल" },
            ],
          },
          {
            district: "Tonk",
            districtHindi: "टोंक",
            tehsils: [
              { tehsil: "Tonk", tehsilHindi: "टोंक" },
              { tehsil: "Malpura", tehsilHindi: "मालपुरा" },
              { tehsil: "Niwai", tehsilHindi: "निवाई" },
              { tehsil: "Peeplu", tehsilHindi: "पीपलू" },
              { tehsil: "Todaraisingh", tehsilHindi: "टोड़ा राईसिंह" },
              { tehsil: "Uniara", tehsilHindi: "उनियारा" },
            ],
          },
        ],
      },
      {
        city: "Bharatpur",
        cityHindi: "भरतपुर",
        districts: [
          {
            district: "Bharatpur",
            districtHindi: "भरतपुर",
            tehsils: [
              { tehsil: "Bharatpur", tehsilHindi: "भरतपुर" },
              { tehsil: "Bayana", tehsilHindi: "बयाना" },
              { tehsil: "Weir", tehsilHindi: "वीर" },
              { tehsil: "Nadbai", tehsilHindi: "नदबई" },
              { tehsil: "Kumher", tehsilHindi: "कुम्हेर" },
              { tehsil: "Rupbas", tehsilHindi: "रुपबस" },
            ],
          },
          {
            district: "Dholpur",
            districtHindi: "धोलपुर",
            tehsils: [
              { tehsil: "Dholpur", tehsilHindi: "धोलपुर" },
              { tehsil: "Bari", tehsilHindi: "बारी" },
              { tehsil: "Baseri", tehsilHindi: "बासरी" },
              { tehsil: "Rajakhera", tehsilHindi: "राजाखेड़ा" },
              { tehsil: "Saipau", tehsilHindi: "सैपांव" },
            ],
          },
          {
            district: "Karauli",
            districtHindi: "करौली",
            tehsils: [
              { tehsil: "Karauli", tehsilHindi: "करौली" },
              { tehsil: "Hindaun", tehsilHindi: "हिंदौन" },
              { tehsil: "Todabhim", tehsilHindi: "टोढ़ाभिम" },
              { tehsil: "Sapotra", tehsilHindi: "सापोत्रा" },
              { tehsil: "Mandrayal", tehsilHindi: "मंडरायल" },
            ],
          },
          {
            district: "Sawai Madhopur",
            districtHindi: "सवाई माधोपुर",
            tehsils: [
              { tehsil: "Sawai Madhopur", tehsilHindi: "सवाई माधोपुर" },
              { tehsil: "Gangapur City", tehsilHindi: "गंगापुर सिटी" },
              { tehsil: "Bonli", tehsilHindi: "बोंली" },
              { tehsil: "Bamanwas", tehsilHindi: "बामनवस" },
              { tehsil: "Chauth Ka Barwara", tehsilHindi: "चौथ का बरवाड़ा" },
            ],
          },
        ],
      },
      {
        city: "Bikaner",
        cityHindi: "बीकानेर",
        districts: [
          {
            district: "Bikaner",
            districtHindi: "बीकानेर",
            tehsils: [
              { tehsil: "Bikaner", tehsilHindi: "बीकानेर" },
              { tehsil: "Nokha", tehsilHindi: "नोक़ा" },
              { tehsil: "Kolayat", tehsilHindi: "कोलायत" },
              { tehsil: "Lunkaransar", tehsilHindi: "लूणकरणसर" },
              { tehsil: "Pugal", tehsilHindi: "पुगल" },
            ],
          },
          {
            district: "Churu",
            districtHindi: "चुरू",
            tehsils: [
              { tehsil: "Churu", tehsilHindi: "चुरू" },
              { tehsil: "Ratangarh", tehsilHindi: "रतनगढ़" },
              { tehsil: "Taranagar", tehsilHindi: "तारानगर" },
              { tehsil: "Rajgarh", tehsilHindi: "राजगढ़" },
              { tehsil: "Sardarshahar", tehsilHindi: "सरदारशाहर" },
            ],
          },
          {
            district: "Ganganagar",
            districtHindi: "गंगानगर",
            tehsils: [
              { tehsil: "Sri Ganganagar", tehsilHindi: "सिरी गंगानगर" },
              { tehsil: "Raisinghnagar", tehsilHindi: "रायसिंह नगर" },
              { tehsil: "Sadulshahar", tehsilHindi: "सदुलशाहर" },
              { tehsil: "Padampur", tehsilHindi: "पदामपुर" },
              { tehsil: "Suratgarh", tehsilHindi: "सूरतगढ़" },
            ],
          },
          {
            district: "Hanumangarh",
            districtHindi: "हनुमानगढ़",
            tehsils: [
              { tehsil: "Hanumangarh", tehsilHindi: "हनुमानगढ़" },
              { tehsil: "Pilibanga", tehsilHindi: "पिलिबांगा" },
              { tehsil: "Sangaria", tehsilHindi: "संगरिया" },
              { tehsil: "Tibi", tehsilHindi: "टीबी" },
              { tehsil: "Nohar", tehsilHindi: "नाहर" },
              { tehsil: "Bhadra", tehsilHindi: "भद्र" },
            ],
          },
        ],
      },
    ],
  },
];
