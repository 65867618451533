import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { PieChart, Pie, Cell, Legend } from "recharts";
import PersonIcon from "@mui/icons-material/Person";
import DomainIcon from "@mui/icons-material/Domain";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BadgeIcon from "@mui/icons-material/Badge";
import { useNavigate } from "react-router-dom";
import { ApiConfig } from "../../config/ApiConfig";
import axios from "axios";
import BarGraph from "../../component/graph/Bargraph";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
const renderLabel = ({ value }) => `${value}`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("");
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    console.log("Selected Month Numeric Value:", event.target.value); // Add this to debug
  };
  const getYearRange = (startYear) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };

  const months = [
    { name: "All", value: null },
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  const years = getYearRange(2020);

  const getDashboardData = async () => {
    setIsDataLoading(true);

    try {
      // Set params to only include year and month if selected
      const params = {
        year: selectedYear,
        ...(selectedMonth && { month: selectedMonth }), // Only include month if it's selected
      };

      const res = await axios({
        method: "GET",
        url: ApiConfig.getDashboard,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: params,
      });

      if (res?.data?.responseCode === 200) {
        setDashboard(res?.data?.result);
      } else {
        console.log("Unexpected response code:", res?.data?.responseCode);
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 404) {
        console.log("API not found (404)");
      }
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [selectedYear, selectedMonth]);
  useEffect(() => {
    getDashboardData();
  }, [selectedYear, selectedMonth]);

  const DashBoardContainerList = [
    {
      icon: <PersonIcon fontSize="large" sx={{ color: "#495bff" }} />,
      number: dashboard?.totalUsers || "",
      subTitle: "Total Users",
      color: "#3C40C6",
    },
    {
      icon: <Diversity3Icon fontSize="large" sx={{ color: "#9F0027" }} />,
      number: dashboard?.totalAshaWorkers || "",
      subTitle: "Total Asha Workers",
      color: "#FF5A82",
      navigateTo: "/asha-worker-management",
    },
    {
      icon: <EscalatorWarningIcon fontSize="large" sx={{ color: "#006A82" }} />,
      number: dashboard?.totalCaregivers || "",
      subTitle: "Total Caregivers",
      color: "#0CCBF5",
      navigateTo: "/caregiver-management",
    },
    {
      icon: (
        <PsychologyAltIcon
          fontSize="large"
          sx={{ color: "rgba(8, 5, 21, 0.60)" }}
        />
      ),
      number: dashboard?.totalQuestionnaires || "",
      subTitle: "Total Questionnaires",
      color: "linear-gradient(256deg, #523168 21.76%, #8C51B4 89.49%)",
      navigateTo: "/questionnaire",
    },
    {
      icon: <BadgeIcon fontSize="large" sx={{ color: "#7E2B00" }} />,
      number: dashboard?.totalInformativeContent || "",
      subTitle: "Total Informative Content",
      color: "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
      navigateTo: "/informative-content",
    },
    // {
    //   icon: <EngineeringRoundedIcon fontSize="large" sx={{ color: "#7E2B00" }} />,
    //   number: dashboard?.totalInformativeContent || "",
    //   subTitle: "Asha worker",
    //   color: "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
    //   navigateTo: "/",
    // },
  ];

  const languageData = [
    {
      name: "Hindi",
      value: dashboard?.languageAnalytics?.hindi || "",
      color: "#3C40C6",
    },
    {
      name: "English",
      value: dashboard?.languageAnalytics?.english || "",
      color: "#8C9EFF",
    },
  ];

  const completionData = [
    {
      name: "Completed",
      value: dashboard?.questionnaireCompletionRate?.completed,
      color: "#3C40C6",
    },
    {
      name: "Incomplete",
      value: dashboard?.questionnaireCompletionRate?.notCompleted,
      color: "#FF5A82",
    },
  ];

  const [hoveredIndexLanguage, setHoveredIndexLanguage] = useState(null);
  const [hoveredIndexCompletion, setHoveredIndexCompletion] = useState(null);
  const [isHoveredLanguage, setIsHoveredLanguage] = useState(false); // For language chart hover state
  const [isHoveredCompletion, setIsHoveredCompletion] = useState(false); // For completion chart hover state

  const handleMouseEnterLanguage = (index) => {
    setHoveredIndexLanguage(index); // Set hovered index for the language chart
  };

  const handleMouseLeaveLanguage = () => {
    setHoveredIndexLanguage(null); // Reset hovered index for language chart
  };

  const handleMouseEnterCompletion = (index) => {
    setHoveredIndexCompletion(index); // Set hovered index for the completion chart
  };

  const handleMouseLeaveCompletion = () => {
    setHoveredIndexCompletion(null); // Reset hovered index for completion chart
  };

  const handleChartMouseEnterLanguage = () => {
    setIsHoveredLanguage(true); // Set hover state for language chart
  };

  const handleChartMouseLeaveLanguage = () => {
    setIsHoveredLanguage(false); // Reset hover state for language chart
  };

  const handleChartMouseEnterCompletion = () => {
    setIsHoveredCompletion(true); // Set hover state for completion chart
  };

  const handleChartMouseLeaveCompletion = () => {
    setIsHoveredCompletion(false); // Reset hover state for completion chart
  };

  return (
    <>
      {
        <Grid container>
          <Typography color={"#262626"} fontWeight={"600"} variant="h5">
            Dashboard
          </Typography>

          <Grid
            container
            item
            xs={12}
            gap={1}
            mt={4}
            sx={{ display: "flex", justifyContent: "center" }}
            // justifyContent="space-around"
          >
            {DashBoardContainerList.map((e, i) => (
              <Tooltip title={`${e?.subTitle}: ${e?.number}`}>
                <Grid
                  container
                  item
                  md={2.8}
                  sm={4}
                  xs={12}
                  sx={{
                    background: `linear-gradient(180deg, #2a73e0, white)`, // Gradient background
                    borderRadius: "20px",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Lifted shadow effect
                    transition: "transform 0.4s ease, box-shadow 0.4s ease",
                    cursor: "pointer",
                    margin: "10px", // Ensure spacing between cards
                    overflow: "hidden", // Prevent overflow when scaling
                    position: "relative", // Set relative positioning for z-index stacking
                    zIndex: 1, // Ensure the card has a proper stacking order
                    "&:hover": {
                      transform: "scale(1.1) translateY(-10px)", // Lift on hover
                      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.4)", // Stronger shadow
                      zIndex: 10, // Bring the card to the front on hover
                    },
                  }}
                  onClick={() => {
                    if (e.navigateTo) {
                      navigate(e.navigateTo);
                    }
                  }}
                >
                  {/* Icon Section */}
                  <Grid
                    item
                    sx={{
                      "& .MuiSvgIcon-root": {
                        color: "#FFFFFF !important",
                      },
                    }}
                  >
                    {e.icon}
                  </Grid>

                  {/* Title Section */}
                  <Grid item>
                    <Typography
                      variant="h3"
                      color="#171515"
                      fontWeight="600"
                      fontSize="28px"
                      textAlign="center"
                    >
                      {e.number}
                    </Typography>
                  </Grid>

                  {/* Subtitle Section */}
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="#171515"
                      fontWeight="500"
                      fontSize="16px"
                      textAlign="center"
                    >
                      {e.subTitle}
                    </Typography>
                  </Grid>
                </Grid>
              </Tooltip>
            ))}
          </Grid>

          <Grid container spacing={3} mt={3} p={1.1}>
          
            <Grid item xs={12} md={6}>
              <Box
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                border="2px solid white"
                boxShadow="0px 0px 12.275px 0px rgba(0, 0, 0, 0.27)"
                borderRadius="20px"
                backgroundColor="#DDE2E4"
                onMouseEnter={handleChartMouseEnterLanguage}
                onMouseLeave={handleChartMouseLeaveLanguage}
                // style={{
                //   transition: "transform 0.3s, box-shadow 0.3s",
                //   transform: isHoveredLanguage ? "scale(1.05)" : "scale(1)", // Scaling effect on hover
                //   boxShadow: isHoveredLanguage
                //     ? "0px 0px 20px rgba(0, 0, 0, 0.2)"
                //     : "none",
                // }}
              >
                <Typography variant="h6" align="center">
                  Language and Localization Analytics
                </Typography>
                <PieChart width={280} height={280}>
                  <Pie
                    data={languageData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ value }) => `${value}`}
                    labelLine={false}
                    dataKey="value"
                  >
                    {languageData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.color} 
                        onMouseEnter={() => handleMouseEnterLanguage(index)}
                        onMouseLeave={handleMouseLeaveLanguage}
                      />
                    ))}
                  </Pie>
                  <Legend
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                  />
                </PieChart>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                border="2px solid white"
                boxShadow="0px 0px 12.275px 0px rgba(0, 0, 0, 0.27)"
                borderRadius="20px"
                backgroundColor="#DDE2E4"
                onMouseEnter={handleChartMouseEnterCompletion}
                onMouseLeave={handleChartMouseLeaveCompletion}
                // style={{
                //   transition: "transform 0.3s, box-shadow 0.3s",
                //   transform: isHoveredCompletion ? "scale(1.05)" : "scale(1)", // Scaling effect on hover
                //   boxShadow: isHoveredCompletion
                //     ? "0px 0px 20px rgba(0, 0, 0, 0.2)"
                //     : "none",
                // }}
              >
                <Typography variant="h6" align="center">
                  Questionnaire Completion Rate
                </Typography>
                <PieChart width={280} height={280}>
                  <Pie
                    data={completionData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                    labelLine={false}
                    dataKey="value"
                  >
                    {completionData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.color} // Change color on hover
                        onMouseEnter={() => handleMouseEnterCompletion(index)}
                        onMouseLeave={handleMouseLeaveCompletion}
                      />
                    ))}
                  </Pie>
                  <Legend
                    layout="horizontal"
                    align="center"
                    verticalAlign="bottom"
                  />
                </PieChart>
              </Box>
            </Grid>



            <Grid item xs={12} mt={4}>
              <Box
                p={2}
                display="flex"
                flexDirection="column" // Keep the BarGraph below the title and dropdowns
                border="2px solid white"
                boxShadow="0px 0px 12.275px 0px rgba(0, 0, 0, 0.27)"
                borderRadius="20px"
                backgroundColor="#DDE2E4"
              >
                {/* Row with Typography and Dropdowns */}
                <Box
                  display="flex"
                  flexDirection="row" // Align typography and dropdowns horizontally
                  justifyContent="space-between" // Push typography to the left and dropdowns to the right
                  alignItems="center" // Vertically center the content
                  width="100%" // Ensure full width for proper alignment
                  mb={2} // Add margin-bottom to create space between this section and the BarGraph
                >
                  {/* Typography on the left */}
                  <Typography variant="h6" align="start" gutterBottom>
                    Questionnaire Response Report
                  </Typography>

                  {/* Dropdown Section on the right */}
                  <Box display="flex" alignItems="center">
                    {/* Year Dropdown */}
                    <Box mr={2}>
                      <Select
                        variant="outlined"
                        displayEmpty
                        onChange={handleYearChange}
                        value={selectedYear}
                        sx={{
                          backgroundColor: "#DDE2E4",
                          borderRadius: "8px",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                          "&:hover": {
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiSelect-outlined": {
                            padding: "8px",
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Year
                        </MenuItem>
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    {/* Month Dropdown */}
                    <Box>
                      <Select
                        variant="outlined"
                        displayEmpty
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        sx={{
                          backgroundColor: "#DDE2E4",
                          borderRadius: "8px",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
                          "&:hover": {
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiSelect-outlined": {
                            padding: "8px",
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Month
                        </MenuItem>
                        {months.map((month) => (
                          <MenuItem key={month.value} value={month.value}>
                            {month.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                </Box>

                {/* BarGraph Section */}
                <BarGraph dataDashborad={dashboard} />
              </Box>
            </Grid>



          </Grid>
        </Grid>
      }
    </>
  );
};

export default Dashboard;
