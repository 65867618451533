import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { ApiConfig } from "../../../config/ApiConfig";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CircularProgress from "@mui/material/CircularProgress";

const Root = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(3),
  width: "100%",
  boxSizing: "border-box",
}));
const PrivacyPolicyContainer = styled(Box)(({ theme }) => ({
  "& .belowBox": {
    border: "1px solid #cccccc",
    padding: "15px",
    borderRadius: "10px",
    "& .MuiTypography-body2 p": {
      margin: 0,
    },
  },
  "& .heading": {
    marginTop: "20px",
  },
  "& .titlebox": {
    display: "flex",
  },
}));
const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.text.primary,
  fontSize: "2rem",
  fontWeight: 700,
}));

const ViewContent = () => {
  // const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [viewData, setViewData] = useState([]);
  console.log(location?.state?.type, "jljl");
  const [isLoading, setIsLoading] = useState(false);
  let type = location?.state?.type;

  const getStaticData = async () => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getOneStaticContent,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          contentType: type,
        },
      });
      if (res?.data?.responseCode === 200) {
        setViewData(res?.data?.result[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStaticData();
  }, []);
  return (
    <Root>
      <PrivacyPolicyContainer>
        <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                color: "#2A73E0",
              }}
            >
              <ArrowCircleLeftIcon
                sx={{
                  fontSize: "30px",
                }}
              />
            </IconButton>
          </Box>
          <Typography variant="h5">
            {type === "privacyPolicy" ? "Privacy Policy" : "Terms & Condition"}
          </Typography>
        </Box>

        <Box mt={2} mb={4}>
          <Typography variant="h5">For English</Typography>
        </Box>
        <Box className="belowBox">
          <Box className="belowBox">
            {!isLoading && viewData ? (
              <Typography
                variant="body2"
                style={{ width: "100%", fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: viewData?.description_en }}
              ></Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </PrivacyPolicyContainer>
      <PrivacyPolicyContainer>
        <Box mt={2} mb={4}>
          <Typography variant="h5">For Hindi</Typography>
        </Box>
        <Box className="belowBox">
          <Box className="belowBox">
            {!isLoading && viewData ? (
              <Typography
                variant="body2"
                style={{ width: "100%", fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: viewData?.description_hi }}
              ></Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </PrivacyPolicyContainer>
    </Root>
  );
};

export default ViewContent;
