import React from "react";
import { Grid, Typography, Box, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const ViewBroadcast = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const details = state?.value || {};
  console.log("details: ", details);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "N/A";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  // Extract English and Hindi details from state
  const broadcastDetails = [
    { label: "Title", value: details?.title_en || "N/A" },
    { label: "Message", value: details?.message_en || "N/A" },
  ];

  const broadcastDetailsInHindi = [
    { label: "शीर्षक", value: details?.title_hi || "-" },
    { label: "विवरण", value: details?.message_hi || "-" },
  ];
  const broadTitle = [
    {
      label: "User Type",
      value: capitalizeFirstLetter(
        details?.broadcastFor
          ? details.broadcastFor === "ASHA_WORKER"
            ? "Asha Worker"
            : details.broadcastFor === "CAREGIVER"
            ? "Caregiver"
            : details.broadcastFor === "ALL"
            ? "All"
            : "-"
          : "-"
      ),
    },
  ];

  const renderDetails = (title, details) => (
    <Grid
      container
      item
      xs={12}
      sx={{
        p: { lg: 4, sm: 0 },
        boxShadow: { lg: "rgba(0, 0, 0, 0.16) 0px 1px 4px", xs: "none" },
        mb: 3,
      }}
    >
      <Grid item container sm={12} lg={10} sx={{ mt: { lg: 0, xs: 6 } }}>
        <Box>
          <Typography variant="h5">{title}</Typography>
          {details.map((detail, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "baseline", mt: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: "bold", mr: 1 }}>
                {detail.label}:
              </Typography>
              <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                {detail.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
          <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          View Broadcast (प्रसारण देखें)
        </Typography>
      </Grid>
      {renderDetails("", broadTitle)}
      {/* Render Broadcast Details in English */}
      {renderDetails("Broadcast Details (English)", broadcastDetails)}

      {/* Render Broadcast Details in Hindi */}
      {renderDetails("Broadcast Details (Hindi)", broadcastDetailsInHindi)}
    </Grid>
  );
};

export default ViewBroadcast;
