import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import EditIcon from "@mui/icons-material/Edit";
import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ApiConfig } from "../../../config/ApiConfig";
import axios from "axios";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

const validationSchema = Yup.object({
  fName: Yup.string().trim()
    .required("First name is required.")
    .matches(/^\S*$/, "Spaces are not allowed."),
  lName: Yup.string().trim()
    .required("Last name is required"),
  
  email: Yup.string().email("Enter valid email.").required("Email is required"),
});

const EditProfile = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const { data } = state;

  const fullName = data?.fullName || "";
  const [firstName, ...rest] = fullName.split(" ");
  const lastName = rest.join(" ");

  const inputRef = useRef();
  const formikRef = useRef();

  const fileUploader = async (event) => {
    const file = event.currentTarget.files[0];
    if (!file) return;

    const fileSizeLimit = 5 * 1024 * 1024; // 5MB
    if (file.size > fileSizeLimit) {
      toast.error("File size should not exceed 5MB.");
      return;
    }
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      const res = await axios.post(ApiConfig.uploadFile, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        setImage(res.data.result);
      } else {
        toast.error(res.data.responseMessage || "Something went wrong");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async (values) => {
    let Id = sessionStorage.getItem("id");
    if (!image) {
      toast.success("Image is required.");
      return;
    }
    setIsLoading(true);

    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.editAccountDetails,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        data: {
          fullName_en: `${values?.fName} ${values?.lName}`,
          email_en: values?.email,
          profileUrl: image,
          userId: Id,
        },
      });

      if (res?.data?.responseCode === 200) {
        toast.success(
          res?.data?.responseMessage || "User updated successfully."
        );
        navigate(-1);
      } else {
        toast.error(res?.data?.responseMessage || "Something went wrong.");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.responseMessage ||
          "An error occurred. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setImage(data?.profileUrl);
  }, [data]);

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ mt: { xs: 6, md: 2, lg: 0 } }}
    >
      <Grid container item xs={12}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
        >
          <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
            <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <Typography variant="h5" fontWeight={"600"}>
            Edit {data?.fullName} profile
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={10}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          container
          item
          xs={12}
          md={4}
          mt={8}
          sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
          alignItems={"center"}
        >
          <Box sx={{ position: "relative", width: 180, height: 180 }}>
            {/* Avatar */}
            <Avatar
              src={image}
              sx={{
                height: 180,
                width: 180,
                opacity: isLoading ? 0.5 : 1,
                transition: "opacity 0.3s",
              }}
            />

            {/* Loader */}
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {/* Badge for Edit Icon */}
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{
                position: "absolute",
                bottom: 20,
                right: 30,
              }}
              badgeContent={
                <IconButton
                  style={{
                    background: "gray",
                  }}
                  disabled={isLoading}
                  onClick={() => {
                    if (inputRef?.current) {
                      inputRef?.current?.click();
                    }
                  }}
                >
                  <input
                    type="file"
                    name="photo"
                    id="photo"
                    ref={inputRef}
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      fileUploader(e);
                    }}
                  />
                  <EditIcon />
                </IconButton>
              }
            />
          </Box>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={8}
          justifyContent={"center"}
          alignItems={"center"}
          mt={8}
        >
          <Formik
            initialValues={{
              fName: data?.fullName ? firstName : "",
              lName: data?.fullName ? lastName : "",
              email: data?.email || "",
            }}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
            innerRef={formikRef}
          >
            {({ errors, touched, handleChange, handleBlur, values }) => (
              <Form style={{ width: "100%" }}>
                <Grid container item xs={12} gap={"22px"}>
                  <Grid item container xs={12} md={5}>
                    <Box sx={{ width: "100%" }}>
                      <label>First Name</label>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        style={{ marginTop: "8px" }}
                        variant="outlined"
                        name="fName"
                        placeholder="Enter first name"
                        value={values.fName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.fName && Boolean(errors.fName)}
                        helperText={touched.fName && errors.fName}
                      />
                    </Box>
                  </Grid>
                  <Grid item container xs={12} md={5}>
                    <Box sx={{ width: "100%" }}>
                      <label>Last Name</label>
                      <TextField
                        disabled={isLoading}
                        fullWidth
                        style={{ marginTop: "8px" }}
                        variant="outlined"
                        name="lName"
                        placeholder="Enter last name"
                        value={values.lName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lName && Boolean(errors.lName)}
                        helperText={touched.lName && errors.lName}
                      />
                    </Box>
                  </Grid>
                  <Grid item container xs={12}>
                    <Box sx={{ width: { xs: "100%", md: "87%" } }}>
                      <label>Email</label>
                      <TextField
                        disabled
                        fullWidth
                        style={{ marginTop: "8px" }}
                        variant="outlined"
                        name="email"
                        placeholder="Enter email"
                        value={values.email}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>

        <Grid container item xs={12} justifyContent={"center"} gap={8} mt={18}>
          <Button
            variant="contained"
            style={{ background: "#99AAAB", padding: "8px 60px" }}
            disabled={isLoading}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={{ background: "#2A73E0", padding: "8px 60px" }}
            disabled={isLoading}
            onClick={() => formikRef.current?.submitForm()}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditProfile;
