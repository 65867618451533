import { useState, useEffect } from "react";
import "./Header.css";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";
const drawerWidth = 340;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    "@media(max-width:868px)": {
      marginLeft: `-${drawerWidth}px`,
      height: "0px",
    },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `100%`,
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function Header(props) {
  let { setOpenLeft, openLeft } = props;
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:1200px)");
  const notmatches = useMediaQuery("(min-width:1200px)");
  const adminLeftDrawer = useMediaQuery("(min-width:862px)");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (adminLeftDrawer) {
      setOpenLeft(true);
    }
    if (matches) {
      setOpenLeft(false);
    }
  }, [adminLeftDrawer, matches]);

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ padding: 0 }}>
          <div className="headerContainer">
            <div style={{ display: "flex", gap: 10 }}>
              {matches && (
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setOpenLeft(!openLeft);
                  }}
                  edge="end"
                >
                  {openLeft && <CloseIcon style={{ color: "black" }} />}
                  {!openLeft && <MenuIcon style={{ color: "black" }} />}
                </IconButton>
              )}
              <img
                src="/images/Group 1261154272.svg"
                className="img"
                height={60}
                style={{
                  marginLeft: "28px",
                  marginBottom: "8px",
                  marginTop: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/dashboard");
                }}
                
              />
               {notmatches && (
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setOpenLeft(!openLeft);
                  }}
                  edge="end"
                >
                  {openLeft && matches && <CloseIcon style={{ color: "black" }} />}
                  {!notmatches?!openLeft:true  && <MenuIcon style={{ color: "black" }} />}
                </IconButton>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Main open={open}>
        <DrawerHeader />
      </Main>
    </>
  );
}

export default Header;
