import * as Yup from "yup";

// const firstNameRegex = /^[a-zA-Z]*$/;
// const lastNameRegex = /^[a-zA-Z\s]*$/;
// const phoneregex = /^[6789]\d{9}$/;
// const titleRegex = /^[^\d]*$/;
// const passwordRegex =
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&^(}){|\-_+="':;,.~`])[A-Za-z\d@$!#%*?&^(}){|\-_+="':;,.~`]{8,}$/;
// const emailRegex =
//   /^(?!\s)\w+([.-]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.){1,2}[a-zA-Z]{2,3}))$/;

const firstNameRegex = /^[a-zA-Z\u0900-\u097F0-9]*$/;

const lastNameRegex = /^[a-zA-Z\u0900-\u097F0-9\s]*$/;

const phoneregex = /^[6789\u0966-\u096F]\d{9}|\d{9}[\u0966-\u096F]{9}$/;

// const titleRegex = /^[^\d\u0030-\u0039\u0966-\u096F]*$/;

// const passwordRegex =
//   /^(?=.*[a-zA-Z\u0900-\u097F])(?=.*\d)(?=.*[#@$!%*?&^(}){|\-_+="':;,.~`])[A-Za-z\d@$!#%*?&^(}){|\-_+="':;,.~`]{8,}$/;

const emailRegex =
  /^(?!\s)\w+([.-]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\u0900-\u097F\-0-9]+\.){1,2}[a-zA-Z]{2,3}))$/;

export const caregiverSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .trim()
    .min(2, "First name cannot be less than 2 characters.")
    .max(20, "First name cannot be more than 20 characters.")
    .matches(firstNameRegex, "Please enter valid first name."),

  firstNameHindi: Yup.string()
    .required("कृपया अपना पहला नाम दर्ज करें।")
    .trim()
    .min(2, "पहले नाम में कम से कम 2 वर्ण होने चाहिए।")
    .max(20, "पहले नाम में 20 से अधिक वर्ण नहीं हो सकते।"),
  // lastName: Yup.string()
  //   .required("Please enter your last name.")
  //   .trim()
  //   .min(2, "Last name cannot be less than 2 characters.")
  //   .max(20, "Last name cannot be more than 20 characters.")
  //   .matches(lastNameRegex, "Please enter valid last name."),

  // lastNameHindi: Yup.string()
  //   .required("कृपया अपना अंतिम नाम दर्ज करें।")
  //   .trim()
  //   .min(2, "अंतिम नाम में कम से कम 2 वर्ण होने चाहिए।")
  //   .max(20, "अंतिम नाम में 20 से अधिक वर्ण नहीं हो सकते।"),
  // email: Yup.string()
  //   .optional("Please enter the email.")
  //   .trim()
  //   .matches(emailRegex, "Please enter valid email id.")
  //   .max(64, "Email cannot be more than 64 characters.")
  //   .email("Invalid email address."),

  // emailHindi: Yup.string()
  //   .optional("कृपया ईमेल दर्ज करें।")
  //   .trim()
  //   .matches(emailRegex, "कृपया मान्य ईमेल दर्ज करें।")
  //   .max(64, "ईमेल 64 वर्णों से अधिक नहीं हो सकता।")
  //   .email("अमान्य ईमेल पता।"),

  phoneNumber: Yup.string()
    .required("Please enter the phone number.")
    .trim()
    .matches(phoneregex, "Please enter valid phone number.")
    .max(10, "Phone number cannot be more than 10 characters."),

  phoneNumberHindi: Yup.string()
    .required("कृपया फोन नंबर दर्ज करें।")
    .trim()
    .matches(phoneregex, "कृपया मान्य फोन नंबर दर्ज करें।")
    .max(10, "फोन नंबर 10 अंकों से अधिक नहीं हो सकता।"),

  state: Yup.string().required("Please select a state."),
  stateHindi: Yup.string().required("कृपया राज्य का चयन करें।"),

  // ward: Yup.string().required("Please enter area."),

  // wardHindi: Yup.string().required("कृपया क्षेत्र दर्ज करें।"),

  // address: Yup.string().required("Please enter address."),

  // addressHindi: Yup.string().required("कृपया पता दर्ज करें।"),

  // thumbnailImage: Yup.mixed()
  //   .required("Please upload a thumbnail image.")
  //   // .test(
  //   //   "fileSize",
  //   //   "The file is too large. Please upload a file smaller than 2MB.",
  //   //   (value) => value && value.size <= 2 * 1024 * 1024
  //   // )
  //   // .test(
  //   //   "fileType",
  //   //   "Unsupported file type. Only JPG and PNG images are allowed.",
  //   //   (value) => value && ["image/jpeg", "image/png"].includes(value.type)
  //   // )
  //   ,
});

export const addCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter your category name.")
    .trim()
    .max(100, "Category name cannot exceed 100 characters."),

  categoryNameHindi: Yup.string()
    .required("कृपया अपनी श्रेणी का नाम हिंदी में दर्ज करें।")
    .trim()
    .max(100, "हिंदी में श्रेणी का नाम 100 अक्षरों से अधिक नहीं हो सकता।"),

  // thumbnailImage: Yup.mixed()
  //   .required("Please upload a menu image.")
  //   // .test(
  //   //   "fileSize",
  //   //   "The file is too large. Please upload a file smaller than 2MB.",
  //   //   (value) => value && value.size <= 2 * 1024 * 1024
  //   // )
  //   // .test(
  //   //   "fileType",
  //   //   "Unsupported file type. Only JPG and PNG images are allowed.",
  //   //   (value) => value && ["image/jpeg", "image/png"].includes(value.type)
  //   // )
  //   ,
});

export const addQuestionnaireCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter your category name.")
    .trim()
    .max(100, "Category name cannot exceed 100 characters."),

  categoryNameHindi: Yup.string()
    .required("कृपया अपनी श्रेणी का नाम हिंदी में दर्ज करें।")
    .trim()
    // .max(100, "हिंदी में श्रेणी का नाम 100 अक्षरों से अधिक नहीं हो सकता।"),
});

export const addArticleSchema = Yup.object().shape({
  articleType: Yup.string().required("Please select the article type."),
  articleTypeHindi: Yup.string().required("कृपया लेख का प्रकार चुनें।"),

  articleName: Yup.string()
    .required("Please enter the article name.")
    .trim()
    .max(100, "Article name cannot exceed 100 characters."),
  articleNameHindi: Yup.string()
    .required("कृपया लेख का नाम हिंदी में दर्ज करें।")
    .trim()
    .max(100, "हिंदी में लेख का नाम 100 अक्षरों से अधिक नहीं हो सकता।"),

  articleImage: Yup.mixed().when("articleType", {
    is: "IMAGE",
    then: (schema) => schema.required("Please upload an image."),
    // .test(
    //   "fileSize",
    //   "File is too large. Please upload a file smaller than 5MB.",
    //   (value) => value && value.size <= 5 * 1024 * 1024
    // )
    // .test(
    //   "fileType",
    //   "Invalid file type. Only JPG and PNG images are allowed.",
    //   (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    // )
    otherwise: (schema) => schema.notRequired(),
  }),

  articleImageHindi: Yup.mixed().when("articleType", {
    is: "IMAGE",
    then: (schema) => schema.required("कृपया एक हिंदी छवि अपलोड करें।"),
    // .test(
    //   "fileSize",
    //   "फ़ाइल बहुत बड़ी है। कृपया 5MB से छोटी फ़ाइल अपलोड करें।",
    //   (value) => value && value.size <= 5 * 1024 * 1024
    // )
    // .test(
    //   "fileType",
    //   "अमान्य फ़ाइल प्रकार। केवल JPG और PNG छवियाँ मान्य हैं।",
    //   (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    // )
    otherwise: (schema) => schema.notRequired(),
  }),

  articleVideo: Yup.mixed().when("articleType", {
    is: "VIDEO",
    then: (schema) => schema.required("Please upload a video."),
    // .test(
    //   "fileSize",
    //   "File is too large. Please upload a file smaller than 10MB.",
    //   (value) => value && value.size <= 10 * 1024 * 1024
    // )
    // .test(
    //   "fileType",
    //   "Invalid file type. Only MP4 videos are allowed.",
    //   (value) => value && value.type === "video/mp4"
    // )
    otherwise: (schema) => schema.notRequired(),
  }),

  articleVideoHindi: Yup.mixed().when("articleType", {
    is: "VIDEO",
    then: (schema) => schema.required("कृपया एक हिंदी वीडियो अपलोड करें।"),
    // .test(
    //   "fileSize",
    //   "फ़ाइल बहुत बड़ी है। कृपया 10MB से छोटी फ़ाइल अपलोड करें।",
    //   (value) => value && value.size <= 10 * 1024 * 1024
    // )
    // .test(
    //   "fileType",
    //   "अमान्य फ़ाइल प्रकार। केवल MP4 वीडियो मान्य हैं।",
    //   (value) => value && value.type === "video/mp4"
    // )
    otherwise: (schema) => schema.notRequired(),
  }),

  thumbnailImage: Yup.mixed().when("articleType", {
    is: "VIDEO",
    then: (schema) => schema.required("Please upload a menu image."),
    // .test(
    //   "fileSize",
    //   "The file is too large. Please upload a file smaller than 2MB.",
    //   (value) => value && value.size <= 2 * 1024 * 1024
    // )
    // .test(
    //   "fileType",
    //   "Unsupported file type. Only JPG and PNG images are allowed.",
    //   (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    // )
    otherwise: (schema) => schema.notRequired(),
  }),

  thumbnailImageHindi: Yup.mixed().when("articleType", {
    is: "VIDEO",
    then: (schema) => schema.required("कृपया एक थंबनेल छवि अपलोड करें।"),
    // .test(
    //   "fileSize",
    //   "फ़ाइल बहुत बड़ी है। कृपया 2MB से छोटी फ़ाइल अपलोड करें।",
    //   (value) => value && value.size <= 2 * 1024 * 1024
    // )
    // .test(
    //   "fileType",
    //   "अमान्य फ़ाइल प्रकार। केवल JPG और PNG छवियाँ मान्य हैं।",
    //   (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    // )
    otherwise: (schema) => schema.notRequired(),
  }),

  description: Yup.string()
    .required("Please enter the description.")
    .trim()
    .max(1200, "Description cannot exceed 1200 characters."),
  // descriptionHindi: Yup.string()
  //   .required("कृपया हिंदी में विवरण दर्ज करें।")
  //   .trim()
  //   .max(1200, "हिंदी में विवरण 1200 अक्षरों से अधिक नहीं हो सकता।"),

  uploadPdf: Yup.mixed().required("Please upload a PDF file."),
  // .test(
  //   "fileSize",
  //   "File is too large. Please upload a file smaller than 10MB.",
  //   (value) => value && value.size <= 10 * 1024 * 1024
  // )
  // .test(
  //   "fileType",
  //   "Invalid file type. Only PDF files are allowed.",
  //   (value) => value && value.type === "application/pdf"
  // )
  uploadPdfHindi: Yup.mixed().required("कृपया एक हिंदी PDF फ़ाइल अपलोड करें।"),
  // .test(
  //   "fileSize",
  //   "फ़ाइल बहुत बड़ी है। कृपया 10MB से छोटी फ़ाइल अपलोड करें।",
  //   (value) => value && value.size <= 10 * 1024 * 1024
  // )
  // .test(
  //   "fileType",
  //   "अमान्य फ़ाइल प्रकार। केवल PDF फ़ाइलें मान्य हैं।",
  //   (value) => value && value.type === "application/pdf"
  // )
});

export const addQuestionnaireSchema = Yup.object().shape({
  questionnaireTitle: Yup.string()
    .required("Please enter your questionnaire title.")
    .trim()
    .max(100, "Questionnaire title cannot exceed 100 characters."),

  questionnaireTitleHindi: Yup.string()
    .required("कृपया अपनी प्रश्नावली का शीर्षक हिंदी में दर्ज करें।")
    .trim()
    .max(
      100,
      "हिंदी में प्रश्नावली का शीर्षक 100 अक्षरों से अधिक नहीं हो सकता।"
    ),
});

export const addBroadcastSchema = Yup.object().shape({
  broadcastFor: Yup.string().required("Please select the user for broadcast."),
  broadcastTitle: Yup.string()
    .required("Please enter your broadcast title.")
    .trim()
    .max(100, "Broadcast title cannot exceed 100 characters."),

  broadcastTitleHindi: Yup.string()
    .required("कृपया अपनी प्रसारण का शीर्षक हिंदी में दर्ज करें।")
    .trim()
    .max(100, "हिंदी में प्रसारण का शीर्षक 100 अक्षरों से अधिक नहीं हो सकता।"),

  broadcastMessage: Yup.string()
    .required("Please enter your broadcast message.")
    .trim()
    .max(1200, "Broadcast message cannot exceed 1200 characters."),

  broadcastMessageHindi: Yup.string()
    .required("कृपया अपनी प्रसारण का संदेश हिंदी में दर्ज करें।")
    .trim()
   
});
