import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { ApiConfig } from "../../../config/ApiConfig";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
}));

const PrivacyPolicyContainer = styled(Box)(({ theme }) => ({
  "& .belowBox": {
    border: "1px solid #cccccc",
    padding: "15px",
    borderRadius: "10px",
    height: "auto",
  },
  "& .heading": {
    marginTop: "20px",
  },
  "& .titlebox": {
    display: "flex",
  },
}));

const ViewContact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [viewData, setViewData] = useState(""); // Initialize as an empty string
  console.log("viewData: ", viewData);
  const [isLoading, setIsLoading] = useState(false);

  const getStaticData = async () => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getOneStaticContent,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          contentType: "supportContact",
        },
      });
      if (res?.data?.responseCode === 200) {
        setViewData(res?.data?.result[0]?.description_en || ""); // Ensure result is a string
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <Root>
      <PrivacyPolicyContainer>
        <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                color: "#2A73E0",
              }}
            >
              <ArrowCircleLeftIcon
                sx={{
                  fontSize: "30px",
                }}
              />
            </IconButton>
          </Box>
          <Typography variant="h5">Support Contact</Typography>
        </Box>
        <Box
          className="belowBox"
          mb={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box marginBottom={2} mt={2}>
            <Typography variant="body1" color="textSecondary">
              Phone Number
            </Typography>
            <PhoneInput
              countryCodeEditable={false}
              placeholder="Enter phone number"
              value={viewData || ""} // Ensure it's always a string
              disabled
              // onChange={(value) => {
              //   setViewData(value); // Update viewData if the phone number is edited
              // }}
              inputStyle={{
                // width: "100%",
                height: "45px",
                marginTop: "5px",
                fontWeight: "normal",
                border: "1px solid #e7e7e7",
              }}
            />
          </Box>
        </Box>
      </PrivacyPolicyContainer>
    </Root>
  );
};

export default ViewContact;
