import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

const Filter = ({ setFilter, statusFilter, filterList }) => {
  const { pathname } = useLocation();
  const [filterLocal, setFilterLocal] = useState({
    page: 1,
    limit: 10,
    search: "",
    fromDate: "",
    toDate: "",
    status: "All",
  });

  const placeholderMap = {
    "/caregiver-management": "Search by caregiver name",
    "/asha-worker-management/view-asha-worker": "Search by caregiver name",
    "/asha-worker-management": "Search by asha-worker name",
    "/informative-content": "Search by category name",
    "/informative-content/view-category": "Search by article name",
    "/questionnaire": "Search by category name",
    "/questionnaire/view-category": "Search by questionnaire name",
    "/caregiver-management/view-caregiver": "Search by questionnaire name",
    "/broadcast": "Search by broadcast title",
  };

  const isQuestionnaireDetail = pathname.startsWith(
    "/questionnaire/view-questionnaire/"
  );

  const placeholderText = isQuestionnaireDetail
    ? "Search by question"
    : placeholderMap[pathname] || "Search";

  useEffect(() => {
    filterhandler();
  }, [filterLocal]);

  async function filterhandler() {
    if (filterLocal.fromDate && !filterLocal.toDate) {
      toast.error("Both date are required.");
    } else if (!filterLocal.fromDate && filterLocal.toDate) {
      toast.error("Both date are required.");
    } else if (
      filterLocal.search &&
      !filterLocal.fromDate &&
      !filterLocal.toDate
    ) {
      const status = filterLocal.status == "All" ? "" : filterLocal.status;
      setFilter({ ...filterLocal, status: status });
    } else {
      const status = filterLocal.status == "All" ? "" : filterLocal.status;
      setFilter({ ...filterLocal, status: status });
      // setFilter(filterLocal);
    }
  }
  function clearFilter() {
    if (
      !(
        filterLocal.page == 1 &&
        filterLocal.limit == 10 &&
        filterLocal.search == "" &&
        filterLocal.fromDate == "" &&
        filterLocal.toDate == "" &&
        filterLocal.status == ""
      )
    ) {
      setFilterLocal({
        page: 1,
        limit: 10,
        search: "",
        fromDate: "",
        toDate: "",
        status: "",
      });
      setFilter({
        page: 1,
        limit: 10,
        search: "",
        fromDate: "",
        toDate: "",
        status: "",
      });
    }
  }

  return (
    <Grid
      item
      container
      xs={12}
      mt={4}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Grid
        xl={3}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        item
        container
        alignItems={"center"}
        sx={{
          justifyContent: { xs: "center", md: "flex-end" },
          mt: { xs: 0, md: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            width: { xs: "100%" },
            alignItems: "center",
            justifyContent: { xs: "f  lex-start", md: "auto" },
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholderText}
            InputProps={{
              sx: {
                borderRadius: "12px",
                border: "0px solid black",
                input: {
                  padding: "10px 10px",
                },
              },
            }}
            value={filterLocal.search}
            onChange={(e) => {
              const filterToSet = {
                ...filterLocal,
                search: e?.target?.value,
              };
              setFilterLocal(filterToSet);
            }}
          />
        </Box>
      </Grid>

      <Grid
        xl={5}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        item
        container
        gap={2}
        sx={{
          justifyContent: { xs: "flex-start" },
          mt: { xs: 4, md: 0 },
        }}
      >
        <Grid item xs={statusFilter ? 3.5 : 5.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                width: "100%",
                ml: { sm: 0, md: 4 },
                borderRadius: 80,
              }}
              components={["DatePicker"]}
            >
              <DatePicker
                sx={{
                  width: { xs: "100%", md: "auto" },
                  borderRadius: 80,

                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },

                  "& .MuiInputLabel-root": {
                    top: "-5px",
                  },
                  input: {
                    padding: "10px 10px",
                  },
                }}
                value={
                  filterLocal.fromDate ? dayjs(filterLocal.fromDate) : null
                }
                label="From date"
                onChange={(e) => {
                  const filterToset = {
                    ...filterLocal,
                    fromDate: e?.format("YYYY-MM-DD"),
                  };
                  setFilterLocal(filterToset);
                }}
              />
            </Box>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={statusFilter ? 3.5 : 5.5}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                width: "100%",
                ml: { sm: 0, md: 4 },
                borderRadius: 80,
                height: "10px !important",
              }}
              components={["DatePicker"]}
            >
              <DatePicker
                sx={{
                  width: { xs: "100%", md: "auto" },
                  borderRadius: 80,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                  },
                  "& .MuiInputLabel-root": {
                    top: "-5px",
                  },
                  input: {
                    padding: "10px 10px",
                  },
                }}
                shouldDisableDate={(date) => {
                  return date.isBefore(filterLocal.fromDate);
                }}
                label="To date"
                value={filterLocal.toDate ? dayjs(filterLocal.toDate) : null}
                onChange={(e) => {
                  const filterToset = {
                    ...filterLocal,
                    toDate: e?.format("YYYY-MM-DD"),
                  };
                  setFilterLocal(filterToset);
                }}
              />
            </Box>
          </LocalizationProvider>
        </Grid>

        {statusFilter && (
          <Grid item xs={3}>
            <Box
              sx={{
                width: "100%",
                ml: { sm: 0, md: 4 },
                borderRadius: 80,
                height: "10px !important",
                position: "relative",
              }}
            >
              <Tooltip title="Select Status" placement="top">
                {filterLocal.status ? (
                  <></>
                ) : (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      position: "absolute",
                      top: "9px",
                      left: "12px",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "1rem",
                    }}
                  >
                    Status
                  </Typography>
                )}
                <Select
                  label="Select status"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "2px",
                      borderRadius: "100px",
                    },
                  }}
                  sx={{
                    "& .MuiSelect-select": {
                      padding: "10.5px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "12px",
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                  onChange={(e) => {
                    const filterToset = {
                      ...filterLocal,
                      status: e?.target?.value,
                    };
                    setFilterLocal(filterToset);
                  }}
                  value={filterLocal.status}
                >
                  <MenuItem value="All">{"All"}</MenuItem>
                  {filterList &&
                    filterList?.map((data, i) => {
                      return (
                        <MenuItem key={i} value={data}>
                          {data}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        item
        md={12}
        sm={12}
        xs={12}
        lg={4}
        xl={4}
        sx={{
          justifyContent: { xs: "center", xl: "flex-end" },
          mt: { xs: 4, lg: 0 },
        }}
        gap={2}
      >
        <Tooltip title="Clear filter">
          <Button
            disabled={
              filterLocal.search == "" &&
              filterLocal.fromDate == "" &&
              filterLocal.toDate == ""
                ? true
                : false
            }
            variant="contained"
            sx={{
              padding: { xs: "0px 100px", lg: "0px 70px", xl: "0px 100px" },
              height: 44,
              borderRadius: "20px",
              background: "#1D60B3",
              "&:hover": {
                background: "#154d82",
              },
              "&.Mui-disabled": {
                background: "#d3d3d3",
                color: "#000000",
              },
            }}
            onClick={() => {
              clearFilter();
            }}
          >
            Clear
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Filter;
