import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Avatar, Box, Typography, Paper, TextField } from "@mui/material";
import axios from "axios";
import { ApiConfig } from "../config/ApiConfig";
import dayjs from "dayjs";

const ChatScreen = ({
  ashaWorkerId,
  careGiverId,
  ashaWorkerProfileImage,
  careGiverProfileImage,
}) => {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const isAtBottom = useRef(true);
  const pollingInterval = useRef(null);

  // Fetch chat messages
  const getAllChat = async (page, isPolling = false) => {
    setIsLoading(true);
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getChatConversationHistory,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          caregiverId: careGiverId,
          ashaWorkerId: ashaWorkerId,
          limit: 10,
          page: page,
        },
      });
      if (res?.data?.responseCode === 200) {
        const newMessages = res?.data?.result?.messagesList || [];
        if (isPolling) {
          // Append only new messages during polling
          setMessages((prevMessages) => {
            const prevIds = new Set(prevMessages.map((msg) => msg.id)); // Assuming each message has a unique `id`
            const uniqueNewMessages = newMessages.filter(
              (msg) => !prevIds.has(msg.id)
            );
            return [...prevMessages, ...uniqueNewMessages];
          });
        } else {
          if (page === 1) {
            setMessages(newMessages);
          } else {
            setMessages((prevMessages) => [...newMessages, ...prevMessages]);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = (event) => {
    const container = event.target;
    if (container.scrollTop === 0 && !isLoading) {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        getAllChat(nextPage);
        return nextPage;
      });
    }

    isAtBottom.current =
      container.scrollHeight - container.scrollTop === container.clientHeight;
  };

  useLayoutEffect(() => {
    const container = chatContainerRef.current;
    if (container && isAtBottom.current) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    getAllChat(page);

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    // Start polling for new messages
    pollingInterval.current = setInterval(() => {
      getAllChat(1, true); // Poll the server for new messages (page 1 only)
    }, 5000); // 5-second interval

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
      clearInterval(pollingInterval.current); // Cleanup polling on unmount
    };
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = messages.filter((message) =>
        message.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages(messages);
    }
  }, [searchTerm, messages]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const renderMediaPreview = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const imageRegex = /\.(jpeg|jpg|gif|png|webp|svg)$/i;
    const videoRegex = /\.(mp4|webm|ogg)$/i;

    if (!urlRegex.test(content)) return null;

    if (imageRegex.test(content)) {
      return (
        <img
          src={content}
          alt="media"
          style={{
            maxWidth: "100%",
            maxHeight: 200,
            borderRadius: 10,
            objectFit: "cover",
            marginTop: 8,
          }}
        />
      );
    }

    if (videoRegex.test(content)) {
      return (
        <video
          controls
          style={{
            maxWidth: "100%",
            maxHeight: 200,
            borderRadius: 10,
            objectFit: "cover",
            marginTop: 8,
          }}
        >
          <source src={content} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return null;
  };

  return (
    <Box
      sx={{
        width: "35vw",
        height: "75vh",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #ccc",
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      {/* Chat Title with Names */}
      <Box
        sx={{
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#1976d2",
          color: "#fff",
          borderRadius: "8px 8px 0 0",
          height: 50,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          Chat Conversation History
        </Typography>
      </Box>

      {/* Search Bar */}
      <Box sx={{ padding: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder="Search messages..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>

      {/* Chat Messages Container */}
      <Box
        ref={chatContainerRef}
        sx={{
          flexGrow: 1,
          overflowY: "scroll",
          padding: 2,
          backgroundColor: "#f9f9f9",
          "::-webkit-scrollbar": {
            display: "none", // Hide the scrollbar
          },
        }}
      >
        {filteredMessages.length === 0 && !isLoading && (
          <Typography variant="body1" color="textSecondary" align="center">
            No chat history found
          </Typography>
        )}

        {filteredMessages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection:
                message.senderId === ashaWorkerId ? "row" : "row-reverse",
              marginBottom: 2,
              alignItems: "center",
            }}
          >
            <Avatar
              alt={message.senderId}
              src={
                message.senderId === ashaWorkerId
                  ? ashaWorkerProfileImage
                  : careGiverProfileImage
              }
              sx={{ margin: 1 }}
            />
            <Box>
              <Paper
                sx={{
                  padding: 1.5,
                  maxWidth: "80%",
                  backgroundColor:
                    message.senderId === careGiverId ? "#f0f0f0" : "#1976d2",
                  color: message.senderId === careGiverId ? "black" : "white",
                  borderRadius:
                    message.senderId === careGiverId
                      ? "20px 20px 5px 20px"
                      : "20px 20px 20px 5px",
                  boxShadow: 1,
                }}
              >
                {message.messageType === "MESSAGE" ? (
                  <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                    {message.content}
                  </Typography>
                ) : (
                  renderMediaPreview(message.content)
                )}
              </Paper>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  color: "textSecondary", 
                  marginTop: 1,
                }}
              >
                {dayjs(message.createdAt).format("MMM D YYYY h:mm A")}
              </Typography>
            </Box>
          </Box>
        ))}

        <div ref={messagesEndRef} />
      </Box>
    </Box>
  );
};

export default ChatScreen;
