import React from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const ViewArticle = () => {
  const { state } = useLocation();
  console.log("state: ", state);
  const navigate = useNavigate();
  const renderPdfLink = () => {
    if (state?.value?.pdfUrl_en) {
      return (
        <>
          {/* PDF Preview using iframe */}
          <Box sx={{ mb: 2 }}>
            <iframe
              src={state?.value?.pdfUrl_en}
              width="250px"
              height="150px"
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            />
          </Box>

          {/* Download link */}
          <a
            href={state?.value?.pdfUrl_en}
            target="_blank"
            rel="noopener noreferrer"
            download
            style={{
              color: "#1976d2",
              textDecoration: "none",
              fontWeight: "500",
              padding: "8px 16px",
              backgroundColor: "#f0f4f8",
              borderRadius: "4px",
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#e3f2fd")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#f0f4f8")}
          >
            Download PDF (डाउनलोड पीडीएफ)
          </a>
        </>
      );
    } else {
      return (
        <Typography variant="body2" color="textSecondary">
          No PDF available (कोई पीडीएफ उपलब्ध नहीं)
        </Typography>
      );
    }}
  const renderMediaPreview = () => {
    if (state?.value?.articleType_en?.toLowerCase() === "video") {
      return (
        <>
          <Typography variant="body1" fontWeight="500" mb={1}>
            Video Preview (वीडियो पूर्वावलोकन)
          </Typography>
          <Box sx={{ mb: 3 }}>
  <video
    width="100%"
    controls
    src={state?.value?.documentUrl_en}
    style={{
      borderRadius: "12px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      maxWidth: "100%",  // Ensures the video doesn't stretch beyond container width
      maxHeight: "300px",  // Set a max height to prevent large videos from overflowing
      objectFit: "contain",  // Ensures the video scales without distorting its aspect ratio
    }}
  >
    Your browser does not support the video tag.
  </video>
</Box>

        </>
      );
    } else {
      return (
        <>
          <Typography variant="body1" fontWeight="500" mb={1}>
            Image Preview (छवि पूर्वावलोकन)
          </Typography>
          <Box sx={{ mb: 3 }}>
            <img
              src={state?.value?.documentUrl_en}
              alt="Article Image"
              style={{
                width: "350px",
                height:"auto",
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Box>
        </>
      );
    }
  };

  const renderContent = (label, contentEn, contentHi) => (
    <Grid container item xs={12} sx={{ mb: 2 }}>
      <Typography variant="subtitle2" fontWeight="500" sx={{ width: "30%" }}>
        {label}
      </Typography>
      <Box sx={{ width: "70%" }}>
        <Typography variant="body2" sx={{ color: "#424242" }}>
          {contentEn}
        </Typography>
        {contentHi && (
          <Typography variant="body2" color="textSecondary">
            {contentHi}
          </Typography>
        )}
      </Box>
    </Grid>
  );

  return (
    <Grid container justifyContent="center" spacing={2} sx={{ py: 4 }}>
      {/* Header */}
      <Grid
        container
        item
        xs={12}
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
          <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
        </IconButton>
        <Typography variant="h5" fontWeight="600" color="#262626">
          Article Details
        </Typography>
      </Grid>

      {/* Main Content */}
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          p: 3,
          borderRadius: 2,
        }}
      >
        <Card variant="outlined" sx={{ p: 3, borderRadius: 2 }}>
          <CardContent>
            {/* Article Title */}
            {renderContent(
              "Article Title (शीर्षक)",
              state?.value?.articleTitle_en,
              state?.value?.articleTitle_hi
            )}

            {/* Article Type */}
            {renderContent(
              "Article Type (लेख प्रकार)",
              state?.value?.articleType_en,
              state?.value?.articleType_hi
            )}

            {/* Uploaded PDF */}
            <Grid container item xs={12} sx={{ mb: 2 }}>
              <Typography
                variant="subtitle2"
                fontWeight="500"
                sx={{ width: "30%" }}
              >
                Uploaded PDF (अपलोड किया गया पीडीएफ)
              </Typography>
              <Box sx={{ width: "70%" }}>{renderPdfLink()}</Box>
            </Grid>

            {/* Media Preview */}
            {renderMediaPreview()}

            {/* Description */}
            {renderContent(
              "Description (विवरण)",
              state?.value?.description_en,
              state?.value?.description_hi
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewArticle;
