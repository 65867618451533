import styled from "@emotion/styled";
import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postAPIHandlerWithoutToken } from "../../config/service";
import DialogCommon from "../../component/common/Dialogs";
import toast from "react-hot-toast";

const OtpWrapper = styled("div")({
  height: "100dvh",
  width: "40%",
  overflow: "hidden",
  marginTop: "160px",
  marginRight: "100px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width:1280px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",

    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
});

const HeadingContainer = styled("div")({
  marginTop: "14px",
  "@media(max-width:1024px)": {
    width: "70vw !important",
  },
});

const OtpVerifictaion = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [minutes, setMinutes] = useState(
    sessionStorage.getItem("minutes") ? sessionStorage.getItem("minutes") : 2
  );
  const [seconds, setSeconds] = useState(
    sessionStorage.getItem("seconds") ? sessionStorage.getItem("seconds") : 0
  );
  const [isLoading, setIsLoading] = useState(false);
  const [netWorkError, setNetWorkError] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [openOtpSuccessDialog, setOpenOtpSuccessDialog] = useState(false);
  const [openResendDialog, setOpenResendDialog] = useState(false);
  const [navigateAfterClose, setNavigateAfterClose] = useState(false);

  const handleCloseResend = () => setOpenResendDialog(false);
  const handleCloseSuccess = () => {
    setOpenOtpSuccessDialog(false);
    if (navigateAfterClose) {
      navigate("/resetPassword", {
        state: { email: state?.email?.toLowerCase()?.trim() },
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (netWorkError) {
        setNetWorkError("");
      }
      if (newMessage) {
        setNewMessage("");
      }
    }, 5000);
  }, [netWorkError, newMessage]);

  useEffect(() => {
    if (!state?.email) {
      return navigate("/");
    }
  }, []);

  useEffect(() => {
    setOtp("");
  }, [netWorkError]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    sessionStorage.setItem("minutes", minutes);
    sessionStorage.setItem("seconds", seconds);

    return () => {
      sessionStorage.removeItem("minutes");
      sessionStorage.removeItem("seconds");
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const reSendOtp = async () => {
    setNetWorkError("");
    setOtp();
    setOpenResendDialog(true);
    const payLoadToSend = {
      email: state?.email?.toLowerCase()?.trim(),
    };
    try {
      const response = await postAPIHandlerWithoutToken({
        endPoint: "resentOtp",
        dataToSend: payLoadToSend,
      });
      console.log(response, "response");

      if (response?.data?.responseCode === 200) {
        setMinutes(2);
        setSeconds(0);
      }
    } catch (error) {
      setNetWorkError(error?.response?.responseMessage);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  const handleOtpSubmission = async () => {
    try {
      if (!otp) {
        setNetWorkError("Otp is required.");
        return;
      }
      if (otp?.length < 6) {
        setNetWorkError("Otp should contain 6 digits.");
        return;
      }
      const payLoadToSend = {
        otp: otp,
        email: state?.email?.toLowerCase()?.trim(),
      };
      const response = await postAPIHandlerWithoutToken({
        endPoint: "verifyOtp",
        dataToSend: payLoadToSend,
      });

      if (response?.data?.responseCode === 200) {
        setOpenOtpSuccessDialog(true);
        setNavigateAfterClose(true);
      } else {
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      console.log(error);

      const errorMessage =
        error?.response?.data?.responseMessage ||
        "An error occurred. Please try again.";
      toast.error(errorMessage);
      setOtp("");
      console.error("Error:", error);
    }
  };

  return (
    <OtpWrapper>
      <Typography variant="h4">OTP Verification</Typography>

      <HeadingContainer>
        <Typography variant="body1" color="gray" textAlign="center">
          Please enter the OTP sent on your email:
          {state?.email?.toLowerCase()?.trim()}
        </Typography>
      </HeadingContainer>

      <Box
        mt={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {netWorkError && (
          <Grid
            container
            item
            py={1}
            borderRadius={"8px"}
            mb={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="body1" color={"red"} fontWeight={"400"}>
              {netWorkError}
            </Typography>
          </Grid>
        )}
        {newMessage && (
          <Grid
            container
            item
            py={1}
            borderRadius={"8px"}
            mb={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="body1" color={"green"} fontWeight={"400"}>
              {newMessage}
            </Typography>
          </Grid>
        )}
        <FormControl
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <label
            style={{
              margin: "0px 20px",
            }}
          >
            Enter OTP
          </label>

          <OtpInput
            value={otp}
            onChange={(e) => {
              setOtp(e);
              setNetWorkError("");
              setNewMessage("");
            }}
            numInputs={6}
            inputType="tel"
            inputMode="numeric"
            pattern="[0-9]*"
            renderSeparator={
              <Box display={"inline"} mx={1}>
                {" "}
              </Box>
            }
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "center",

              flexWrap: "wrap",
              margin: "0px 10px",
              marginTop: "14px",
            }}
            inputStyle={{
              minWidth: "55px",
              height: "45px",
              padding: "0px",
              appearance: "textfield",
            }}
            renderInput={(props) => <input {...props} />}
          />
          {minutes > 0 || seconds > 0 ? (
            <Box
              mt={2}
              sx={{
                width: `calc(55px * 7.5)`,
                textAlign: "right",
              }}
            >
              {String(minutes).padStart(2, "0").replace("-", "")}:
              {String(seconds).padStart(2, "0")}
            </Box>
          ) : (
            <Box
              mt={2}
              sx={{
                width: `calc(55px * 7.5)`,
                textAlign: "center",
              }}
            >
              <span
                variant="body2"
                sx={{ fontSize: "16px" }}
                color="gray"
                textAlign="center"
                alignItems="center"
              >
                If you don't receive any OTP ?
              </span>
              <Button
                onClick={() => {
                  reSendOtp();
                }}
                variant="text"
                sx={{
                  color: "#e55f18",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                Resend OTP
              </Button>
            </Box>
          )}
        </FormControl>

        <Box
          mt={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleOtpSubmission();
            }}
            style={{
              background:
                "linear-gradient(98deg, #2A73E0 -1.68%, #1D60B3 103.45%)",
              textTransform: "none",
              padding: "8px 100px",
            }}
            variant="contained"
            loading={isLoading}
          >
            Continue
          </Button>
        </Box>

        <Box
          mt={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <Typography
        component={Button}
        variant="subtitle2"
        color={"#FF6A13"}
        sx={{
          fontSize: { xs: "12px", md: "16px" },
          textTransform: "none",
          textDecoration: "underline",
          fontWeight: "500",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        Back to login
      </Typography>
        </Box>
      </Box>

      <DialogCommon
        handleClose={handleCloseSuccess}
        open={openOtpSuccessDialog}
        type="OtpAuthCredentialsMatch"
      />
      <DialogCommon
        handleCloseResend={handleCloseResend}
        open={openResendDialog}
        type="OtpResendDialog"
      />
    </OtpWrapper>
  );
};
export default OtpVerifictaion;
