import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Typography,
  Grid,
  Button,
  IconButton,
  FormHelperText,
  Radio,
  FormControlLabel,
  RadioGroup,
  TextField,
  Box,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { addArticleSchema } from "../../../../schema/index";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { putAPIHandler } from "../../../../config/service";
import { fileUploadHandler } from "../../../../utils/fileUploadHandler";
import toast from "react-hot-toast";
import { transliterateToHindi } from "../../../../config/service";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";

const EditArticle = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pdfloadingEn, setPdfLoadingEn] = useState(false);
  const [pdfloadingHi, setPdfLoadingHi] = useState(false);
  const [imgloadingHi, setImgLoadingHi] = useState(false);
  const [imgloadingEn, setImgLoadingEn] = useState(false);
  const [thumbloadingEn, setThumbLoadingEn] = useState(false);
  const [thumbloadingHi, setThumbLoadingHi] = useState(false);
  const { state } = useLocation();
  const articleData = state?.value;
  const isload= pdfloadingEn ||pdfloadingEn || imgloadingHi || imgloadingEn || thumbloadingEn || thumbloadingHi

console.log(articleData,"articleData");
 
 
  const fileUploader = async (
    event,
    setFieldValue,
    fieldName,
    setLoadingState
  ) => {
    setLoadingState(true);
    const file = event.target.files[0];

    if (!file) {
      setFieldValue(fieldName, null);
      setLoadingState(false);
      return;
    }

    const imageTypes = [
      "image/jpeg",  // JPG or JPEG image
      "image/png",   // PNG image
   
      "image/webp",  // WebP image
    
      "image/jpg", // SVG image

    ];
    
    const videoTypes = ["video/mp4"];
    const pdfTypes = ["application/pdf"];
    const isImage = imageTypes.includes(file.type);
    const isVideo = videoTypes.includes(file.type);
    const isPdf = pdfTypes.includes(file.type);

    if (!isImage && !isVideo && !isPdf) {
      toast.error(
        "Unsupported file type. Please upload JPG, PNG, PDF, or MP4 files only."
      );
      setFieldValue(fieldName, null);
      setLoadingState(false);
      return;
    }

    const maxSize = isImage ? 5 * 1024 * 1024 : 10 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error(
        `The file is too large. Please upload a file smaller than ${
          isImage ? "5MB" : "10MB"
        }.`
      );
      setFieldValue(fieldName, null);
      setLoadingState(false);
      return;
    }

    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = async () => {
       

        const uploadedUrl = await fileUploadHandler(file);
        setFieldValue(fieldName, uploadedUrl);
        
        if (uploadedUrl) {
          if (fieldName === "thumbnailImage" || fieldName === "thumbnailImageHindi") {
          

            setFieldValue(
              `thumbnailUrl_${fieldName.includes("Hindi") ? "hi" : "en"}`,
              uploadedUrl
            );
            setLoadingState(false);
          } else {

          

            setFieldValue(
              `documentUrl_${fieldName.includes("Hindi") ? "hi" : "en"}`,
              uploadedUrl
            );
            setLoadingState(false);
          }
        } else {
          toast.error("Failed to upload image. Please try again.");
          setFieldValue(fieldName, null);
          setLoadingState(false);
        }
      };
      reader.readAsDataURL(file);
    } else if (isVideo || isPdf) {
      

      const uploadedUrl = await fileUploadHandler(file);
      setFieldValue(fieldName, uploadedUrl);
 
      if (uploadedUrl) {
        if (isVideo) {
          setFieldValue(
            `documentUrl_${fieldName.includes("Hindi") ? "hi" : "en"}`,
            uploadedUrl
          );
          setLoadingState(false);
        } else if (isPdf) {
          setFieldValue(
            `pdfUrl_${fieldName.includes("Hindi") ? "hi" : "en"}`,
            uploadedUrl
          );
          setLoadingState(false);
        }
      } else {
        toast.error("Failed to upload file. Please try again.");
        setFieldValue(fieldName, null);
        setLoadingState(false);
      }
    }
  };
  const onSubmit = async (values) => {
    const payloadToSend = {
      categoryId: articleData?.categoryId,
      articleId: articleData?.id,
      articleType_en: values.articleType || "",
      articleType_hi: values.articleTypeHindi || "",
      articleTitle_en: values.articleName || "",
      articleTitle_hi: values.articleNameHindi || "",
      documentUrl_en: values.documentUrl_en || values.articleVideo || values.articleImage ,
      documentUrl_hi: values.documentUrl_hi || values.articleVideoHindi || values.articleImageHindi,
      pdfUrl_en: values.uploadPdf || values.pdfUrl_en,
      pdfUrl_hi: values.uploadPdfHindi || values.pdfUrl_hi,
      description_en: values.description || "",
      description_hi: values.descriptionHindi || "",
      ...(values.articleType === "VIDEO" && {
        thumbnail_en: values.thumbnailUrl_en || values.thumbnailImage ,
        thumbnail_hi: values.thumbnailUrl_hi || values.thumbnailImageHindi 
      }),
    };

    try {
      const response = await putAPIHandler({
        endPoint: "updateCategoryArticle",
        dataToSend: payloadToSend,
      });
      console.log(response, "response");

      if (response && response.data.responseCode === 200) {
        navigate("/informative-content/view-category");
      } else {
        console.error("error.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          Edit Article
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Formik
          initialValues={{
            articleType: articleData?.articleType_en || "",
            articleTypeHindi: articleData?.articleType_hi || "",
            articleName: articleData?.articleTitle_en || "",
            articleNameHindi: articleData?.articleTitle_hi || "",
            articleVideo: articleData?.documentUrl_en || null,
            articleVideoHindi: articleData?.documentUrl_hi || null,
            articleImage: articleData?.documentUrl_en || null,
            articleImageHindi: articleData?.documentUrl_hi || null,
            description: articleData?.description_en || "",
            descriptionHindi: articleData?.description_hi || "",
            uploadPdf: articleData?.pdfUrl_en || null,
            uploadPdfHindi: articleData?.pdfUrl_hi || null,
            thumbnailImage: articleData?.thumbnail_en || null,
            thumbnailImageHindi: articleData?.thumbnail_hi || null,
          }}
          validationSchema={addArticleSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form
            style={{
              minWidth: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              // sm={8}
              md={10}
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                p: "10px",
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                <Grid container item sm={12} lg={2}>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Article Type (आलेख प्रकार):
                  </label>
                </Grid>

                <Grid container item sm={12} lg={3}>
                  <RadioGroup
                    row
                    name="articleType"
                    value={values.articleType}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("articleType", value);
                      setFieldValue("articleTypeHindi", value);
                    }}
                  >
                    <FormControlLabel
                      value="IMAGE"
                      control={<Radio />}
                      label="Image"
                    />
                    <FormControlLabel
                      value="VIDEO"
                      control={<Radio />}
                      label="Video"
                    />
                  </RadioGroup>

                  {errors.articleType && touched.articleType && (
                    <FormHelperText error>
                      {errors.articleType}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid container item sm={12} lg={3}>
                  <RadioGroup
                    row
                    name="articleTypeHindi"
                    value={values.articleTypeHindi}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("articleType", value);
                      setFieldValue("articleTypeHindi", value);
                    }}
                  >
                    <FormControlLabel
                      value="IMAGE"
                      control={<Radio />}
                      label="छवि"
                    />
                    <FormControlLabel
                      value="VIDEO"
                      control={<Radio />}
                      label="वीडियो"
                    />
                  </RadioGroup>
                  {errors.articleTypeHindi && touched.articleTypeHindi && (
                    <FormHelperText error>
                      {errors.articleTypeHindi}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                <Grid container item sm={12} lg={2}>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    Article Name (आलेख नाम):
                  </Typography>
                </Grid>

                <Grid container item sm={12} lg={4}>
                  <TextField
                    fullWidth
                    type="text"
                    id="articleName"
                    name="articleName"
                    placeholder="Enter article name"
                    inputProps={{
                      maxLength: 80,
                    }}
                    value={values.articleName}
                    disabled={isLoading}
                    onChange={(e) => {
                      const englishText = e.target.value;
                      handleChange(e);
                      transliterateToHindi(englishText).then((hindiText) => {
                        setFieldValue("articleNameHindi", hindiText);
                      });
                    }}
                    onBlur={handleBlur}
                    error={touched.articleName && Boolean(errors.articleName)}
                    helperText={touched.articleName && errors.articleName}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                  />
                </Grid>
                <Grid container item sm={12} lg={4}>
                  <TextField
                    fullWidth
                    value={values.articleNameHindi}
                    type="text"
                    id="articleNameHindi"
                    name="articleNameHindi"
                    placeholder="आलेख का नाम दर्ज करें"
                    inputProps={{
                      maxLength: 80,
                    }}
                    disabled={isLoading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.articleNameHindi &&
                      Boolean(errors.articleNameHindi)
                    }
                    helperText={
                      touched.articleNameHindi && errors.articleNameHindi
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                <Grid container item sm={12} lg={2}>
                  <label
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Description (विवरण ):
                  </label>
                </Grid>

                <Grid container item sm={12} lg={4}>
                  <TextField
                    fullWidth
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Enter description"
                    multiline
                    rows={4} // Adjust rows for desired height
                    inputProps={{
                      maxLength: 1200,
                    }}
                    value={values.description}
                    disabled={isLoading}
                    // onChange={(e) => {
                    //   const englishText = e.target.value;
                    //   const hindiText = transliterateToHindi(englishText);
                    //   handleChange(e);
                    //   setFieldValue("descriptionHindi", hindiText);
                    // }}
                    onChange={(e) => {
                      const englishText = e.target.value;
                      handleChange(e);
                      transliterateToHindi(englishText).then((hindiText) => {
                        setFieldValue("descriptionHindi", hindiText);
                      });
                    }}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                  />
                </Grid>
                <Grid container item sm={12} lg={4}>
                  <TextField
                    fullWidth
                    value={values.descriptionHindi}
                    type="text"
                    id="descriptionHindi"
                    name="descriptionHindi"
                    placeholder="विवरण दर्ज करें"
                    multiline
                    rows={4} // Adjust rows for desired height
                    inputProps={{
                      maxLength: 1200,
                    }}
                    disabled={isLoading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.descriptionHindi &&
                      Boolean(errors.descriptionHindi)
                    }
                    helperText={
                      touched.descriptionHindi && errors.descriptionHindi
                    }
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 20px",
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        backgroundColor: "#fff",
                      },
                      "& .MuiInputBase-root:hover": {
                        backgroundColor: "#f4f4f4",
                        borderColor: "#bbb",
                      },
                      "& .Mui-focused": {
                        borderColor: "rgb(60,115,224)",
                      },
                      "& input": {
                        padding: 0, // Adjust if necessary
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sx={{
                  gap: { sm: 1, lg: 6 },
                  justifyContent: { sm: "flex-start", md: "center" },
                }}
                mt={2}
              >
                <Grid container item sm={12} lg={2}>
                  <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                    Upload PDF (PDF अपलोड करें):
                  </Typography>
                </Grid>

                {/* First PDF Upload */}
                <Grid
                  container
                  item
                  sm={12}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) =>
                        fileUploader(
                          e,
                          setFieldValue,
                          "uploadPdf",
                          setPdfLoadingEn
                        )
                      }
                      style={{
                        display: "none",
                      }}
                      id="uploadPdf"
                    />
                    <label htmlFor="uploadPdf">
                      <Button
                        variant="outlined"
                        component="span"
                        fullWidth
                        sx={{
                          width: "100%",
                          padding: "12px 20px",
                          borderRadius: "8px",
                          borderColor: "#ccc",
                          textTransform: "none",
                          color: "#333",
                          "&:hover": {
                            backgroundColor: "#f4f4f4",
                            borderColor: "#bbb",
                          },
                        }}
                        startIcon={<PictureAsPdfIcon />} // PDF icon
                      >
                        Upload PDF
                      </Button>
                    </label>
                    {errors.uploadPdf && touched.uploadPdf && (
                      <FormHelperText error>
                        {errors.uploadPdf}
                      </FormHelperText>
                    )}

                    {pdfloadingEn ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : values.uploadPdf ? (
                      <Box sx={{ marginTop: "20px" }}>
                        <iframe
                          src={values.uploadPdf}
                          width="100%"
                          height="100px"
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                          }}
                          title="PDF Preview"
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Grid>

                {/* Second PDF Upload */}
                <Grid
                  container
                  item
                  sm={12}
                  lg={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) =>
                        fileUploader(
                          e,
                          setFieldValue,
                          "uploadPdfHindi",
                          setPdfLoadingHi
                        )
                      }
                      style={{
                        display: "none",
                      }}
                      id="uploadPdfHindi"
                    />
                    <label htmlFor="uploadPdfHindi">
                      <Button
                        variant="outlined"
                        component="span"
                        sx={{
                          width: "100%",
                          padding: "12px 20px",
                          borderRadius: "8px",
                          borderColor: "#ccc",
                          textTransform: "none",
                          color: "#333",
                          "&:hover": {
                            backgroundColor: "#f4f4f4",
                            borderColor: "#bbb",
                          },
                        }}
                        startIcon={<PictureAsPdfIcon />} // PDF icon
                      >
                        हिंदी PDF अपलोड करें
                      </Button>
                    </label>
                    {errors.uploadPdfHindi && touched.uploadPdfHindi && (
                      <FormHelperText error>
                        {errors.uploadPdfHindi}
                      </FormHelperText>
                    )}

                    {pdfloadingHi ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : values.uploadPdfHindi ? (
                      <Box sx={{ marginTop: "20px" }}>
                        <iframe
                          src={values.uploadPdfHindi}
                          width="100%"
                          height="100px"
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                          }}
                          title="PDF Preview"
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>

              {values.articleType && (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                  mt={2}
                >
                  {/* Thumbnail Image Label */}
                  <Grid container item sm={12} lg={2}>
                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                      {values.articleType === "VIDEO"
                        ? "Article Video (आलेख वीडियो)"
                        : "Article Image (आलेख छवि)"}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    lg={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {values.articleType === "IMAGE" && (
                        <>
                          <input
                            type="file"
                            name="articleImage"
                            accept="image/jpeg, image/png"
                            onChange={(e) =>
                              fileUploader(
                                e,
                                setFieldValue,
                                "articleImage",
                                setImgLoadingEn
                              )
                            }
                            style={{ display: "none" }}
                            id="articleImage"
                          />

                          <label htmlFor="articleImage">
                            <Button
                              variant="outlined"
                              component="span"
                              sx={{
                                width: "100%",
                                padding: "12px 20px",
                                borderRadius: "8px",
                                borderColor: "#ccc",
                                textTransform: "none",
                                color: "#333",
                                "&:hover": {
                                  backgroundColor: "#f4f4f4",
                                  borderColor: "#bbb",
                                },
                              }}
                              startIcon={<PhotoCameraIcon />}
                            >
                              Upload Image
                            </Button>
                          </label>
                          {errors.articleImage && touched.articleImage && (
                            <FormHelperText error>
                              {errors.articleImage}
                            </FormHelperText>
                          )}
                          {imgloadingEn ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 2,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            // Reserve space for image preview
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: values.articleImage ? "100px" : "0",
                                width: "100%",
                                mt: 1,
                              }}
                            >
                              {values.articleImage && (
                                <Avatar
                                  alt="Image Preview"
                                  src={values.articleImage}
                                  sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: "8px",
                                    boxShadow:
                                      "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      boxShadow:
                                        "0px 6px 12px rgba(0, 0, 0, 0.2)",
                                    },
                                  }}
                                />
                              )}
                            </Box>
                          )}
                        </>
                      )}
                     {values.articleType === "VIDEO" && (
<>
  <input
    type="file"
    accept="video/mp4"
    onChange={(e) =>
      fileUploader(
        e,
        setFieldValue,
        "articleVideo",
        setImgLoadingEn
      )
    }
    style={{ display: "none" }}
    id="articleVideo"
  />

  <label htmlFor="articleVideo">
    <Button
      variant="outlined"
      component="span"
      sx={{
        width: "100%",
        padding: "12px 20px",
        borderRadius: "8px",
        borderColor: "#ccc",
        textTransform: "none",
        color: "#333",
        "&:hover": {
          backgroundColor: "#f4f4f4",
          borderColor: "#bbb",
        },
      }}
      startIcon={<VideocamIcon />}
    >
      Upload Video
    </Button>
  </label>
  {errors.articleVideo && touched.articleVideo && (
    <FormHelperText error>{errors.articleVideo}</FormHelperText>
  )}

  {imgloadingEn ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2, // Space for the loader
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    // Reserve space for video preview
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 3, // Added margin-top for spacing
        mb: 3, // Added margin-bottom to avoid overlap with fields below
        width: "100%",
      }}
    >
      {values.articleVideo && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "auto",
          }}
        >
          <video
            width="100%"
            height="auto"
            controls
            src={values.articleVideo}
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            Your browser does not support the video tag.
          </video>
        </Box>
      )}
    </Box>
  )}
</>
)}

                    </Box>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    lg={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {values.articleType === "IMAGE" && (
                        <>
                          <input
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={(e) =>
                              fileUploader(
                                e,
                                setFieldValue,
                                "articleImageHindi",
                                setImgLoadingHi
                              )
                            }
                            style={{ display: "none" }}
                            id="articleImageHindi"
                          />

                          <label htmlFor="articleImageHindi">
                            <Button
                              variant="outlined"
                              component="span"
                              sx={{
                                width: "100%",
                                padding: "12px 20px",
                                borderRadius: "8px",
                                borderColor: "#ccc",
                                textTransform: "none",
                                color: "#333",
                                "&:hover": {
                                  backgroundColor: "#f4f4f4",
                                  borderColor: "#bbb",
                                },
                              }}
                              startIcon={<PhotoCameraIcon />}
                            >
                              चित्र अपलोड करें
                            </Button>
                          </label>
                          {errors.articleImageHindi &&
                            touched.articleImageHindi && (
                              <FormHelperText error>
                                {errors.articleImageHindi}
                              </FormHelperText>
                            )}

                          {imgloadingHi ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mt: 2,
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            // Reserve space for image preview
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: values.articleImageHindi
                                  ? "100px"
                                  : "0",
                                width: "100%",
                                mt: 1,
                              }}
                            >
                              {values.articleImageHindi && (
                                <Avatar
                                  alt="Image Preview"
                                  src={values.articleImageHindi}
                                  sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: "8px",
                                    boxShadow:
                                      "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.2s ease-in-out",
                                    "&:hover": {
                                      transform: "scale(1.1)",
                                      boxShadow:
                                        "0px 6px 12px rgba(0, 0, 0, 0.2)",
                                    },
                                  }}
                                />
                              )}
                            </Box>
                          )}
                        </>
                      )}

                      {values.articleType === "VIDEO" && (
                        <>
                          <input
                            type="file"
                            accept="video/mp4"
                            onChange={(e) =>
                              fileUploader(
                                e,
                                setFieldValue,
                                "articleVideoHindi",
                                setImgLoadingHi
                              )
                            }
                            style={{ display: "none" }}
                            id="articleVideoHindi"
                          />

                          <label htmlFor="articleVideoHindi">
                            <Button
                              variant="outlined"
                              component="span"
                              sx={{
                                width: "100%",
                                padding: "12px 20px",
                                borderRadius: "8px",
                                borderColor: "#ccc",
                                textTransform: "none",
                                color: "#333",
                                "&:hover": {
                                  backgroundColor: "#f4f4f4",
                                  borderColor: "#bbb",
                                },
                              }}
                              startIcon={<VideocamIcon />}
                            >
                              वीडियो अपलोड करें
                            </Button>
                          </label>
                          {errors.articleVideoHindi &&
                            touched.articleVideoHindi && (
                              <FormHelperText error>
                                {errors.articleVideoHindi}
                              </FormHelperText>
                            )}

{imgloadingHi ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2, // Space for the loader
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    // Reserve space for video preview
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 3, // Added margin-top for spacing
        mb: 3, // Added margin-bottom to avoid overlap with fields below
        width: "100%",
      }}
    >
      {values.articleVideoHindi && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "auto",
          }}
        >
          <video
            width="100%"
            height="auto"
            controls
            src={values.articleVideoHindi}
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            Your browser does not support the video tag.
          </video>
        </Box>
      )}
    </Box>
  )}
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}

              {values.articleType === "VIDEO" && (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                  mt={2}
                >
                  <Grid container item sm={12} lg={2}>
                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                      Thumbnail (थंबनेल छवि):
                    </Typography>
                  </Grid>

                  {/* Thumbnail Image Upload Field */}
                  <Grid
                    container
                    item
                    sm={12}
                    lg={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          fileUploader(
                            e,
                            setFieldValue,
                            "thumbnailImage",
                            setThumbLoadingEn
                          )
                        }
                        style={{ display: "none" }}
                        id="thumbnailImage"
                      />

                      <label htmlFor="thumbnailImage">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{
                            width: "100%",
                            padding: "12px 20px",
                            borderRadius: "8px",
                            borderColor: "#ccc",
                            textTransform: "none",
                            color: "#333",
                            "&:hover": {
                              backgroundColor: "#f4f4f4",
                              borderColor: "#bbb",
                            },
                          }}
                          startIcon={<PhotoCameraIcon />}
                        >
                          Upload Image
                        </Button>
                      </label>
                      {errors.thumbnailImage && touched.thumbnailImage && (
                        <FormHelperText error>
                          {errors.thumbnailImage}
                        </FormHelperText>
                      )}

                      {thumbloadingEn ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        // Reserve space for image preview
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: values.thumbnailImage ? "100px" : "0",
                            width: "100%",
                            mt: 1,
                          }}
                        >
                          {values.thumbnailImage && (
                            <Avatar
                              alt="Image Preview"
                              src={values.thumbnailImage}
                              sx={{
                                width: 100,
                                height: 100,
                                borderRadius: "8px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                  transform: "scale(1.1)",
                                  boxShadow:
                                    "0px 6px 12px rgba(0, 0, 0, 0.2)",
                                },
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>

                  <Grid
                    container
                    item
                    sm={12}
                    lg={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          fileUploader(
                            e,
                            setFieldValue,
                            "thumbnailImageHindi",
                            setThumbLoadingHi
                          )
                        }
                        style={{ display: "none" }}
                        id="thumbnailImageHindi"
                      />
                      <label htmlFor="thumbnailImageHindi">
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{
                            width: "100%",
                            padding: "12px 20px",
                            borderRadius: "8px",

                            textTransform: "none",
                            color: "#333",
                            "&:hover": {
                              backgroundColor: "#f4f4f4",
                              borderColor: "#bbb",
                            },
                          }}
                          startIcon={<PhotoCameraIcon />}
                        >
                          चित्र अपलोड करें
                        </Button>
                      </label>
                      {errors.thumbnailImageHindi &&
                        touched.thumbnailImageHindi && (
                          <FormHelperText error>
                            {errors.thumbnailImageHindi}
                          </FormHelperText>
                        )}

                      {thumbloadingHi ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        // Reserve space for image preview
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: values.thumbnailImageHindi
                              ? "100px"
                              : "0",
                            width: "100%",
                            mt: 1,
                          }}
                        >
                          {values.thumbnailImageHindi && (
                            <Avatar
                              alt="Image Preview"
                              src={values.thumbnailImageHindi}
                              sx={{
                                width: 100,
                                height: 100,
                                borderRadius: "8px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                transition: "transform 0.2s ease-in-out",
                                "&:hover": {
                                  transform: "scale(1.1)",
                                  boxShadow:
                                    "0px 6px 12px rgba(0, 0, 0, 0.2)",
                                },
                              }}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                mt={6}
                mb={8}
                sx={{ gap: { xs: 4, md: 10 } }}
              >
                <Grid item container xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "gray" }}
                    onClick={() => {
                      navigate(-1);
                    }}
                    disabled={isload}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item container xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ background: "#F83629" }}
                    type="submit"
                    disabled={isload}
                  >
                    EDIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default EditArticle;
