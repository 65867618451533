import styled from "@emotion/styled";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useScrollContainer } from "react-indiana-drag-scroll";
import { useNavigate } from "react-router-dom";
// import { AssessmentType } from "../assessmentManagement/AddEditAssesmentModal";
// import { getApiHandlerWithToken } from "../../../config/service";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CircularProgressBox from "../../../component/CircularProgressBox";
const ActionButton = styled(Button)({
  backgroundColor: "#F83629",
  color: "white",
  fontWeight: "500",
  margin: "0px 6px",
  // width: "80px",
  "@media(max-width:996px)": {
    marginTop: "8px",
  },
});
const tableHeadData = ["Title", "Action"];
const tableData = [
  { title: "Privacy Policy", type: "privacyPolicy" },
  { title: "Terms & Conditions", type: "termsCondition" },
  { title: "Support Contact Phone No.  ", type: "support" },
];

const nonShownData = ["Banner Management", "Team", "FAQ"];

const StaticContent = () => {
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [staticData, setstaticData] = useState([]);

  useEffect(() => {
    getInitialStaticData();
  }, []);

  const handleNavigation = (path) => {
    // const stateData = { type: path, allStatic };
    if (path === "support") {
      navigate("/static-content/view-contact", { state: { type: path } });
    } else {
      navigate("/static-content/view-content", { state: { type: path } });
    }
  };
  const handleEditNavigation = (path) => {
    if (path === "support") {
      navigate("/static-content/edit-contact", { state: { type: path } });
    } else {
      navigate("/static-content/edit-content", { state: { type: path } });
    }
  };

  async function getInitialStaticData() {
    // setIsDataLoading(true);
    // const res = await getApiHandlerWithToken("findAllStaticContentList");
    // setIsDataLoading(false);
    // if (!res) {
    //   return;
    // }
    // setstaticData(res?.result);
  }

  function navigationHandler(value, type) {
    switch (value) {
      case "Privacy Policy":
        navigate("/static-content-management/content", {
          state: {
            data: staticData?.filter((e, i) => {
              return e?.content_type == "privacyPolicy";
            })[0],
            type: type,
          },
        });
        break;
      case "Terms & Conditions":
        navigate("/static-content-management/content", {
          state: {
            data: staticData?.filter((e, i) => {
              return e?.content_type == "termsAndCondition";
            })[0],
            type: type,
          },
        });
        break;
      case "FAQ":
        navigate("/static-content-management/faq");
        break;
      case "About Us":
        navigate("/static-content-management/content", {
          state: {
            data: staticData?.filter((e, i) => {
              return e?.content_type == "aboutUs";
            })[0],
            type: type,
          },
        });
        break;
      case "Banner Management":
        navigate("/static-content-management/banner");
        break;
      case "Demo":
        console.log(value);
        break;

      default:
        break;
    }
  }

  //   const scrollContainer = useScrollContainer();
  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: { xs: 6, md: 2, lg: 0 },
      }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" fontWeight="600">
          Static Content Management
        </Typography>
      </Grid>

      {isDataLoading ? (
        <CircularProgressBox />
      ) : (
        <TableContainer
          className="removeScrollbar"
          //   ref={scrollContainer.ref}
          component={Paper}
          sx={{
            mt: 4,
            overflowX: "scroll",
            width: "100%",
          }}
        >
          <Table sx={{ minWidth: 600 }}>
            <TableHead
              sx={{
                bgcolor: "#2A73E0",
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "4px",
                    height: "45px",
                  },
                }}
              >
                {tableHeadData.map((e, i) => (
                  <TableCell key={i} align="center" sx={{ color: "white" }}>
                    {e}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((item) => (
                <TableRow
                  key={item}
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "4px",
                    },
                  }}
                >
                  <TableCell align="center" scope="row">
                    {item?.title}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    <Tooltip title={"View"}>
                      <IconButton
                        variant="filled"
                        onClick={() => handleNavigation(item?.type)}
                        sx={{
                          color: "#5D9FED",
                        }}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Edit"}>
                      <IconButton
                        variant="filled"
                        onClick={() => handleEditNavigation(item?.type)}
                        sx={{ color: "#2b2b52" }}
                      >
                        <ModeEditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

export default StaticContent;
