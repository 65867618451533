import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import { Box, FormControl, TextField, Button, Grid, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postAPIHandlerWithoutToken } from "../../config/service";
import DialogCommon from "../../component/common/Dialogs";
import EmailIcon from "@mui/icons-material/Email";
const ForgetWrapper = styled("div")({
  height: "100dvh",
  width: "40%",
  overflow: "hidden",
  marginTop: "160px",
  marginRight: "100px",
  "@media(max-width:1280px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
});
const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "8px",

  width: "35vw",
  "@media(max-width:1024px)": {
    width: "70vw !important",
    // margin: "10px 0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    marginTop: "2px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#1D60B3",
    },
  },
});
const HeadingContainer = styled("div")({
  marginTop: "14px",
  "@media(max-width:1024px)": {
    width: "35vw !important",
  },
});

const forgetValidationSchema = Yup.object({
  email: Yup.string()
    .email("Enter valid email.")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Enter valid email.")
    .trim()
    .required("Email is required."),
  // .max(79, "Maximum 80 character are allowed."),
});

const Forget = () => {
  const navigate = useNavigate();
  const [netWorkError, setNetWorkError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [navigateAfterClose, setNavigateAfterClose] = useState(false);
  const [emailForOTP, setEmailForOTP] = useState("");
  const navigator = useNavigate();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccessDialog(false);
    if (navigateAfterClose) {
      navigator("/otpverification", {
        state: { email: emailForOTP?.toLowerCase()?.trim() },
      });
    }
  };

  const forgetPasswordHandler = async (values) => {
    try {
      const payLoadToSend = {
        email: values.email?.toLowerCase()?.trim(),
        userType:"ADMIN"
      };
      const response = await postAPIHandlerWithoutToken({
        endPoint: "resentOtp",
        dataToSend: payLoadToSend,
      });
      console.log(response, "response");
      if (response?.data?.responseCode === 200) {
        setEmailForOTP(values.email);
        setOpenSuccessDialog(true);
        setNavigateAfterClose(true);
      } else {
        setOpenDialog(true);
      }
    } catch (error) {
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setNetWorkError("");
    }, 5000);
  }, [netWorkError]);

  return (
    <ForgetWrapper>
      <Typography variant="h4" align="center">Forgot Password?</Typography>
      <HeadingContainer>
        <Typography variant="body1" color="gray" align="center">
          Please enter your registered email address and we will send you OTP to
          reset your password.
        </Typography>
      </HeadingContainer>

      <Box mt={3}>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={forgetValidationSchema}
          onSubmit={forgetPasswordHandler}
        >
          {({ errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Grid container item py={1} borderRadius={"8px"} mb={2}>
                <Typography variant="body1" color={"red"} fontWeight={"400"}>
                  {netWorkError}
                </Typography>
              </Grid>
              <FormControl sx={{ width: "100%" }}>
                <label>Email</label>
                <FormTextField
                  variant="outlined"
                  placeholder="Enter your email"
                  inputProps={{
                    maxLength: 80,
                    style: {
                      height: "20px",
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  name="email"
                  onChange={(e) => {
                    setNetWorkError("");
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </FormControl>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    background:
                      "linear-gradient(98deg, #2A73E0 -1.68%, #1D60B3 103.45%)",
                    textTransform: "none",
                    padding: "8px 50px",
                  }}
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Continue
                </Button>
              </Box>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={Button}
                  variant="subtitle2"
                  color={"#FF6A13"}
                  sx={{
                    fontSize: { xs: "12px", md: "16px" },
                    textTransform: "none",
                    textDecoration: "underline",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Back to login
                </Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>

      <DialogCommon
        handleClose={handleClose}
        open={openDialog}
        type="ForgotAuthCredentialsNotMatch"
      />
      <DialogCommon
        handleCloseSuccess={handleCloseSuccess}
        open={openSuccessDialog}
        type="ForgotSuccessAuthCredentialsMatch"
      />
    </ForgetWrapper>
  );
};

export default Forget;
