import { Box, CircularProgress, Container, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

const NoDataFound = ({ message, btnName, descript, color = "#111111", fontSize = "30px" }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        minHeight: "400px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box style={{ display: "column", textAlign: "center", justifyContent: "center" }}>
        <Container maxWidth="sm">
          {btnName ?
            <img src="/images/error.png" alt="error" style={{ width: "100px", height: "100px", position: "relative", top: "120px" }} />

            : ""}
          <Typography sx={{ fontWeight: "700", color: color, fontSize: "20px", marginTop: { xs: "200px", md: "150px" } }}>
            {message ? message : "No Business Owner Assigned Yet"}
          </Typography>
          <Typography style={{ textAlign: "center", fontWeight: "600", marginTop: "10px", fontSize: "14px", }}>
            {descript ? descript : ""}
          </Typography>
          {btnName ? <Button sx={{
            background: "linear-gradient(98deg, rgb(228, 103, 3) -1.68%, rgb(199, 52, 13) 103.45%)",
            height: "50px",
            borderRadius: "10px",
            textTransform: "capitalize",
            padding: "0px 30px",
            color: "#fff",
            marginTop: "20px"
          }} onClick={() => {
            navigate(-1);
          }}  >
            {btnName}
          </Button> : ""}

        </Container>


      </Box>
    </Box>
  );
};

export default NoDataFound;
