import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Typography, Grid, Button, IconButton, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { addQuestionnaireSchema } from "../../../../schema/index";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { postAPIHandler } from "../../../../config/service";
import { transliterateToHindi } from "../../../../config/service";

const AddQuestionnaire = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const questionaireid = state?.questionaire;
  const categoryIdState = state?.categoryId;
  console.log("categoryIdState: ", categoryIdState);
  const questionairname = state?.questionairname;

  const onSubmit = async (values) => {
    console.log(values, "values");
    const payloadToSend = {
      categoryId: state?.categoryId,
      questionnaireName_en: values.questionnaireTitle,
      questionnaireName_hi: values.questionnaireTitleHindi,
    };
    try {
      const response = await postAPIHandler({
        endPoint: "addQuestionnaire",
        dataToSend: payloadToSend,
      });
      console.log(response, "response");

      if (response && response.data.responseCode === 200) {
        navigate("/questionnaire/view-category", {
          state: {
            propquestionid: questionaireid,
            proquestionairname: questionairname,
          }, // Pass `questionaireId` in state
        });
      } else {
        console.error("error.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 8%",
        }}
        item
        xs={12}
        mb={2}
      >
        <IconButton
          onClick={() => {
            navigate(-1, {
              state: {
                propquestionid: questionaireid,
                proquestionairname: questionairname,
              },
            });
          }}
          sx={{
            color: "#2A73E0",
          }}
        >
          <ArrowCircleLeftIcon
            sx={{
              fontSize: "30px",
            }}
          />
        </IconButton>
        <Typography color={"#262626"} fontWeight={"600"} variant="h5">
          Add Questionnaire
        </Typography>
      </Grid>

      <Grid item container xs={12}>
        <Formik
          initialValues={{
            questionnaireTitle: "",
            questionnaireTitleHindi: "",
          }}
          validationSchema={addQuestionnaireSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form
              style={{
                minWidth: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                item
                md={10}
                xs={12}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  p: "10px",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignItems={"center"}
                  sx={{
                    gap: { sm: 1, lg: 6 },
                    justifyContent: { sm: "flex-start", md: "center" },
                  }}
                  mt={2}
                >
                  {/* English Input Field */}
                  <Grid container item sm={12} lg={2}>
                    <label style={{ whiteSpace: "nowrap" }}>
                      Questionnaire Title:
                    </label>
                  </Grid>

                  <Grid container item sm={12} lg={4}>
                    <TextField
                    fullWidth
                      type="text"
                      id="questionnaireTitle"
                      name="questionnaireTitle"
                      placeholder="Enter questionnaire title"
                      inputProps={{
                        maxLength: 100,
                       
                      }}

                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "12px 20px",
                          borderRadius: "8px",
                         
                          backgroundColor: "#fff",
                        },
                        "& .MuiInputBase-root:hover": {
                          backgroundColor: "#f4f4f4",
                          borderColor: "#bbb",
                        },
                        "& .Mui-focused": {
                          borderColor: "rgb(60,115,224)",
                        },
                        "& input": {
                          padding: 0, // Adjust if necessary
                        },
                      }}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      disabled={isLoading}
                      value={values.questionnaireTitle}
                      onChange={(e) => {
                        const englishText = e.target.value;
                        handleChange(e);
                        transliterateToHindi(englishText).then((hindiText) => {
                          setFieldValue("questionnaireTitleHindi", hindiText);
                        });
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.questionnaireTitle &&
                        Boolean(errors.questionnaireTitle)
                      }
                      helperText={
                        touched.questionnaireTitle && errors.questionnaireTitle
                      }
                     
                    />
                  </Grid>

                  {/* Auto-Filled Hindi Input Field */}
                  <Grid container item sm={12} lg={4}>
                    <TextField
                    fullWidth
                      type="text"
                      id="questionnaireTitleHindi"
                      name="questionnaireTitleHindi"
                      placeholder="प्रश्नावली शीर्षक दर्ज करें"
                      inputProps={{
                        maxLength: 100,
                       
                      }}

                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "12px 20px",
                          borderRadius: "8px",
                         
                          backgroundColor: "#fff",
                        },
                        "& .MuiInputBase-root:hover": {
                          backgroundColor: "#f4f4f4",
                          borderColor: "#bbb",
                        },
                        "& .Mui-focused": {
                          borderColor: "rgb(60,115,224)",
                        },
                        "& input": {
                          padding: 0, // Adjust if necessary
                        },
                      }}
                      FormHelperTextProps={{
                       
                      }}
                      disabled={isLoading}
                      value={values.questionnaireTitleHindi}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.questionnaireTitleHindi &&
                        Boolean(errors.questionnaireTitleHindi)
                      }
                      helperText={
                        touched.questionnaireTitleHindi &&
                        errors.questionnaireTitleHindi
                      }
                      
                    />
                  </Grid>
                </Grid>

                {/* Buttons */}
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"center"}
                  mt={6}
                  mb={8}
                  sx={{ gap: { xs: 4, md: 10 } }}
                >
                  <Grid item container xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ background: "gray" }}
                      onClick={() => {
                        navigate(-1, {
                          state: {
                            propquestionid: questionaireid,
                            proquestionairname: questionairname,
                          },
                        });
                      }}
                      disabled={isLoading}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item container xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ background: "#F83629" }}
                      type="submit"
                      loading={isLoading}
                    >
                      ADD
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default AddQuestionnaire;
