import * as React from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
  Grid,
  Popper,
  styled,
} from "@mui/material";

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    maxHeight: "none", // Disable maximum height
    overflow: "visible", // Allow full content to show without scrolling
  },
}));

const SearchableDropdown = ({
  options,
  label,
  value,
  onChange,
  multiple = false,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2} alignItems="center" p={1}>
        {/* Label */}
        <Grid item>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {label}:
          </Typography>
        </Grid>

        {/* Dropdown */}
        <Grid item xs>
          <Autocomplete
            sx={{
              "& .MuiInputBase-root": {
                // padding: "12px 20px",
                borderRadius: "8px",

                backgroundColor: "#fff",
              },
              "& .MuiInputBase-root:hover": {
                backgroundColor: "#f4f4f4",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(60,115,224)",
              },
              "& .MuiSelect-select": {
                padding: "12px 20px", // Matches the TextField padding
              },
              "& .Mui-disabled": {
                backgroundColor: "#f5f5f5",
                color: "#999",
              },
            }}
            multiple={multiple} // Enables multiple selection
            value={value} // The selected options array
            onChange={(_, newValue) => onChange(newValue)} // Update the parent component state when the value changes
            disablePortal
            options={options}
            getOptionLabel={(option) => option.label || option.name || option} // Assuming the option object has a 'label' or 'name' field
            isOptionEqualToValue={(option, value) => option.id === value.id} // Comparison function for option selection
            renderInput={(params) => (
              <TextField {...params} label={label} variant="outlined" />
            )}
            PopperComponent={(props) => <StyledPopper {...props} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchableDropdown;
