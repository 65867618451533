// export const baseurl = "http://localhost:2182";
// export const baseurl = "http://172.16.6.83:2182"; //izhaar local
// export const baseurl = "https://node-aiimsmobileapp.mobiloitte.io";
export const baseurl = "https://node.sphaiimsjdh.org"; ///live url

  
const url = `${baseurl}/api/v1`;

export const ApiConfig = {
  //AUNTHENTICATION && ADMIN
  adminLogin: `${url}/admin/login`,
  resentOtp: `${url}/user/resendOtp`,
  verifyOtp: `${url}/user/verifyOtp`,
  resetPassword: `${url}/user/resetPassword`,
  updateUserDetails: `${url}/admin/updateUserDetails`,

  //DASHBOARD
  getDashboard: `${url}/admin/dashboardDetails`,

  //USER MANAGEMENT
  listAllUsers: `${url}/admin/listAllUsers`,
  addNewUser: `${url}/admin/addNewUser`,
  getChatConversationHistory: `${url}/admin/getChatConversationHistory`,
  editAccountDetails: `${url}/admin/updateUserDetails`,
  getAccountDetails: `${url}/admin/viewUserDetails`,
  listAllQuestionnaireSubmittedByCaregiver: `${url}/admin/listAllQuestionnaireSubmittedByCaregiver`,
  assignMultipleCaregiversToAshaWorker: `${url}/admin/assignMultipleCaregiversToAshaWorker`,
  listAshaWorkerAssignedCaregivers: `${url}/admin/listAshaWorkerAssignedCaregivers`,
  listAllUnassignedCaregivers: `${url}/admin/listAllUnassignedCaregivers`,
  getAllQuestionnaireResponses: `${url}/user/getAllQuestionnaireResponses`,

  //UPLOAD FILE
  uploadFile: `${url}/user/uploadFile`,

  //INFORMATIVE CONTENT MANAGEMENT
  listAllContentCategory: `${url}/admin/listAllContentCategory`,
  addContentCategory: `${url}/admin/addContentCategory`,
  updateContentCategory: `${url}/admin/updateContentCategory`,
  listAllCategoryArticle: `${url}/admin/listAllCategoryArticle`,
  addCategoryArticle: `${url}/admin/addCategoryArticle`,
  updateCategoryArticle: `${url}/admin/updateCategoryArticle`,

  //QUESTIONNAIRE MANAGEMENT
  listAllQuestionnaireCategory: `${url}/admin/listAllQuestionnaireCategory`,
  addQuestionnaireCategory: `${url}/admin/addQuestionnaireCategory`,
  updateQuestionnaireCategory: `${url}/admin/updateQuestionnaireCategory`,
  listAllQuestionnaire: `${url}/admin/listAllQuestionnaire`,
  addQuestionnaire: `${url}/admin/addQuestionnaire`,
  updateQuestionnaire: `${url}/admin/updateQuestionnaire`,
  listAllQuestionnaireQuestion: `${url}/admin/listAllQuestionnaireQuestion`,
  addQuestionnaireQuestion: `${url}/admin/addQuestionnaireQuestion`,
  updateQuestionnaireQuestion: `${url}/admin/updateQuestionnaireQuestion`,

  //BROADCAST MANAGEMENT
  listAllBroadcastMessage: `${url}/admin/listAllBroadcastMessage`,
  sendBroadcastMessage: `${url}/admin/sendBroadcastMessage`,

  // STATIC MANAGEMENT
  getOneStaticContent: `${url}/staticContent/getAllStaticContentByType`,
  editStaticContent: `${url}/staticContent/updateStaticContent`,
  getStaticContentByType: `${url}/admin/getStaticContentByType`,


  //MY ACCOUNT
  changePassword: `${url}/user/changePassword`,
};
