import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Grid,
} from "@mui/material";
import { borderRadius, styled } from "@mui/system";

const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "white !important",
    borderRadius: "12px",
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  background: "white",
  border: "1px solid black",
}));

export default function DialogCommon({
  handleClose,
  handleCloseSuccess,
  open,
  type,
  handleCloseResend,
  item,
  blockStatus,
  handleDeleteConfirm,
  handleBlockConfirm,
}) {
  return (
    <Box>
      {type === "loginAuthCredentialsNotMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <img
                src="images/not_found.png"
                alt="Admin Not Found"
                style={{ width: "150px", height: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6">Admin Not Found.</Typography>
              <Typography variant="h6">Please Check & Try Again.</Typography>
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: "center",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid container item xs={5}>
              <Button
                onClick={handleClose}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#fe352c" }}
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "resetPasswordSuccessDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <img
                src="images/success.png"
                alt="Admin Not Found"
                style={{ width: "150px", height: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6">
                New password has been reset successfully.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid container item xs={5}>
              <Button
                onClick={handleClose}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#fe352c" }}
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "ForgotAuthCredentialsNotMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <img
                src="images/not_found.png"
                alt="Admin Not Found"
                style={{ width: "150px", height: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6">
                Entered email is not found. Please check.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid container item xs={5}>
              <Button
                onClick={handleClose}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#fe352c" }}
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "ForgotSuccessAuthCredentialsMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <img
                src="images/success.png"
                alt="Admin Not Found"
                style={{ width: "150px", height: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6">
                OTP sent to your entered email address.
              </Typography>
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: "center",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid container item xs={5}>
              <Button
                onClick={handleCloseSuccess}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#fe352c" }}
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "OtpAuthCredentialsMatch" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <img
                src="images/success.png"
                alt="Admin Not Found"
                style={{ width: "150px", height: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6">
                OTP verification is successful.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid container item xs={5}>
              <Button
                onClick={handleClose}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#fe352c" }}
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "OtpResendDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <img
                src="images/success.png"
                alt="Admin Not Found"
                style={{ width: "150px", height: "auto", maxWidth: "100%" }}
              />
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6">
                OTP resend to your entered email address.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid container item xs={5}>
              <Button
                onClick={handleCloseResend}
                variant="contained"
                fullWidth
                style={{ backgroundColor: "#fe352c" }}
              >
                Ok
              </Button>
            </Grid>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "deleteDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" color="red" fontWeight={800}>
                Delete
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Are you want to delete this {item} ?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleDeleteConfirm}
              autoFocus
              color="secondary"
            >
              Delete
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "rejectDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" fontWeight={800}>
                Reject Recruiter
              </Typography>
            </Box>
            <Box
              align="center"
              my={3}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography variant="h6" color="secondary" align="left">
                Enter Reason For Rejection
              </Typography>
              <textarea type="text" rows={10} width={"100%"} />
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleCloseResend}
              autoFocus
              color="secondary"
            >
              Reject
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}
      {type === "blockUnblockDialog" && (
        <DialogStyled
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography variant="h3" fontWeight={800}>
                {blockStatus === "Active" ? "Block" : "Unblock"}
              </Typography>
            </Box>
            <Box align="center" my={3}>
              <Typography variant="h6" color="secondary">
                Are you sure you want to{" "}
                {blockStatus === "Active" ? "block" : "unblock"} this {item}?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <ButtonStyled
              onClick={handleBlockConfirm}
              autoFocus
              color="secondary"
            >
              {blockStatus === "Active" ? "Block" : "Unblock"}
            </ButtonStyled>
            <ButtonStyled onClick={handleClose} autoFocus color="secondary">
              Cancel
            </ButtonStyled>
          </DialogActions>
        </DialogStyled>
      )}
    </Box>
  );
}
