import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BlockIcon from "@mui/icons-material/Block";
import { Avatar, Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";

const LogoutPopUp = ({ open, setOpen, funcToExecute }) => {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px !important",
          },
        }}
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ height: "100px", width: "100px", bgcolor: "#ffffff" }}>
            <LogoutIcon
              style={{
                height: "80px",
                width: "80px",
                color: "red",
              }}
            />
          </Avatar>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "10px",
            mb: "20px",
          }}
        >
          <Grid container item xs={5}>
            <Button
              onClick={() => {
                sessionStorage.removeItem("userToken");
                localStorage.removeItem("token");
                sessionStorage.removeItem("user_type");
                sessionStorage.removeItem("adviser_id");
                funcToExecute();
                handleClose();
              }}
              variant="contained"
              fullWidth
              style={{
                // background:
                //   "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                background: "red",
              }}
            >
              Yes
            </Button>
          </Grid>
          <Grid container item xs={5}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "gray" }}
            >
              No
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default LogoutPopUp;
