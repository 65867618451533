import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import CircularProgressBox from "../component/CircularProgressBox";
import { CircularProgress, Grid } from "@mui/material";

const AdminAuthGuard = () => {
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setisLoading(true);
    if (!sessionStorage.getItem("authToken")) {
      setisLoading(false);
      navigate("/", { replace: true });
    }
    if (sessionStorage.getItem("user_type") === "ADMIN") {
      navigate("/dashboard", { replace: true });
      setisLoading(false);
    }
    setisLoading(false);
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        height={"100dvh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "#cc3d0b" }} />
      </Grid>
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default AdminAuthGuard;
