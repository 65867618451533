import { create } from "zustand";
import { navBarSlice } from "./slice/navBarSlice";
import { persist } from "zustand/middleware";
export const useStore = create(
  persist(
    (set, get, api) => ({
      ...navBarSlice(set, get, api),
    }),
    {
      name: "store",
      getStorage: () => localStorage,
      version: 1,
    }
  )
);
