import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { postAPIHandlerWithoutToken } from "../../config/service";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DialogCommon from "../../component/common/Dialogs";
import LockIcon from "@mui/icons-material/Lock";

const ResetPassWrapper = styled("div")({
  height: "100dvh",
  width: "40%",
  overflow: "hidden",
  marginTop: "160px",
  marginRight: "100px",
  "@media(max-width:1280px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",

    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
});
const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "6px",
  width: "100%",
  "@media(max-width:1024px)": {
    width: "70vw !important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    marginTop: "2px",
  },
  marginBottom: "28px",
});

const ForgetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required.")
    .matches(/^\S*$/, "Spaces are not allowed in password.")
    .trim()
    .min(6, "Minimum 6 character required.")
    .max(20, "Maximum 20 characters allowed."),
  cnfPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .required("Confirm password is required.")
    .max(20, "Maximum 20 characters allowed."),
});



const ResetPassword = () => {
  const [ispasswordHidden, setIspasswordHidden] = useState(true);
  const [isConfmPasswordHidden, setIsConfmPasswordHidden] = useState(true);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [netWorkError, setNetWorkError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    navigate("/");
  };
  useEffect(() => {
    if (!state?.email) {
      return navigate("/");
    }
  }, []);
  const resetPasswordhandler = async (values) => {
    try {
      const payLoadToSend = {
        email: state?.email?.toLowerCase()?.trim(),
        password: values.password,
      };
      const response = await postAPIHandlerWithoutToken({
        endPoint: "resetPassword",
        dataToSend: payLoadToSend,
      });
      if (response?.data?.responseCode === 200) {
        setOpenDialog(true);
      }
    } catch (error) {
      console.log(error);
      setNetWorkError(error?.response?.data?.responseMessage);
    }
  };
  const handleCopyPaste = (event) => {
  
      event.preventDefault();
   
  }
  return (
    <ResetPassWrapper>
      <Typography variant="h4">Reset Password</Typography>
      <Box mt={3}>
        <Formik
          validationSchema={ForgetPasswordSchema}
          initialValues={{
            password: "",
            cnfPassword: "",
          }}
          onSubmit={resetPasswordhandler}
        >
          {({ errors, touched, handleBlur, handleChange }) => (
            <Form>
              {netWorkError && (
                <Grid container item py={1} borderRadius={"8px"} mb={2}>
                  <Typography variant="body1" color={"red"} fontWeight={"400"}>
                    {netWorkError}
                  </Typography>
                </Grid>
              )}
              <FormControl
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label style={{ alignSelf: "flex-start" }}>Password</label>
                <FormTextField
                  name="password"
                  variant="outlined"
                  placeholder="Enter your new password"
                  inputProps={{
                    maxLength: 20,
                    style: {
                      height: "20px",
                    },
                  }}
                  onCopy={handleCopyPaste} // Prevent copying
        onCut={handleCopyPaste} // Prevent cutting
        onPaste={handleCopyPaste} 
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  type={ispasswordHidden ? "password" : "text"}
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIspasswordHidden((p) => !p)}
                        >
                          {ispasswordHidden ? (
                            <VisibilityOffIcon />
                          ) : (
                            <RemoveRedEyeIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <label>Confirm Password</label>
                <FormTextField
                  name="cnfPassword"
                  variant="outlined"
                  placeholder="Enter your confirm password"
                  inputProps={{
                    maxLength: 20,
                    style: {
                      height: "20px",
                    },
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                  type={isConfmPasswordHidden ? "password" : "text"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.cnfPassword && Boolean(errors.cnfPassword)}
                  helperText={touched.cnfPassword && errors.cnfPassword}
                  InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIsConfmPasswordHidden((p) => !p)}
                        >
                          {isConfmPasswordHidden ? (
                            <VisibilityOffIcon />
                          ) : (
                            <RemoveRedEyeIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    background:
                      "linear-gradient(98deg, #2A73E0 -1.68%, #1D60B3 103.45%)",
                    textTransform: "none",
                    padding: "8px 50px",
                  }}
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Continue
                </Button>
              </Box>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={Button}
                  variant="subtitle2"
                  color={"#FF6A13"}
                  sx={{
                    fontSize: { xs: "12px", md: "16px" },
                    textTransform: "none",
                    textDecoration: "underline",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Back to login
                </Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <DialogCommon
        handleClose={handleClose}
        open={openDialog}
        type="resetPasswordSuccessDialog"
      />
    </ResetPassWrapper>
  );
};

export default ResetPassword;
