import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";

const BarGraph = (dataDashborad) => {
  const allData = dataDashborad?.dataDashborad?.questionnaireResponseReport;

  const tooltipFormatter = (value, name) => {
    const formattedName =
      name === "completionCount" ? "Completion Count" : name;
    return [value, formattedName];
  };


  const truncateLabel = (label, maxLength) => {
    if (!label || label.length <= maxLength) return label;
    const halfLength = Math.floor((maxLength - 3) / 2); 
    return `${label.slice(0, halfLength)}...${label.slice(-halfLength)}`;
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={allData || [{}]}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          
          <XAxis
            dataKey="questionnaireName"
            tickFormatter={(label) => truncateLabel(label, 30)} // Apply truncation logic
            textAnchor="middle"
            interval={0} // Ensures all labels are shown
            height={60} // Adjust height to accommodate labels
            tickLine={false}
            tick={{ fontSize: 12 }}
          />
          
          <YAxis>
            <Label
              value="Completion Rate"
              angle={-90} // Rotate the label to make it horizontal
              position="insideLeft"
              style={{ textAnchor: "middle", fontSize: 20 }}
              dx={-5}
            />
          </YAxis>

          <Tooltip
            cursor={{ fill: "transparent" }}
            pointerEvents="auto"
            formatter={tooltipFormatter}
          />

          <Bar
            dataKey="completionCount"
            fill="#82ca9d"
            shape={<Rectangle fill="#2a73e0" stroke="#3C40C6" />}
            barSize={30}
            cursor="pointer"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
