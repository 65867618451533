import React from "react";
import { Grid, Typography, Box, Avatar, Divider, Paper, Tooltip } from "@mui/material";
import ChatScreen from "../../../../component/ChatScreen";
import { useLocation } from "react-router-dom";

const GeneralInfo = () => {
  const { state } = useLocation();
  const careGiverId = state?.value?.id;
  const ashaWorkerId = state?.value?.ashaWorkerDetails?.id;
  const careGiverProfileImage = state?.value?.profileUrl;
  const ashaWorkerProfileImage = state?.value?.ashaWorkerDetails?.profileUrl;

  const caregiverDetails = [
    { label: "Full Name", value: state?.value?.fullName_en || "N/A" },
    { label: "Email", value: state?.value?.email_en || "N/A" },
    { label: "Phone Number", value: `${state?.value?.countryCode_en} ${state?.value?.mobileNumber_en}` || "N/A" },
    { label: "City", value: state?.value?.city_en || "N/A" },
    { label: "State", value: state?.value?.state_en || "N/A" },
    { label: "District", value: state?.value?.district_en || "N/A" },
    { label: "Tehsil", value: state?.value?.tehsil_en || "N/A" },
    { label: "Address", value: state?.value?.address_en || "N/A" },
  ];
  const ashaWorkerDetails = [
    { label: "Full Name", value: state?.value?.ashaWorkerDetails?.fullName || "N/A" },
    { label: "Email", value: state?.value?.ashaWorkerDetails?.email || "N/A" },
    { label: "Phone Number", value: state?.value?.ashaWorkerDetails?.mobileNumber || "N/A" },
    { label: "Address", value: state?.value?.ashaWorkerDetails?.address || "N/A" },
  ];

  const renderDetails = (title, details, profileImage) => (
    <Paper elevation={3} sx={{
      p: 3, borderRadius: 2, display: "flex", alignItems: "flex-start", transition: "transform 0.3s ease-in-out",
      '&:hover': { transform: 'translateY(-5px)', boxShadow: 6 }, width: "100%", boxSizing: "border-box",
      maxWidth: "100%", overflow: "hidden"
    }}>
      <Avatar
        sx={{
          height: 100, width: 100, mr: 3, boxShadow: 1, transition: "transform 0.3s ease",
          '&:hover': { transform: 'scale(1.1)' }
        }}
        src={profileImage}
      />
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" sx={{
          mb: 2, fontWeight: 700, color: "rgb(60,115,224)", fontSize: { xs: "1.2rem", sm: "1.5rem" },
          textTransform: 'capitalize', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
        }}>
          {title}
        </Typography>
        {details.map((detail, index) => (
          <Box key={index} sx={{
            display: "flex", alignItems: "flex-start", mb: 1, flexWrap: "wrap", width: "100%"
          }}>
            <Tooltip title={detail.label} placement="top" arrow>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600, color: "rgb(60,115,224)", width: "150px", overflow: "hidden", textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {detail.label}:
              </Typography>
            </Tooltip>
            <Tooltip title={detail.value} placement="top" arrow>
              <Typography
                variant="body1"
                sx={{
                  color: "text.primary", maxWidth: "calc(100% - 150px)", overflow: "hidden", textOverflow: "ellipsis",
                  whiteSpace: "normal", flex: 1, fontWeight: 400, color: "#333", wordBreak: "break-word"
                }}
              >
                {detail.value}
              </Typography>
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Paper>
  );

  return (
    <Grid container sx={{
      bgcolor: "#f9f9f9", overflow: "hidden", px: { xs: 1, sm: 3 }, py: 3, justifyContent: "space-between"
    }}>
      {/* Left Section: Caregiver and ASHA Worker Details */}
      <Grid item xs={12} md={6} sx={{
        overflowY: "auto", p: 2, maxWidth: "100%", boxSizing: "border-box"
      }}>
        {renderDetails("Caregiver Details", caregiverDetails, careGiverProfileImage)}
        <Divider sx={{ my: 3, backgroundColor: "#e0e0e0" }} />
        {renderDetails("Assigned ASHA Worker Details", ashaWorkerDetails, ashaWorkerProfileImage)}
      </Grid>

      {/* Right Section: Chat Screen */}
      <Grid item xs={12} md={6} sx={{
        borderLeft: "1px solid #e0e0e0", p: 2, bgcolor: "#f9f9f9", maxWidth: "100%", boxSizing: "border-box"
      }}>
        <ChatScreen
          careGiverId={careGiverId}
          ashaWorkerId={ashaWorkerId}
          careGiverProfileImage={careGiverProfileImage}
          ashaWorkerProfileImage={ashaWorkerProfileImage}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralInfo;
