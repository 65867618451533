import axios from "axios";
import { ApiConfig } from "./ApiConfig";

export const postAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const postAPIHandlerWithoutToken = async ({ endPoint, dataToSend }) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      data: dataToSend ? dataToSend : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandlerWithToken = async ({ endPoint }) => {
  try {
    return await axios({
      method: "GET",
      url: ApiConfig[endPoint],
    });
  } catch (error) {
    console.log(error);
  }
};

export const putAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandler = async ({ endPoint, id, source, paramsData }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      params: paramsData ? paramsData : null,
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      cancelToken: source ? source.authToken : null,
    });
  } catch (error) {
    console.log(error);
  }
};

export const patchAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      headers: {
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const postAPIHandlerForFile = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    const response = await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        "Content-Type":
          dataToSend instanceof FormData
            ? "multipart/form-data"
            : "application/json",
        authToken: window.sessionStorage.getItem("authToken"),
      },
      data: dataToSend || null,
      params: paramsData || null,
    });
    return response;
  } catch (error) {
    console.error("Error in API call:", error);
    return error.response || { status: 500, message: "Internal Server Error" };
  }
};

export const transliterateToHindi = async (text) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_TRANSLATER_API_KEY_NEW;
  console.log(API_KEY, "api keys");

  try {
    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          q: text,
          target: "hi", // Hindi
          source: "en", // English
          format: "text",
        }),
      }
    );

    const data = await response.json();
    return data.data.translations[0].translatedText;
  } catch (error) {
    console.error("Translation Error:", error);
    return text; // Return the original text if translation fails
  }
};
