import React from "react";
import AppRouter from "./router"
import "@fontsource-variable/arimo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./index.css";
import AuthContext from "./context/auth";
import "react-perfect-scrollbar/dist/css/styles.css";
import "overlayscrollbars/overlayscrollbars.css";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Arimo Variable",
    },
  },
});
const App = () => {

  return (
    <AuthContext>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </AuthContext>
  );
};

export default App;

