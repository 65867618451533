import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoginLayout from "../layout/LoginLayout";
import DashboardLayout from "../layout/DashboardLayout";
import { CssBaseline } from "@mui/material";
import NormalRouteGuard from "./NormalRouteGuard";
import AdminAuthGuard from "./AdminAuthGuard";
import Login from "../pages/auth/Login";
import Forget from "../pages/auth/Forget";
import OtpVerifictaion from "../pages/auth/OtpVerification";
import ResetPassword from "../pages/auth/ResetPassword";
import Dashboard from "../pages/dashboard";
import CareGiver from "../pages/dashboard/CareGiverManagement";
import AddCareGiver from "../pages/dashboard/CareGiverManagement/AddCareGiver";
import EditCareGiver from "../pages/dashboard/CareGiverManagement/EditCareGiver";
import AshaWorker from "../pages/dashboard/ASHAWorkerManagement";
import AddASHAWorker from "../pages/dashboard/ASHAWorkerManagement/AddASHAWorker";
import InformativeContent from "../pages/dashboard/InformativeContentManagement";
import Broadcast from "../pages/dashboard/BroadcastManagement";
import Questionnaire from "../pages/dashboard/QuestionnaireManagement";
import MyAccount from "../pages/dashboard/MyAccount";
import EditProfile from "../pages/dashboard/MyAccount/editprofile";
import EditPassword from "../pages/dashboard/MyAccount/editpassword";
import StaticContent from "../pages/dashboard/StaticContentManagement";
import ViewCareGiver from "../pages/dashboard/CareGiverManagement/ViewCareGiver";
import ViewASHAWorker from "../pages/dashboard/ASHAWorkerManagement/ViewASHAWorker";
import ArticleCategory from "../pages/dashboard/InformativeContentManagement/ArticleCategory";
import AddArticle from "../pages/dashboard/InformativeContentManagement/ArticleCategory/AddArticle";
import QuestionnaireCategory from "../pages/dashboard/QuestionnaireManagement/QuestionnaireCategory";
import AddQuestionnaire from "../pages/dashboard/QuestionnaireManagement/QuestionnaireCategory/AddQuestionnaire";
import Question from "../pages/dashboard/QuestionnaireManagement/QuestionnaireCategory/Question";
import AddBroadcast from "../pages/dashboard/BroadcastManagement/AddBroadcast";
import ViewBroadcast from "../pages/dashboard/BroadcastManagement/ViewBroadcast";
import AddArticleCategory from "../pages/dashboard/InformativeContentManagement/AddCategory";
import EditArticleCategory from "../pages/dashboard/InformativeContentManagement/EditCategory";
import AddQuestionnaireCategory from "../pages/dashboard/QuestionnaireManagement/AddCategory";
import EditQuestionnaireCategory from "../pages/dashboard/QuestionnaireManagement/EditCategory";
import ViewContent from "../pages/dashboard/StaticContentManagement/ViewContent";
import EditContent from "../pages/dashboard/StaticContentManagement/EditContent";
import ViewContact from "../pages/dashboard/StaticContentManagement/ViewContact";
import EditContact from "../pages/dashboard/StaticContentManagement/EditContact";
import ViewArticle from "../pages/dashboard/InformativeContentManagement/ArticleCategory/ViewArticle";
import EditArticle from "../pages/dashboard/InformativeContentManagement/ArticleCategory/EditArticle";
import EditASHAWorker from "../pages/dashboard/ASHAWorkerManagement/EditASHAWorker";
import SurveyResponses from "../pages/dashboard/CareGiverManagement/ViewCareGiver/SurveysResponses";
import EditQuestionnaire from "../pages/dashboard/QuestionnaireManagement/QuestionnaireCategory/EditQuestionnaire";
import TermsAndConditions from "../pages/auth/TermsAndConditions";
import PrivacyPolicy from "../pages/auth/PrivacyPolicy";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Toaster position="top-right" />
      <CssBaseline />
      <Routes>
        <Route element={<NormalRouteGuard />}>
          <Route
            path="/"
            element={
              <LoginLayout>
                <Login />
              </LoginLayout>
            }
          />
          <Route
            path="/forget"
            element={
              <LoginLayout>
                <Forget />
              </LoginLayout>
            }
          />
          <Route
            path="/OtpVerification"
            element={
              <LoginLayout>
                <OtpVerifictaion />
              </LoginLayout>
            }
          />
          <Route
            path="/resetPassword"
            element={
              <LoginLayout>
                <ResetPassword />
              </LoginLayout>
            }
          />
          <Route
            path="/privacypolicy"
            element={
                <PrivacyPolicy />
            }
          />
          <Route
            path="/termsandconditions"
            element={
                <TermsAndConditions />
            }
          />
        </Route>

        <Route element={<AdminAuthGuard />}>
          <Route
            path="/dashboard"
            element={
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            }
          />
          <Route
            path="/caregiver-management"
            element={
              <DashboardLayout>
                <CareGiver />
              </DashboardLayout>
            }
          />
          <Route
            path="/caregiver-management/add-caregiver"
            element={
              <DashboardLayout>
                <AddCareGiver />
              </DashboardLayout>
            }
          />
          <Route
            path="/caregiver-management/edit-caregiver"
            element={
              <DashboardLayout>
                <EditCareGiver />
              </DashboardLayout>
            }
          />
          <Route
            path="/caregiver-management/view-caregiver"
            element={
              <DashboardLayout>
                <ViewCareGiver />
              </DashboardLayout>
            }
          />
          <Route
            path="/caregiver-management/view-caregiver/view-survey"
            element={
              <DashboardLayout>
                <SurveyResponses />
              </DashboardLayout>
            }
          />
          <Route
            path="/asha-worker-management"
            element={
              <DashboardLayout>
                <AshaWorker />
              </DashboardLayout>
            }
          />
          <Route
            path="/asha-worker-management/add-asha-worker"
            element={
              <DashboardLayout>
                <AddASHAWorker />
              </DashboardLayout>
            }
          />
          <Route
            path="/asha-worker-management/view-asha-worker"
            element={
              <DashboardLayout>
                <ViewASHAWorker />
              </DashboardLayout>
            }
          />
          <Route
            path="/asha-worker-management/edit-asha-worker"
            element={
              <DashboardLayout>
                <EditASHAWorker />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content"
            element={
              <DashboardLayout>
                <InformativeContent />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content/add-category"
            element={
              <DashboardLayout>
                <AddArticleCategory />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content/edit-category"
            element={
              <DashboardLayout>
                <EditArticleCategory />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content/view-category/:categoryId"
            element={
              <DashboardLayout>
                <ArticleCategory />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content/view-category/add-article"
            element={
              <DashboardLayout>
                <AddArticle />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content/view-category/edit-article"
            element={
              <DashboardLayout>
                <EditArticle />
              </DashboardLayout>
            }
          />
          <Route
            path="/informative-content/view-category/view-article"
            element={
              <DashboardLayout>
                <ViewArticle />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnaire"
            element={
              <DashboardLayout>
                <Questionnaire />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnaire/add-category"
            element={
              <DashboardLayout>
                <AddQuestionnaireCategory />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnaire/edit-category"
            element={
              <DashboardLayout>
                <EditQuestionnaireCategory />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnaire/add-questionnaire"
            element={
              <DashboardLayout>
                <AddQuestionnaire />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnair/edit-questionnaire"
            element={
              <DashboardLayout>
                <EditQuestionnaire />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnaire/view-questionnaire/:id"
            element={
              <DashboardLayout>
                <Question />
              </DashboardLayout>
            }
          />
          <Route
            path="/questionnaire/view-category"
            element={
              <DashboardLayout>
                <QuestionnaireCategory />
              </DashboardLayout>
            }
          />
          <Route
            path="/broadcast"
            element={
              <DashboardLayout>
                <Broadcast />
              </DashboardLayout>
            }
          />
          <Route
            path="/broadcast/add-broadcast"
            element={
              <DashboardLayout>
                <AddBroadcast />
              </DashboardLayout>
            }
          />
          <Route
            path="/broadcast/view-broadcast"
            element={
              <DashboardLayout>
                <ViewBroadcast />
              </DashboardLayout>
            }
          />
          <Route
            path="/static-content"
            element={
              <DashboardLayout>
                <StaticContent />
              </DashboardLayout>
            }
          />
          <Route
            path="/static-content/view-content"
            element={
              <DashboardLayout>
                <ViewContent />
              </DashboardLayout>
            }
          />
          <Route
            path="/static-content/edit-content"
            element={
              <DashboardLayout>
                <EditContent />
              </DashboardLayout>
            }
          />
          <Route
            path="/static-content/view-contact"
            element={
              <DashboardLayout>
                <ViewContact />
              </DashboardLayout>
            }
          />
          <Route
            path="/static-content/edit-contact"
            element={
              <DashboardLayout>
                <EditContact />
              </DashboardLayout>
            }
          />
          <Route
            path="/my-account"
            element={
              <DashboardLayout>
                <MyAccount />
              </DashboardLayout>
            }
          />
          <Route
            path="/my-account/edit-profile"
            element={
              <DashboardLayout>
                <EditProfile />
              </DashboardLayout>
            }
          />
          <Route
            path="/my-account/edit-password"
            element={
              <DashboardLayout>
                <EditPassword />
              </DashboardLayout>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
