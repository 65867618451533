import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import * as yup from "yup";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toast from "react-hot-toast";
import { ApiConfig } from "../../../config/ApiConfig";
import CircularProgress from "@mui/material/CircularProgress";
import { transliterateToHindi } from "../../../config/service";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
}));
const PrivacyPolicyContainer = styled(Box)(({ theme }) => ({
  "& .belowBox": {
    border: "1px solid #cccccc",
    padding: "15px",
    borderRadius: "10px",
    "& .MuiTypography-body2 p": {
      margin: 0,
    },
  },
  "& .heading": {
    marginTop: "20px",
  },
  "& .titlebox": {
    display: "flex",
  },
}));
const HeaderText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.text.primary,
  fontSize: "2rem",
  fontWeight: 700,
}));

const EditContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [viewData, setViewData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionEng, setDescriptionEng] = useState("");
  const [descriptionHnd, setDescriptionHnd] = useState("");

  const type = location?.state?.type;

  const [initialValue, setInitialValue] = useState({
    description_hi: "",
    description_en: "",
  });

  useEffect(() => {
    if (viewData) {
      setInitialValue({
        description_hi: viewData?.description_hi ?? "",
        description_en: viewData?.description_en ?? "",
      });
    }
  }, [viewData]);

  const formValidationSchema = yup.object().shape({
    description_hi: yup.string(),
    description_en: yup.string(),
  });

  const getStaticData = async () => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: ApiConfig.getOneStaticContent,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        params: {
          contentType: type,
        },
      });
      if (res?.data?.responseCode === 200) {
        setViewData(res?.data?.result[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editStaticData = async (descriptionEn, descriptionHi) => {
    let myId = viewData?.id;

    setIsLoading(true);

    try {
      const res = await axios({
        method: "PUT",
        url: ApiConfig.editStaticContent,
        headers: {
          authToken: sessionStorage.getItem("authToken"),
        },
        data: {
          staticContentId: myId,
          description_en: descriptionEn,
          description_hi: descriptionHi,
        },
      });
      if (res?.data?.responseCode === 200) {
        toast.success(res?.data?.responseMessage);
        navigate("/static-content");
      }
    } catch (error) {
      console.log(error);
      if (error?.response) {
        toast.error(error?.response?.data?.responseMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <Root>
      <PrivacyPolicyContainer>
        <Box mb={4} sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)} sx={{ color: "#2A73E0" }}>
            <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <Typography variant="h5">
            Edit{" "}
            {type === "privacyPolicy" ? "Privacy Policy" : "Terms & Condition"}
          </Typography>
        </Box>

        <Typography variant="h5" className="title" mb={2} mt={2}>
          For English
        </Typography>
        <Box>
          {!isLoading && viewData ? (
            <Formik
              enableReinitialize={true}
              initialValues={{
                description_en: initialValue.description_en || "",
                description_hi: initialValue.description_hi || "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={() => {
                editStaticData(descriptionEng, descriptionHnd);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <Box className="belowBox" mb={4}>
                    <CKEditor
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                        ],
                      }}
                      editor={ClassicEditor}
                      data={initialValue.description_en}
                      onChange={(event, editor) => {
                        const englishText = editor.getData();
                        setDescriptionEng(englishText);
                        transliterateToHindi(englishText).then((hindiText) => {
                          setDescriptionHnd(hindiText);
                          setFieldValue("description_hi", hindiText);
                        });
                      }}
                    />
                  </Box>

                  <Typography variant="h5" className="title" mb={2} mt={2}>
                    For Hindi
                  </Typography>
                  <Box className="belowBox" mb={4}>
                    <CKEditor
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                        ],
                      }}
                      editor={ClassicEditor}
                      data={
                        values.description_hi || initialValue.description_hi
                      }
                      onChange={(event, editor) => {
                        const hindiText = editor.getData();
                        setDescriptionHnd(hindiText);
                        setFieldValue("description_hi", hindiText);
                      }}
                    />
                  </Box>

                  <Box
                    className="displayCenter"
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button variant="contained" type="submit">
                      Update
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </PrivacyPolicyContainer>
    </Root>
  );
};

export default EditContent;
